// font-family: 'DM Sans', sans-serif;
// font-family: 'Source Sans 3', sans-serif;
// font-family: 'Source Serif 4', serif;

export default {
  backgroundColor: '#f6f5fC',
  bodyBackground: '#f5f5f9',
  bodyColor: 'rgb(105, 122, 141)',
  spaces: {
    screenEdge: '18px',
  },
  radius: {
    xxs: '8px',
    xs: '12px',
    sm: '16px',
    md: '24px',
    lg: '32px',
  },
  colors: {
    fullWhite: '#FFFFFF',
    fullBlack: '#000000',
    textDefault: '#223B59',
    iconDefault: '#4F6586',
    opacityBlack10: 'rgba(0, 0, 0, 0.1)',
    black100: '#252E46',
    black80: '#495165',
    black60: '#666E7F',
    black40: '#B8BDC7',
    black20: '#E5E8EC',
    black10: '#F2F3F5',
    black5: '#F2F3F5',
    surfaces: {
      lightOrange: '#F8F3EB',
      lightBlue: '#F3FAFC',
      white: '#FCFCFC',
    },
    primary: {
      light: '#4F6586',
      main: '#223B59',
      dark: '#06102A',
    },
    secondary: {
      light: '#E7F6FF',
      main: '#77C7E4',
      dark: '#2183A6',
    },
    disc: {
      d: {
        lighter: '#FAD3D1',
        light: '#F49690',
        main: '#EF6C64',
        dark: '#D8534B',
      },
      i: {
        lighter: '#FDEACE',
        light: '#FCD59C',
        main: '#FABF69',
        dark: '#E3A74F',
      },
      s: {
        lighter: '#D8D4E8',
        light: '#BAB2D7',
        main: '#9C91C5',
        dark: '#7E73AA',
      },
      c: {
        lighter: '#B2E6D0',
        light: '#66CCA1',
        main: '#3FBE89',
        dark: '#3B9771',
      },
    },
    trends: {
      up: { icon: '#3FBE89' },
      down: { icon: '#EF6C64' },
      const: { icon: '#2183A6' },
    },
    alerts: {
      generic: {
        background: '#EFEFEF',
        titleColor: '#223B59',
        textColor: '#223B59',
      },
      success: {
        background: '#C2FFC0',
        titleColor: '#22593F',
        textColor: '#223B59',
      },
      warning: {
        background: '#FFF9C0',
        titleColor: '#594A22',
        textColor: '#223B59',
      },
      danger: {
        background: '#FFCFE6',
        titleColor: '#592222',
        textColor: '#223B59',
        backgroundRed: '#A11212',
      },
      info: {
        background: '#B9F5F9',
        titleColor: '#224C59',
        textColor: '#223B59',
      },
    },
  },
};
