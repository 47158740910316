// /* eslint-disable */
import PropTypes from 'prop-types';
import {
  useRef, useState, useEffect, useContext,
} from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { scroller } from 'react-scroll';
import { Sheet } from 'react-modal-sheet';
import {
  Tab, Tabs, Modal, Ratio,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { driver } from 'driver.js';
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player';
import PersoraLoader from '../PersoraLoader';
import api from '../../services/backend';
import {
  // Bolt,
  BookOpen,
  Brain,
  Calendar,
  ChartVertical,
  ChevronLeft,
  ChevronSmallRight,
  Cross,
  DocumentFilled,
  EyeClosed,
  EyeOpen,
  Home1,
  InfoCircle,
  Logout,
  QuestionCircle,
  ScreenShare,
  Send2,
  Settings,
  UserFaceid,
  UsersMore,
} from '../DuotoneIcon';
import UserCard from '../UserCard';
import RemainingUsersCard from '../RemainingUsersCard';
import Disclaimer from '../Disclaimer';
import HandbookTab from '../../pages/Tabs/HandbookTab';
import UserProfileResult from '../UserProfileResult';
import PersoraTextButton from '../TextButton';
import PrivacyPerfilSheet from '../../pages/Sheets/PrivacyPerfil';
import PremiumDisclaimer from '../PremiumDisclaimer';
import defaultTheme from '../../assets/styles/themes/default';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { UserContext } from '../../Context/UserContext';
import NewGoalsTracker from '../NewGoalsTracker';
import HighlightsHomeSlider from '../HighlightsHomeSlider';
import InstrumentComponent from '../InstrumentComponent';
import SoraNerd from '../../assets/img/SoraV2/BustoCortado/Nerd.svg';
import PersoraButton from '../Button';
import { ListItensWithBlueBg, WelcomeImage } from '../WelcomePip/styles';
import welcomePipLider1 from '../../assets/img/pip/welcome-lider-1.png';
import welcomePipLider2 from '../../assets/img/pip/welcome-lider-2.png';
import welcomePipLider4 from '../../assets/img/pip/welcome-lider-4.png';
import SoraPreocupacao from '../../assets/img/SoraV2/BustoCortado/Preocupacao.svg';
import { Tag } from '../InstrumentComponent/styles';
import PipResultsTab from '../PipResultsTab';
import {
  formatDateDDMMYYYY,
  returnQuestStatus,
  checkIfPipEndsToday,
  formatDateDDMM,
} from '../../utils/dateUtils';
import { PipQuestionsDemo } from '../../pages/Sheets/PipForms';
import TermsOfUseSheet from '../../pages/Sheets/TermsOfUse';
import PrivacyPolicySheet from '../../pages/Sheets/PrivacyPolicy';
import { limitString } from '../../utils/formatters';
import UserConfigModal from '../../pages/ModalsFullscreen/UserConfig';
// import MapeamentoLoader from '../MapeamentoLoader';
// import NudgesHomeSlider from '../NudgesHomeSlider';

// import defaultTheme from './assets/styles/themes/default';

const { Context } = require('../../Context/AuthContext');

const LoggedUserFirst = (userList, loggedUserId) => {
  const index = userList.findIndex((user) => user._id === loggedUserId);
  if (index !== -1) {
    userList.splice(0, 0, userList.splice(index, 1)[0]);
  }
  return userList;
};

function HomeTabsOld({ userData }) {
  // console.log last profile userData.profiles[userData.profiles.length - 1]
  const lastProfile = (userData.profiles && userData.profiles[userData.profiles.length - 1]);
  const [PrivacyPerfilSheetIsOpen, setPrivacyPerfilSheetOpen] = useState(false);
  const { currentUserContextData } = useContext(UserContext);
  if (lastProfile) {
    return (
      <div className="mt-4" style={{ width: '100%' }}>
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="home-tabs"
        >
          <Tab
            eventKey="home"
            className="baseStyleTabs"
            style={{ paddingBottom: '72px' }}
            title={(
              <>
                <UserFaceid />
                <span>Meu estilo</span>
              </>
            )}
          >
            <div className="w-100 text-end">
              <PersoraTextButton
                className="mt-2 mb-4 semibold d-none"
                onClick={() => {
                  setPrivacyPerfilSheetOpen(true);
                  if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                    ReactGA.event({
                      category: 'Estilo',
                      action: 'abrir_quem_pode_ver',
                    });
                    mixpanel.track('abrir_quem_pode_ver', {
                      category: 'Estilo',
                      company_id: currentUserContextData._idWorkspace[0],
                    });
                  }
                }}
                style={{ opacity: '.9' }}
              >
                Quem pode ver
                {' '}
                <EyeClosed />
              </PersoraTextButton>
            </div>
            <UserProfileResult
              userName={userData.name}
              userPronouns={userData.settings.pronouns}
              userProfile={userData.profiles[userData.profiles.length - 1]}
            />
          </Tab>
          <Tab
            eventKey="handbook"
            className="baseStyleTabs"
            style={{ paddingBottom: '78px' }}
            title={(
              <>
                <BookOpen tamanho={23} />
                <span>Handbook</span>
              </>
            )}
            // disabled
          >
            {userData.disclaimers && !userData.disclaimers.userHandbook && (
              <Disclaimer
                estilo="info"
                titulo="O guia de bolso sobre você.<br/>Compartilhado com o seu time."
                icone="🎒"
                descartavel
                atributo="userHandbook"
              >
                <p className="mb-1">
                  Utilize este espaço para construir um guia sobre você para trabalhar junto.
                </p>
                <p className="mb-0"><b>Para começar:</b></p>
                <ol>
                  <li>Atualize os campos do seu Handbook</li>
                </ol>
              </Disclaimer>
            )}
            <HandbookTab userSettings={userData.settings} />
          </Tab>
        </Tabs>
        <Sheet
          isOpen={PrivacyPerfilSheetIsOpen}
          onClose={() => setPrivacyPerfilSheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableDrag={!PrivacyPerfilSheetIsOpen}
        >
          <PrivacyPerfilSheet setPrivacyPerfilSheetOpen={setPrivacyPerfilSheetOpen} />
          <Sheet.Backdrop onTap={() => setPrivacyPerfilSheetOpen(false)} />
        </Sheet>
      </div>
    );
  }
}
HomeTabsOld.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    profiles: PropTypes.arrayOf(PropTypes.shape({
      correctedProfileLetters: PropTypes.string,
    })),
    disclaimers: PropTypes.shape({
      userHandbook: PropTypes.bool,
    }),
    settings: PropTypes.shape({
      pronouns: PropTypes.string,
    }),
  }).isRequired,
};

function HomeTabs({ userData }) {
  // console.log last profile userData.profiles[userData.profiles.length - 1]
  // eslint-disable-next-line
  const lastProfile = (userData.profiles && userData.profiles[userData.profiles.length - 1]);
  const { currentUserContextData } = useContext(UserContext);
  const [termsOfUseSheetIsOpen, setTermsOfUseSheetOpen] = useState(false);
  const [privacyPolicySheetIsOpen, setPrivacyPolicySheetOpen] = useState(false);
  const { handleLogout } = useContext(Context);
  const onesignalPushIdToPrint = Cookies.get('onesignal_push_id');
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); };
  const handleShow = () => setShow(true);
  if (lastProfile) {
    return (
      <>
        <div
          className="pt-4"
          style={{
            background: '#fff', display: 'flex', flex: 1,
          }}
        >
          <div style={{
            width: `calc(100% - (${defaultTheme.spaces.screenEdge} * 2)`,
            marginLeft: defaultTheme.spaces.screenEdge,
            position: 'relative',
            paddingBottom: '2rem',
          }}
          >
            <UserCard
              userData={currentUserContextData}
            />
            {/* <hr className="mt-5" /> */}
            <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => handleShow(true)} className="mt-5 ps-1 w-100" style={{ justifyContent: 'left' }}>
              <Settings cor={defaultTheme.colors.primary.main} tamanho={22} />
              {' '}
              Configurações
              <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                <ChevronSmallRight tamanho={22} />
              </span>
            </PersoraButton>
            <hr />
            <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => setTermsOfUseSheetOpen(true)} className="mb-1 ps-1 w-100" style={{ justifyContent: 'left' }}>
              <DocumentFilled cor={defaultTheme.colors.primary.main} tamanho={22} />
              {' '}
              Termos de Uso
              <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                <ChevronSmallRight tamanho={22} />
              </span>
            </PersoraButton>
            <hr />
            <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => setPrivacyPolicySheetOpen(true)} className="mb-1 ps-1 w-100" style={{ justifyContent: 'left' }}>
              <EyeClosed cor={defaultTheme.colors.primary.main} tamanho={22} />
              {' '}
              Política de Privacidade
              <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                <ChevronSmallRight tamanho={22} />
              </span>
            </PersoraButton>
            <hr />
            <Link to="welcome?revisit=true" style={{ textDecoration: 'none' }}>
              <PersoraButton type="button" estilo="ghost" tamanho="mini" className="mb-1 ps-1">
                <ScreenShare cor={defaultTheme.colors.primary.main} tamanho={22} />
                Rever Boas-Vindas
                <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                  <ChevronSmallRight tamanho={22} />
                </span>
              </PersoraButton>
            </Link>
            <hr />
            {currentUserContextData.adminPersora && (
            <>
              <Link to="sendBumper" style={{ textDecoration: 'none' }}>
                <PersoraButton type="button" estilo="ghost" tamanho="mini" className="mb-1 ps-1">
                  <Send2 cor={defaultTheme.colors.primary.main} tamanho={22} />
                  Enviar bumper manual
                  <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                    <ChevronSmallRight tamanho={22} />
                  </span>
                </PersoraButton>
              </Link>
              <hr />
            </>
            )}
            <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={handleLogout} style={{ color: defaultTheme.colors.disc.d.dark, justifyContent: 'left' }} className="ps-1 w-100">
              <Logout cor={defaultTheme.colors.disc.d.dark} tamanho={22} />
              {' '}
              Desconectar da Persora
              <span className="d-none" style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                <ChevronSmallRight tamanho={22} cor={defaultTheme.colors.disc.d.dark} />
              </span>
            </PersoraButton>
            <div style={{ fontSize: '.7rem', color: '#ccc', textAlign: 'left' }} className="mt-4">
              {/* deviceId:
              {' '} */}
              {onesignalPushIdToPrint ? '⎷' : ' '}
            </div>
          </div>
        </div>
        <UserConfigModal show={show} handleClose={handleClose} userData={userData} />
        <Sheet
          isOpen={termsOfUseSheetIsOpen}
          onClose={() => setTermsOfUseSheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          // detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
          disableDrag={!termsOfUseSheetIsOpen}
        >
          <TermsOfUseSheet />
          <Sheet.Backdrop onTap={() => setTermsOfUseSheetOpen(false)} />
        </Sheet>
        <Sheet
          isOpen={privacyPolicySheetIsOpen}
          onClose={() => setPrivacyPolicySheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
      // detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
          disableDrag={!privacyPolicySheetIsOpen}
        >
          <PrivacyPolicySheet />
          <Sheet.Backdrop onTap={() => setPrivacyPolicySheetOpen(false)} />
        </Sheet>
      </>
    );
  }
}
HomeTabs.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    profiles: PropTypes.arrayOf(PropTypes.shape({
      correctedProfileLetters: PropTypes.string,
    })),
    disclaimers: PropTypes.shape({
      userHandbook: PropTypes.bool,
    }),
    settings: PropTypes.shape({
      pronouns: PropTypes.string,
    }),
  }).isRequired,
};

// Componente para as guias da página "time"
function TimeTabs({
  // eslint-disable-next-line
  userData,
  teamData,
  currentTeam,
  handleDeleteUser,
  handleResendInvite,
  setAddUserSheetOpen,
  setPremiumPricesSheetOpen,
}) {
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  // ONBOARDING TEAM HOME
  // Acontece após o onboarding do usuário, no primeiro acesso, após mapeamento de perfil
  if (teamData.length > 0
    && currentUserContextData?.disclaimers
    && !currentUserContextData?.disclaimers?.teamHomeOverview) {
    // wait 400ms
    const driverObjWelcomeTeam = driver({
      showProgress: true,
      allowClose: false,
      stagePadding: 5,
      stageRadius: 16,
      disableActiveInteraction: true,
      progressText: '{{current}} de {{total}}',
      onNextClick: async () => {
        // console.log('scrollll'); // DEBUG
        scroller.scrollTo('thisIsSora', {
          duration: 100,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -90, // Para que o elemento fique 50px abaixo do topo da tela
        });
        if (!driverObjWelcomeTeam.hasNextStep()) {
          driverObjWelcomeTeam.moveNext();
          try {
            const body = {
              teamHomeOverview: true,
            };
            await api.patch('/user/disclaimer', body);
            setCurrentUserContextData((prevData) => ({
              ...prevData,
              disclaimers: {
                ...prevData.disclaimers,
                teamHomeOverview: true,
              },
            }));
            if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
              ReactGA.event({
                category: 'Onboarding',
                action: 'onboarding_concluido',
                onboarding_title: 'Tela do time',
              });
              mixpanel.track('onboarding_concluido', {
                category: 'Onboarding',
                company_id: currentUserContextData._idWorkspace[0],
                onboarding_title: 'Tela do time',
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        setTimeout(() => {
          driverObjWelcomeTeam.moveNext();
        }, 500);
      },
      steps: [
        {
          element: '.TapBarTime',
          popover: {
            title: 'Este é o ambiente do seu time!',
            description: 'Ao expandir o card de cada pessoa, é possível acessar suas informações.',
            side: 'top',
            align: 'center',
            showButtons: 'next',
            nextBtnText: 'Próximo',
            popoverClass: 'tapbartime-popover',
          },
        },
        {
          element: '.thisIsSora',
          popover: {
            title: (currentUserContextData.type === 'Líder'
              ? 'Sora, sua copiloto!'
              : 'Sora, a copiloto do time!'
            ),
            description: (currentUserContextData.type === 'Líder'
              ? 'Teste a ferramenta com a Sora enquanto espera seus convidados.'
              : 'Ela apoiará vocês com orientações personalizadas.'
            ),
            side: 'bottom',
            align: 'center',
            showButtons: 'next',
            nextBtnText: 'Entendi',
          },
        },
      ],
    });
    driverObjWelcomeTeam.drive();
  }
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const youtubeVideoLink = 'https://www.youtube.com/embed/1dcRHlroByM';
  const [videoPlayed, setVideoPlayed] = useState(false);
  const handleProgress = (state) => {
    const playedPercentage = state.played * 100;
    if (playedPercentage >= 70 && videoPlayed === false) {
      console.log('A reprodução atingiu 70%');
      setVideoPlayed(true);
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'video_assistido',
          video_title: 'O que os liderados conseguem visualizar na Persora?',
        });
        mixpanel.track('video_assistido', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
          video_title: 'O que os liderados conseguem visualizar na Persora?',
        });
      }
    }
  };
  return (
    <div className="mt-4" style={{ width: '100%' }}>

      <Tabs
        defaultActiveKey="overview"
        transition={false}
        id="time-tabs"
        // eslint-disable-next-line
        onSelect={(key) => {
          if (key !== 'other') {
            // Para o vídeo e retorna para o início quando sair da aba "other"
            if (playerRef.current) {
              playerRef.current.seekTo(0);
              setIsPlaying(false);
            }
          }
        }}
      >
        <Tab
          eventKey="overview"
          className="baseStyleTabs"
          title={(
            <>
              <UsersMore />
              <span>Time</span>
            </>
            )}
        >
          {/* {userData.disclaimers && !userData.disclaimers.teamHomeOverview && (
            <Disclaimer
              estilo="info"
              titulo="Este é o dashboard do seu time!"
              icone="👋"
              className="mb-4"
              descartavel
              atributo="teamHomeOverview"
            >
              <p className="mb-1">
                Times na Persora são grupos de
                {' '}
                pessoas que trabalham juntas, que possuem um propósito compartilhado.
              </p>
              <p className="mb-0"><b>Para começar:</b></p>
              <ol>
                <li>Interaja com a Sora</li>
                <li>Dê um nome para o Time</li>
                <li>Convide seus liderados</li>
              </ol>
            </Disclaimer>
          )} */}
          {currentTeam.description
          && (
            <div className="row mt-2 mb-3">
              <div className="col-12">
                <h5 className="sourcesans form-label mb-1">Propósito do time</h5>
                <p>{currentTeam.description}</p>
              </div>
            </div>
          )}
          {LoggedUserFirst(teamData, userData._id).map((user) => (
            <UserCard
              key={user.email}
              userData={user}
              handleDeleteUser={handleDeleteUser}
              handleResendInvite={handleResendInvite}
            />
          ))}
          {userData.type === 'Líder'
          && currentUserContextData.workspaceContext.premium !== true
          && (
            // teamData.length >= process.env.REACT_APP_LIMIT_USER
            teamData.length >= 100 // para liberar limite
              ? (
                <RemainingUsersCard
                  totalUsers={teamData.length}
                  onClick={() => {
                    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                      ReactGA.event({
                        category: 'Premium',
                        action: 'convidar_sem_creditos',
                      });
                      mixpanel.track('convidar_sem_creditos', {
                        category: 'Premium',
                        company_id: currentUserContextData._idWorkspace[0],
                      });
                    }
                    setPremiumPricesSheetOpen(true);
                  }}
                />
              )
              : (
                <RemainingUsersCard
                  totalUsers={teamData.length}
                  onClick={() => { setAddUserSheetOpen(true); }}
                />
              )
          )}
          {currentUserContextData.workspaceContext.premium === true
          && userData.type === 'Líder'
          && (
            <RemainingUsersCard
              totalUsers={teamData.length}
              onClick={() => { setAddUserSheetOpen(true); }}
            />
          )}
        </Tab>
        {currentUserContextData.type === 'Líder' && (
          <Tab
            eventKey="other"
            className="baseStyleTabs"
            style={{ minHeight: 'calc(100dvh - 250px)' }}
            title={(
              <>
                <EyeOpen />
                <span>Como seu time vê o app?</span>
              </>
              )}
          >
            <h1 className="pt-2 sourcesans medium" style={{ color: defaultTheme.colors.primary.main, textWrap: 'balance' }}>
              O app pelos olhos do liderado
            </h1>
            <p className="mb-3">
              Sabemos que confiança é essencial para um líder inserir seu time em
              {' '}
              uma nova ferramenta. Criamos este vídeo para mostrar exatamente como
              {' '}
              seus liderados vão navegar e interagir com o app.
            </p>
            {/* div with widescreen proportion */}
            <Ratio aspectRatio="16x9" style={{ borderRadius: '12px', overflow: 'hidden' }}>
              <ReactPlayer
                url={youtubeVideoLink}
                width="100%"
                height="100%"
                onProgress={handleProgress}
                ref={playerRef}
                playing={isPlaying}
                config={{
                  youtube: {
                    playerVars: {
                      controls: 1, // Ocultar os controles padrões do YouTube
                      showinfo: 0,
                      modestbranding: 1,
                    },
                  },
                }}
              />
            </Ratio>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}
TimeTabs.propTypes = {
  userData: PropTypes.shape({
    disclaimers: PropTypes.shape({
      teamHomeOverview: PropTypes.bool,
    }),
    _id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  teamData: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
  })).isRequired,
  currentTeam: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  handleResendInvite: PropTypes.func.isRequired,
  setAddUserSheetOpen: PropTypes.func.isRequired,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
};

/*
  Estados possíveis da PIP para Líder

  Estados possíveis da PIP para liderado
  Indisponível - WS ainda não atingiu 3 usuários - pipContext.lenght = 0
    -> currentUserContextData.pipContext.length === 0 && 'Indisponível'
  Disponível e...
  Desabilitada no time e sem resultados - currentTeam._id
  Desabilitada no time e com resultados -
  Habilitada no time e Aberta 1º dia (com ou sem resposta)
  Habilitada no time e Aberta 2º dia (com ou sem resposta)
  Habilitada no time e sem resultados -
  Habilitada no time e com resultados -

  1º Checar se a pip está disponível ou não
  2º Checar se a pip está habilitada ou não no workspace
  3º Checar se existe 1 ou 2 pips
  4º Checar se a pip mais recente está aberta ou fechada
  5º Estando fechada, chegar se possui resultado ou não
  6º Havendo + de uma, considerar posição correta do vetor e próximo a abertura,
  mostrar data de agendamento

*/

function WorkspaceTabs({
  currentTeam,
  setPipFormOpen,
  pipEnableCurrentTeam,
  setPipEnableCurrentTeam,
}) {
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [pipQuestionsDemoSheetIsOpen, setPipQuestionsDemoSheetOpen] = useState(false);
  const [pipRespostasEfetivasSheetIsOpen, setPipRespostasEfetivasSheetOpen] = useState(false);
  const [show, setShow] = useState(false);
  // const [loadingPipResult, setLoadingPipeResult] = useState(false);
  const [getQuestByTeamData, setGetQuestByTeamData] = useState(null);
  const handleClose = () => { setShow(false); setGetQuestByTeamData(null); };
  const handleShow = () => setShow(true);
  const currentTeamId = currentTeam._id;
  // const currentTeamObject = currentUserContextData.workspaceContext.teams.find(
  //   (team) => team._id === currentTeamId,
  // );
  const [currentTeamObject, setCurrentTeamObject] = useState(
    currentUserContextData.workspaceContext.teams.find(
      (team) => team._id === currentTeamId,
    ),
  );
  // eslint-disable-next-line
  const [pipQuantity, setPipQuantity] = useState(currentUserContextData.pipContext.length);
  const [pipAvailable, setPipAvailable] = useState(false);

  const [mostRecentPip, setMostRecentPip] = useState(null);
  const [mostRecentPipStatus, setMostRecentPipStatus] = useState(null);
  // eslint-disable-next-line
  const [leastRecentPip, setLeastRecentPip] = useState(null);
  // eslint-disable-next-line
  const [leastRecentPipStatus, setLeastRecentPipStatus] = useState(null);
  const [currentPipCard, setCurrentPipCard] = useState(null);
  const [currentPipCardStatus, setCurrentPipCardStatus] = useState(null);
  // eslint-disable-next-line
  const [currentUserAnswer, setCurrentUserAnswer] = useState(null);
  const currentPipCardTotalAnswers = currentPipCard?.answers?.filter(
    (answer) => answer.idTeam === currentTeam._id && answer.isValid === true,
  ).length;
  // const [loadingPip, setLoadingPip] = useState(true);
  const handleSwitchPip = async () => {
    if (currentUserContextData.workspaceContext.ledUsersMapped < 3) {
      toast.error('É necessário ter pelo menos 3 liderados com estilo mapeado no time para que a PIP seja habilitada.', {
        autoClose: 3000,
        position: 'top-center',
      });
      return;
    }
    if (pipEnableCurrentTeam === true) {
      const result = await Swal.fire({
        html: `
        Você realmente deseja desabilitar a PIP? Sem essa pesquisa não conseguirei orientá-lo sobre o que há de mais crítico no time.
      `,
        showCancelButton: true,
        // confirmButtonColor: defaultTheme.colors.alerts.danger.,
        // confirmButtonColor: defaultTheme.colors.alerts.danger.backgroundRed,
        confirmButtonText: 'Desabilitar a PIP',
        cancelButtonText: 'Manter a PIP habilitada',
        customClass: {
          confirmButton: 'swal2-button-full-width swal2-button-color-outline-red',
          cancelButton: 'swal2-button-full-width swal2-button-color-filled-primary-main',
        },
      });
      if (result.isConfirmed) {
        setPipEnableCurrentTeam(!pipEnableCurrentTeam);
        try {
          await api.patch(`/team/settings/?idTeam=${currentTeam._id}`, { pipEnabled: (!pipEnableCurrentTeam) });
          toast.success(`A PIP foi ${pipEnableCurrentTeam ? 'desabilitada' : 'habilitada'} para este time.`, {
            autoClose: 3000,
            position: 'top-center',
          });
          // setCurrentUserContextData workspaceContext -> teams[] -> toolsSettings -> pipEnabled
          const updatedTeams = currentUserContextData.workspaceContext.teams.map((team) => {
            if (team._id === currentTeam._id) {
              return {
                ...team,
                toolsSettings: {
                  ...team.toolsSettings,
                  pipEnabled: !pipEnableCurrentTeam,
                },
              };
            }
            return team;
          });
          setCurrentUserContextData((prevData) => ({
            ...prevData,
            workspaceContext: {
              ...prevData.workspaceContext,
              teams: updatedTeams,
            },
          }));
        } catch (error) {
          console.log(error);
          toast.error(`Erro ao ${pipEnableCurrentTeam ? 'desabilitar' : 'habilitar'} a PIP.`, {
            autoClose: 3000,
            position: 'top-center',
          });
          setPipEnableCurrentTeam(!pipEnableCurrentTeam);
        }
      }
    } else {
      setPipEnableCurrentTeam(!pipEnableCurrentTeam);
      try {
        await api.patch(`/team/settings/?idTeam=${currentTeam._id}`, { pipEnabled: (!pipEnableCurrentTeam) });
        toast.success(`A PIP foi ${pipEnableCurrentTeam ? 'desabilitada' : 'habilitada'} para este time.`, {
          autoClose: 3000,
          position: 'top-center',
        });
        // setCurrentUserContextData workspaceContext -> teams[] -> toolsSettings -> pipEnabled
        const updatedTeams = currentUserContextData.workspaceContext.teams.map((team) => {
          if (team._id === currentTeam._id) {
            return {
              ...team,
              toolsSettings: {
                ...team.toolsSettings,
                pipEnabled: !pipEnableCurrentTeam,
              },
            };
          }
          return team;
        });
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          workspaceContext: {
            ...prevData.workspaceContext,
            teams: updatedTeams,
          },
        }));
      } catch (error) {
        console.log(error);
        toast.error(`Erro ao ${pipEnableCurrentTeam ? 'desabilitar' : 'habilitar'} a PIP.`, {
          autoClose: 3000,
          position: 'top-center',
        });
        setPipEnableCurrentTeam(!pipEnableCurrentTeam);
      }
    }
  };
  const getTitlesBasedOnResults = () => {
    if (currentPipCard && Array.isArray(currentPipCard.resultsTeams) && currentTeam) {
      const teamIndex = currentPipCard.resultsTeams.findIndex(
        (team) => team.teamId === currentTeam._id,
      );
      if (teamIndex !== -1) {
        const teamResults = currentPipCard.resultsTeams[teamIndex].results;
        const isResultsEmpty = !teamResults || teamResults.length === 0;
        return isResultsEmpty
          ? {
            title: 'Infelizmente não houve respostas suficientes para gerarmos o resultado da PIP do seu time',
            description: 'É necessário que pelo menos 3 pessoas respondam aos itens da PIP.',
            questionCircleButton: true,
            showCopyMessage: true,
            hasResults: false,
            copyMessage: 'Quero agradecer aos que responderam a PIP! Mas, não atingimos o mínimo de respostas efetivas para gerar um resultado. Por favor, participem na próxima quinzena para que a ferramenta nos ajude com ações personalizadas. 🙌',
          }
          : {
            title: 'O resultado da nova PIP está disponível',
            description: (currentUserContextData.type === 'Líder' ? 'Como líder, você pode acessar o resultado completo e visualizar as ações sugeridas pela copiloto para abordar a área mais crítica.' : 'Acompanhe a evolução do seu time e apoie o seu líder.'),
            questionCircleButton: false,
            showCopyMessage: true,
            hasResults: true,
            copyMessage: 'Valeu por participarem da PIP! Acabei de acessar o resultado completo e vou dar uma olhada com atenção na sugestão de ação pra tratar o que apareceu como prioridade. Continuo contando com o apoio e a participação de todos!',
          };
      }
    }
    return {
      title: 'Não há informações compiladas para o seu time',
      description: 'Na data da última PIP, seu time não atendia aos requisitos mínimos para utilizar este recurso.',
      questionCircleButton: false,
      showCopyMessage: false,
      hasResults: false,
      copyMessage: '',
    };
  };
  // 66226fbcf6266661452e74c4
  const getCopyMessageContentBasedOnDate = () => {
    // Remove de currentPipCard.answers as respostas que não tenha o idTeam === currentTeam._id
    // e das respostas restantes, remove as que tiverem isValid === false
    const teamAnswers = currentPipCard.answers.filter(
      (answer) => answer.idTeam === currentTeam._id && answer.isValid === true,
    );
    const today = new Date();
    const finalDate = new Date(currentPipCard.finalDate);
    // Remove time part for comparison
    today.setHours(0, 0, 0, 0);
    finalDate.setHours(0, 0, 0, 0);
    if (today.getTime() === finalDate.getTime()) {
      return {
        copyMessage: 'Olá! Nossa pesquisa quinzenal da Persora se encerra hoje. Só teremos resultado se atingirmos o mínimo de respostas efetivas. Leva menos de 1 minuto para responder. Contribua com o time!',
        auxLeaderTitleText: `${teamAnswers.length === 0
          ? 'A PIP do seu time encerra hoje e ainda estamos sem respostas efetivas'
          : `${teamAnswers.length < 3
            ? `A PIP do seu time encerra hoje e estamos com ${teamAnswers.length} ${teamAnswers.length > 1 ? 'respostas efetivas' : 'resposta efetiva'}.`
            : `A PIP do seu time encerra hoje e estamos com ${teamAnswers.length} respostas efetivas`
          }`
        }`,
        auxLeaderDescriptionText: `${teamAnswers.length === 0
          ? 'Você precisa de 3 respostas efetivas para acompanhar "como" o seu time está, e "o que fazer" para melhorar continuamente.'
          : `${teamAnswers.length < 3
            ? 'Vale um reforço com o time! O resultado só será gerado com pelo menos 3 respostas efetivas.'
            : 'Lembre-se: quanto mais pessoas participarem, mais preciso será o resultado.'
          }`
        }`,
        // eslint-disable-next-line
        // auxLeaderDescriptionText: `${teamAnswers.length < 3 ? 'Lembre-se, o resultado só é gerado a partir de 3 respostas efetivas do seu time.' : ''}`,
      };
    }
    return {
      copyMessage: 'Oi! Tá rolando a pesquisa quinzenal da Persora sobre o nosso time. É super importante e só teremos resultado se atingirmos o mínimo de respostas efetivas. Leva menos de 1 minuto para responder. Contribua, por favor! Habilite as notificações da Persora para receber os lembretes.',
      auxLeaderTitleText: 'A PIP do time está em andamento',
      auxLeaderDescriptionText: 'Seja cuidadoso ao reforçar para que todos respondam, pois é comum esquecimentos até que se torne um hábito.',
    };
  };
  const updateTaskPipSeeResult = async () => {
    if (
      currentUserContextData?.tasksGoals?.pipSeeResult?.includes(currentTeam._id)
    ) return;
    if (currentPipCardStatus.status === 'Fechada') {
      let pipSeeResultToUpdate = [];
      //  se pipSeeActionPlan não for um array
      if (!Array.isArray(currentUserContextData.tasksGoals.pipSeeResult)) {
        pipSeeResultToUpdate = [currentTeam._id];
      } else {
        pipSeeResultToUpdate = [
          ...currentUserContextData.tasksGoals.pipSeeResult, currentTeam._id,
        ];
      }
      try {
        const body = {
          pipSeeResult: pipSeeResultToUpdate,
        };
        await api.patch('/user/updateTask', body);
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          tasksGoals: {
            ...prevData.tasksGoals,
            pipSeeResult: pipSeeResultToUpdate,
          },
        }));
        // console.log('Tarefa de pipSeeResult atualizada com sucesso!'); // DEBUG
      } catch (error) {
        console.log(error);
      }
    }
  };
  const updateTaskPipSeeActionPlan = async () => {
    if (
      currentUserContextData?.tasksGoals?.pipSeeActionPlan?.includes(currentTeam._id)
    ) return;
    if (currentPipCardStatus.status === 'Fechada') {
      let pipSeeActionPlanToUpdate = [];
      //  se pipSeeActionPlan não for um array
      if (!Array.isArray(currentUserContextData.tasksGoals.pipSeeActionPlan)) {
        pipSeeActionPlanToUpdate = [currentTeam._id];
      } else {
        pipSeeActionPlanToUpdate = [
          ...currentUserContextData.tasksGoals.pipSeeActionPlan, currentTeam._id,
        ];
      }
      try {
        const body = {
          // eslint-disable-next-line
          pipSeeActionPlan: pipSeeActionPlanToUpdate,
        };
        await api.patch('/user/updateTask', body);
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          tasksGoals: {
            ...prevData.tasksGoals,
            pipSeeActionPlan: pipSeeActionPlanToUpdate,
          },
        }));
        // console.log('Tarefa de pipSeeActionPlan atualizada com sucesso!'); // DEBUG
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleMixpanelConteudoPipAcessado = (content) => {
    const idTeam = currentTeam._id;
    // console.log('conteudo_da_pip_acessado ============> idTeam', idTeam, 'content: ', content);
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      ReactGA.event({
        category: 'Engajamento',
        action: 'conteudo_da_pip_acessado',
        content,
        id_team: idTeam,
      });
      mixpanel.track('conteudo_da_pip_acessado', {
        category: 'Engajamento',
        company_id: currentUserContextData._idWorkspace[0],
        content,
        id_team: idTeam,
      });
    }
  };
  useEffect(() => {
    // 1º Checar se a pip está disponível ou não
    setCurrentTeamObject(currentUserContextData.workspaceContext.teams.find(
      (team) => team._id === currentTeamId,
    ));
    if (pipQuantity > 0) {
      setPipAvailable(true);
    }
    // 2º Checar se a pip está habilitada ou não no workspace
    if (currentTeamObject.toolsSettings.pipEnabled) {
      setPipEnableCurrentTeam(true);
    }
    // 3º Checar se existe 1 ou 2 pips e setar seus useStates
    if (currentUserContextData.pipContext[0]) {
      setMostRecentPip(currentUserContextData.pipContext[0]);
      setMostRecentPipStatus(returnQuestStatus({
        startDate: currentUserContextData.pipContext[0].startDate,
        finalDate: currentUserContextData.pipContext[0].finalDate,
      }));
    }
    if (currentUserContextData.pipContext[1]) {
      setLeastRecentPip(currentUserContextData.pipContext[1]);
      setLeastRecentPipStatus(returnQuestStatus({
        startDate: currentUserContextData.pipContext[1].startDate,
        finalDate: currentUserContextData.pipContext[1].finalDate,
      }));
    }
  }, [currentUserContextData.pipContext]);
  useEffect(() => {
    if (pipQuantity === 1) {
      setCurrentPipCard(mostRecentPip);
      setCurrentPipCardStatus(mostRecentPipStatus);
    } else if (pipQuantity > 1) {
      const today = new Date();
      const mostRecentPipStartDate = new Date(mostRecentPip?.startDate);
      // Calcula a diferença em milissegundos entre
      // a data atual e a data de início do PIP mais recente
      const timeDifference = mostRecentPipStartDate - today;
      // Converte a diferença de tempo para horas
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      // Se mostRecentPip.startDate for no dia seguinte, currentPipCard receberá mostRecentPip
      if (hoursDifference <= 24) {
        setCurrentPipCard(mostRecentPip);
        setCurrentPipCardStatus(mostRecentPipStatus);
      } else {
        // Se não, currentPipCard deverá receber leastRecentPip
        setCurrentPipCard(leastRecentPip);
        setCurrentPipCardStatus(leastRecentPipStatus);
      }
    }
  }, [pipQuantity, mostRecentPip, mostRecentPipStatus, leastRecentPip, leastRecentPipStatus]);
  // useEffect(() => {
  //   // Verifica se currentPipCard e currentPipCard.answers existem e se answers é um array
  //   if (currentPipCard && Array.isArray(currentPipCard.answers)) {
  //     setCurrentUserAnswer(currentPipCard.answers.find(
  //       (answer) => answer.idUser === currentUserContextData._id,
  //     ));
  //   }
  // }, [currentPipCard, currentPipCardStatus, currentUserContextData._id]);
  useEffect(() => {
    // checif currentUserContextData._id está entre as respostas de currentPipCard.answers.idUser
    if (currentPipCard && currentPipCard.answers) {
      setCurrentUserAnswer(currentPipCard.answers.find(
        (answer) => answer.idUser === currentUserContextData._id,
      ));
    }
  }, [currentPipCard, currentPipCardStatus]);
  // useEffect com .get em /questSurvey/getQuestByTeam
  // (body = {teamId: currentTeam._id, type: 'pip'}) que acontece apenas
  // quando show (do modal) muda de false para true ou quando currentPipCard muda
  useEffect(() => {
    if (show && currentPipCard) {
      (async () => {
        try {
          const body = {
            teamId: currentTeam._id,
            type: 'pip',
          };
          const { data } = await api.get(`/questSurvey/getQuestByTeam/${currentTeam._id}?idWorkspace=${currentUserContextData._idWorkspace}&type=pip`, body);
          // console.log('dados getQuestByTeam: ', data); // DEBUG
          setGetQuestByTeamData(data);
        } catch (error) {
          console.log(error);
          toast.error('Erro ao carregar a PIP');
        }
      })();
    }
  }, [show, currentPipCard]);

  useEffect(() => {
    // console.log('rastreia currentUserContextData', currentUserContextData); // DEBUG
    // console.log('rastreia pipEnableCurrentTeam', pipEnableCurrentTeam); // DEBUG
  }, [currentUserContextData, pipEnableCurrentTeam]);

  // console.log(
  //   pipQuantity,
  //   pipAvailable,
  //   pipEnableCurrentTeam,
  //   mostRecentPip,
  //   mostRecentPipStatus,
  //   leastRecentPip,
  //   leastRecentPipStatus,
  //   'atual aqui',
  //   currentPipCard,
  //   currentPipCardStatus,
  //   'currentUserAnswer',
  //   currentUserAnswer,
  // ); // DEBUG
  return (
    <div
      className="pt-4"
      style={{
        background: '#fff', display: 'flex', flex: 1,
      }}
    >
      <div style={{
        width: `calc(100% - (${defaultTheme.spaces.screenEdge} * 2)`,
        marginLeft: defaultTheme.spaces.screenEdge,
      }}
      >
        {/* PIP INDISPONÍVEL */}
        {pipAvailable === false
        && (
          <InstrumentComponent
            instrumentTag="PIP"
            className="thisIsPipCard"
            statusTag="Indisponível"
            styleTag="default"
            title="PIP - Pesquisa para Impacto Positivo"
            description="Este recurso monitora os desafios do time e oferece suporte à liderança. Ficará disponível quando o time tiver pelo menos 3 liderados na Persora."
            buttonText="Expandir"
            buttonAction={() => {
              Swal.fire({
                html: `
                  <img src=${SoraNerd} alt='Sora Nerda' class='mb-3'/><br/>
                  Para habilitar a PIP o seu time deve ter o mínimo de 3 liderados com estilos mapeados.
                `,
                showCancelButton: false,
                confirmButtonColor: defaultTheme.colors.primary.main,
                confirmButtonText: 'Entendi',
                customClass: {
                  confirmButton: 'swal2-button-full-width',
                },
              });
            }}
          />
        )}
        {/* PIP DISPONÍVEL */}
        {currentPipCard && pipEnableCurrentTeam === true
        && (
          currentPipCardStatus.status === 'Fechada' ? (
            <InstrumentComponent
              instrumentTag="PIP"
              className="thisIsPipCard"
              statusTag={`${currentPipCardStatus.status}`}
              styleTag={currentPipCardStatus.tagColor}
              title={getTitlesBasedOnResults().title}
              description={`${getTitlesBasedOnResults().description}`}
              buttonText="Expandir"
              buttonAction={() => {
                handleShow();
                updateTaskPipSeeResult();
                handleMixpanelConteudoPipAcessado('Resultados');
              }}
              disabledButton={(currentPipCardStatus.status === 'Em andamento' && currentUserAnswer ? 'Respondida' : '')}
              questionCircleButton={getTitlesBasedOnResults().questionCircleButton
                ? setPipRespostasEfetivasSheetOpen
                : null}
              copyMessageButton={currentPipCardStatus.status === 'Fechada'
                  && currentUserContextData.type === 'Líder'
                  && getTitlesBasedOnResults().showCopyMessage === true
                ? getTitlesBasedOnResults().copyMessage : ''}
            />
            // questionCircleButton: false,
            // showCopyMessage: true,
            // hasResults: true,
          ) : (
            <InstrumentComponent
              instrumentTag="PIP"
              className="thisIsPipCard"
              statusTag={`${currentPipCardStatus.status}${currentPipCardStatus.status === 'Agendada' ? ` para ${formatDateDDMMYYYY(currentPipCard.startDate, '/')}` : ''}`}
              styleTag={currentPipCardStatus.tagColor}
              title={`
                ${currentUserContextData.type === 'Líder'
                ? `${currentPipCardStatus.status === 'Em andamento' ? getCopyMessageContentBasedOnDate().auxLeaderTitleText : 'PIP - Pesquisa para Impacto Positivo'}`
                : `${currentPipCardStatus.status === 'Em andamento'
                  ? `A PIP do seu time encerra ${checkIfPipEndsToday(currentPipCard.finalDate) ? 'hoje' : 'amanhã'} e estamos com ${currentPipCardTotalAnswers === 0
                    ? 'ainda estamos sem respostas efetivas'
                    : `${currentPipCardTotalAnswers} ${currentPipCardTotalAnswers > 1 ? 'respostas efetivas' : 'resposta efetiva'}.`}`
                  : 'PIP - Pesquisa para Impacto Positivo'}`
                }
              `}
              description={`
                ${currentPipCardStatus.status === 'Agendada'
                ? `A partir do dia ${formatDateDDMMYYYY(currentPipCard.startDate, '/')}, o time poderá responder à PIP quinzenal, que ficará aberta por dois dias. A PIP acompanha os desafios do time e oferece suporte à liderança.`
                : (
                  `${currentUserContextData.type === 'Líder'
                    ? `${currentPipCardStatus.status === 'Em andamento' ? getCopyMessageContentBasedOnDate().auxLeaderDescriptionText : 'É o feedback do time para a liderança. Monitora os desafios do time e oferece suporte à liderança.'}`
                    : `${currentPipCardStatus.status === 'Em andamento' ? 'O resultado será gerado sempre que houver um mínimo de 3 respostas efetivas.' : 'É o feedback do time para a liderança. Monitora os desafios do time e oferece suporte à liderança.'}`
                  }`
                )}
              `}
              buttonText="Expandir"
              buttonAction={() => {
                handleShow();
                updateTaskPipSeeResult();
                handleMixpanelConteudoPipAcessado('Resultados');
              }}
              // copyMessageButton={`${currentPipCardStatus.status === 'Em andamento' && 'aaa'}`}
              copyMessageButton={`${currentPipCardStatus.status === 'Em andamento' && currentUserContextData.type === 'Líder' ? getCopyMessageContentBasedOnDate().copyMessage : `${currentPipCardStatus.status === 'Agendada' && currentUserContextData.type === 'Líder' ? `Olá! A nossa PIP quinzenal começa dia ${formatDateDDMMYYYY(currentPipCard.startDate, '/')}, e fica aberta por dois dias. Só teremos resultado se atingirmos o mínimo de respostas efetivas. Leva menos de 1 minuto para responder. Contribua com o time!` : ''}`}`}
              disabledButton={(currentPipCardStatus.status === 'Em andamento' && currentUserAnswer ? 'Respondida' : '')}
              enabledButton={(currentPipCardStatus.status === 'Em andamento' && !currentUserAnswer && currentUserContextData.type === 'Colaborador' ? 'Responder' : '')}
              enabledButtonAction={() => { setPipFormOpen(true); }}
              questionCircleButton={(currentPipCardStatus.status === 'Em andamento' ? setPipRespostasEfetivasSheetOpen : null)}
            />
          )
        )}
        {/* PIP DESABILITADA */}
        {currentPipCard && pipEnableCurrentTeam === false
        && (
          <InstrumentComponent
            // instrumentTag="PIP"
            statusTag={`Desabilitada em ${currentTeam.emoji} ${currentTeam.name}`}
            className="thisIsPipCard"
            styleTag="default"
            title="PIP - Pesquisa para Impacto Positivo"
            description={`${currentUserContextData.type === 'Líder' ? 'Ao habilitar este recurso, você saberá “como” o seu time está, e “o que fazer” para melhorar continuamente.' : 'O seu líder já pode habilitar este recurso para acompanhar como o time está e receber apoio para melhorar continuamente.'}`}
            buttonText="Expandir"
            buttonAction={() => {
              handleShow();
              updateTaskPipSeeResult();
              handleMixpanelConteudoPipAcessado('Resultados');
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose} fullscreen animation={false} className="modalFullscreenPage" id="pipResultModal" style={{ overflowX: 'hidden' }}>
        <Modal.Header>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handleClose}
            style={{ marginLeft: -12 }}
          >
            <ChevronLeft />
          </PersoraButton>
          <Modal.Title style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1.15rem',
            // background: 'green',
            width: 'calc(100% - 84px)',
            marginLeft: '28px',
            justifyContent: 'center',
          }}
          >
            <span className="semibold">
              PIP
              <Tag className="ms-2 mb-0">
                {currentTeam.emoji ? `${currentTeam.emoji}` : '🤖'}
                {currentTeam.name ? (
                  <span className="ms-1">
                    {limitString(currentTeam.name, 24)}
                  </span>
                ) : ''}
              </Tag>
            </span>
            <Tag className="mb-0 danger d-none">Fechada</Tag>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 56px)',
          width: `calc(100% + (${defaultTheme.spaces.screenEdge} * 2)`,
          marginLeft: `-${defaultTheme.spaces.screenEdge}`,
          background: defaultTheme.colors.surfaces.lightOrange,
          paddingBottom: 0,
        }}
        >
          <Tabs
            defaultActiveKey="pipResultados"
            transition={false}
            id="pip-tabs"
            onSelect={
              (key) => {
                if (key === 'pipResultados') { handleMixpanelConteudoPipAcessado('Resultados'); }
                if (key === 'pipSobre') { handleMixpanelConteudoPipAcessado('Sobre'); }
                if (key === 'pipAgendamento') { handleMixpanelConteudoPipAcessado('Agendamento'); }
              }
            }
          >
            <Tab
              key="tabKey-pipResultados"
              eventKey="pipResultados"
              className="baseStyleTabs"
              style={{
                overflowX: 'hidden', padding: '0 16px', width: 'calc(100% + 32px)', marginLeft: '-16px',
              }}
              title={(
                <>
                  <ChartVertical />
                  <span>Resultados</span>
                </>
              )}
            >
              <PipResultsTab
                getQuestByTeamData={getQuestByTeamData}
                setPipRespostasEfetivasSheetOpen={setPipRespostasEfetivasSheetOpen}
                updateTaskPipSeeActionPlan={updateTaskPipSeeActionPlan}
                currentTeam={currentTeam}
                handleMixpanelConteudoPipAcessado={handleMixpanelConteudoPipAcessado}
              />
            </Tab>
            <Tab
              key="tabKey-pipSobre"
              eventKey="pipSobre"
              className="baseStyleTabs"
              title={(
                <>
                  <InfoCircle />
                  <span>Sobre</span>
                </>
              )}
            >
              <div className="w-100">
                <h4 className="medium mb-3" style={{ color: defaultTheme.colors.primary.main }}>PIP - Pesquisa para Impacto Positivo</h4>
                <WelcomeImage src={welcomePipLider1} alt="Welcome 1" className="mb-4" style={{ maxWidth: '520px' }} />
                {currentUserContextData.type === 'Líder' ? (
                  <p className="mb-2">
                    A PIP é a pesquisa recorrente da Persora que acompanha continuamente
                    a equipe em quatro dimensões:
                    {' '}
                    <b>indivíduo</b>
                    ,
                    {' '}
                    <b>liderança</b>
                    ,
                    {' '}
                    <b>time</b>
                    {' '}
                    e
                    {' '}
                    <b>regras do jogo</b>
                    .
                  </p>
                ) : (
                  <p className="mb-2">
                    A PIP é a voz do time! É uma pesquisa quinzenal que acompanha
                    {' '}
                    continuamente a equipe em quatro dimensões:
                    {' '}
                    <b>indivíduo</b>
                    ,
                    {' '}
                    <b>liderança</b>
                    ,
                    {' '}
                    <b>time</b>
                    {' '}
                    e
                    {' '}
                    <b>regras do jogo</b>
                    .
                  </p>
                )}
                {currentUserContextData.type === 'Líder' && (
                  <PersoraButton
                    tamanho="condensed"
                    estilo="outline"
                    onClick={() => setPipQuestionsDemoSheetOpen(true)}
                    className="mt-3 px-3"
                  >
                    <span style={{ marginRight: '-5px' }}>
                      <QuestionCircle tamanho={24} />
                    </span>
                    O que o meu time responde?
                  </PersoraButton>
                )}
                {currentUserContextData.type === 'Líder' ? (
                  <>
                    <h4 className="medium pt-3 mt-4 mb-3" style={{ color: defaultTheme.colors.primary.main }}>
                      Com base em como time está, você acessa um plano de ação
                    </h4>
                    <WelcomeImage src={welcomePipLider2} alt="Welcome 1" className="mb-4" style={{ maxWidth: '520px' }} />
                    <p className="mb-2">
                      Além de entregar dados sobre como o time está, com
                      {' '}
                      base nos resultados, a Sora personaliza orientações
                      {' '}
                      para a liderança sobre &#34;o que fazer&#34;.
                    </p>
                  </>
                ) : (
                  <>
                    <h4 className="medium pt-3 mt-4 mb-3" style={{ color: defaultTheme.colors.primary.main }}>
                      Sem comprometer o anonimato, o seu líder acessa um plano de ação.
                    </h4>
                    <WelcomeImage src={welcomePipLider4} alt="Welcome 1" className="mb-4" style={{ maxWidth: '520px' }} />
                    <p className="mb-2">
                      Com base nos resultados, a Sora personaliza orientações
                      {' '}
                      para ajudar a liderança a agir nas necessidades reais do time.
                    </p>
                    <p className="mb-2">
                      <b>Uma premissa nossa: nunca expor ninguém!</b>
                    </p>
                  </>
                )}
                <h4 className="medium pt-3 mt-4 mb-3" style={{ color: defaultTheme.colors.primary.main }}>Como funciona a PIP</h4>
                {currentUserContextData.type === 'Líder' ? (
                  <ListItensWithBlueBg className="my-3">
                    <div className="emoji-paragraph">
                      <span>📅</span>
                      <p className="mb-3">
                        É disparada automaticamente a cada 2 semanas.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>📢</span>
                      <p className="mb-3">
                        Fica disponível para resposta durante 2 dias.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>😊</span>
                      <p className="mb-3">
                        Aborda as percepções individuais com base nas últimas duas semanas.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>🥷</span>
                      <p className="mb-3">
                        É anônima e só gera resultado a partir de três respostas efetivas.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>😎</span>
                      <p className="mb-3">
                        A liderança nunca responde a PIP do time que lidera.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>😌</span>
                      <p className="mb-3">
                        É possível não responder por desconforto ou ausência.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>📊</span>
                      <p className="mb-3">
                        Apenas a liderança acessa o resultado completo.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>🚫</span>
                      <p className="mb-2">
                        O resultado não deve ser utilizado como avaliações de desempenho.
                      </p>
                    </div>
                  </ListItensWithBlueBg>
                ) : (
                  <ListItensWithBlueBg className="my-3">
                    <div className="emoji-paragraph">
                      <span>📅</span>
                      <p className="mb-3">
                        É disparada automaticamente a cada 2 semanas.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>⚡</span>
                      <p className="mb-3">
                        Leva 1 min para responder e fica aberta durante 2 dias.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>🥷</span>
                      <p className="mb-3">
                        É anônima e só gera resultado a partir de três respostas efetivas.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>😌</span>
                      <p className="mb-3">
                        É possível não responder por desconforto ou ausência.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>📊</span>
                      <p className="mb-3">
                        O time acessa o resumo do resultado.
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>🚫</span>
                      <p className="mb-2">
                        Não tem conexão com as avaliações de desempenho.
                      </p>
                    </div>
                  </ListItensWithBlueBg>
                )}
              </div>
              <Disclaimer
                estilo="reference"
                titulo="Referência"
                icone={<Brain cor={defaultTheme.colors.primary.main} />}
                className="mt-4 mb-4 text-start"
              >
                <p className="mb-1">
                  Adaptada para o dia a dia do líder e seu squad, criamos uma
                  {' '}
                  pesquisa proprietária a partir da
                  {' '}
                  <b>Gallup Q12.</b>
                </p>
              </Disclaimer>
            </Tab>
            <Tab
              key="tabKey-pipAgendamento"
              eventKey="pipAgendamento"
              className="baseStyleTabs"
              title={(
                <>
                  <Calendar />
                  <span>Agendamento</span>
                </>
              )}
            >
              {currentUserContextData.type === 'Líder' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
                    <h4 className="bold mb-1" style={{ color: defaultTheme.colors.primary.main }}>
                      {pipEnableCurrentTeam ? 'PIP habilitada' : 'PIP desabilitada'}
                      {' '}
                      neste time
                    </h4>
                  </div>
                  {/* eslint-disable-next-line */}
                  <label htmlFor={`pip-toogle-${currentTeam._id}`}>
                    <input
                      id={`pip-toogle-${currentTeam._id}`}
                      type="checkbox"
                      className="toggle-checkbox"
                      onChange={() => handleSwitchPip(currentTeamId)}
                      checked={pipEnableCurrentTeam}
                    />
                    <div className="toggle-switch" />
                  </label>
                </div>
                <hr className="my-4" />
              </>
              )}
              <h4 className="bold mb-3" style={{ color: defaultTheme.colors.primary.main }}>Cronograma</h4>

              {/* map getQuestByTeamData to construct historico */}
              {getQuestByTeamData && getQuestByTeamData.length > 0 ? (
                getQuestByTeamData.slice().reverse().map((quest) => (
                  <div key={quest._id} className="mb-3">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                      {returnQuestStatus({
                        startDate: quest.startDate,
                        finalDate: quest.finalDate,
                      }).status === 'Agendada' && (
                        <>
                          {/* apenas um comentário */}
                          {pipEnableCurrentTeam ? (
                            <Tag className="mb-0 warning">Agendada</Tag>
                          ) : (
                            <Tag className="mb-0">PIP desabilitada</Tag>
                          )}
                        </>
                      )}

                      {returnQuestStatus({
                        startDate: quest.startDate,
                        finalDate: quest.finalDate,
                      }).status === 'Em andamento' && (
                        <>
                          {/* apenas um comentário */}
                          {pipEnableCurrentTeam ? (
                            <Tag className="mb-0 success">Em andamento</Tag>
                          ) : (
                            <Tag className="mb-0">PIP desabilitada</Tag>
                          )}
                        </>
                      )}

                      {returnQuestStatus({
                        startDate: quest.startDate,
                        finalDate: quest.finalDate,
                      }).status === 'Fechada' && (
                      <>
                        {/* eslint-disable-next-line */}
                        {quest?.results[0]?.results?.zona ? (
                          <Tag className="mb-0 success">Concluída</Tag>
                        ) : (
                          quest?.results[0]?.emptyResultReason === 'pipEnabledFalse' ? (
                            <Tag className="mb-0">PIP desabilitada</Tag>
                          ) : (
                            <Tag className="mb-0 danger">Respostas insuficientes</Tag>
                          )
                        )}
                      </>
                      )}

                      <p className="mb-0 bold small">
                        {formatDateDDMM(quest.startDate, '/')}
                        {' '}
                        até
                        {' '}
                        {formatDateDDMMYYYY(quest.finalDate, '/')}
                      </p>

                    </div>
                  </div>
                ))
              )
                : (
                  <div className="text-center">
                    <img src={SoraPreocupacao} alt="Olhos suplicantes" style={{ width: '100%', maxWidth: '190px', margin: '0 auto' }} className="mb-2" />
                    <p>Ainda não há resultados disponíveis.</p>
                  </div>
                )}
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      <Sheet
        isOpen={pipQuestionsDemoSheetIsOpen}
        onClose={() => setPipQuestionsDemoSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="full-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!pipQuestionsDemoSheetIsOpen}
      >
        <PipQuestionsDemo setPipQuestionsDemoSheetOpen={setPipQuestionsDemoSheetOpen} />
        <Sheet.Backdrop onTap={() => setPipQuestionsDemoSheetOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={pipRespostasEfetivasSheetIsOpen}
        onClose={() => setPipRespostasEfetivasSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        disableDrag={!pipRespostasEfetivasSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setPipRespostasEfetivasSheetOpen(false)}>
              <Cross />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
              >
                <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>Respostas</h2>
                <p className="mb-2" style={{ textWrap: 'pretty' }}>
                  Quando a PIP é aberta, a pessoa pode optar por não
                  {' '}
                  responder em caso de desconforto ou ausência.
                </p>
                <p className="mb-2" style={{ textWrap: 'pretty' }}>
                  <b>A resposta só é considerada efetiva quando ela opta por responder</b>
                  ,
                  {' '}
                  e o resultado é gerado apenas a partir de 3 respostas efetivas.
                </p>
                <PersoraButton
                  // tamanho=""
                  estilo="outline"
                  onClick={() => {
                    setPipRespostasEfetivasSheetOpen(false);
                    setPipQuestionsDemoSheetOpen(true);
                  }}
                  className="mt-4 px-3 mb-4"
                >
                  <span style={{ marginRight: '-5px' }}>
                    <QuestionCircle tamanho={24} />
                  </span>
                  O que o meu time responde?
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setPipRespostasEfetivasSheetOpen(false)} />
      </Sheet>
    </div>
  );
}
WorkspaceTabs.propTypes = {
  currentTeam: PropTypes.shape({
    _id: PropTypes.string,
    emoji: PropTypes.string,
    name: PropTypes.string,
    toolsSettings: PropTypes.shape({
      pipEnabled: PropTypes.bool,
    }),
  }).isRequired,
  setPipFormOpen: PropTypes.func.isRequired,
  pipEnableCurrentTeam: PropTypes.bool.isRequired,
  setPipEnableCurrentTeam: PropTypes.func.isRequired,
};
export default function Main({
  activeTapBar,
  setActiveTapBar,
  reloadTeamData,
  setReloadTeamData,
  currentTeam,
  setAddUserSheetOpen,
  setPremiumPricesSheetOpen,
  setCountCurrentTeamUsers,
  setPipFormOpen,
  pipEnableCurrentTeam,
  setPipEnableCurrentTeam,
}) {
  const [teamData, setTeamData] = useState([]);
  const [userData, setUserData] = useState({}); // Novo estado
  const [dataLoaded, setDataLoaded] = useState(true); // Novo estado
  const [dataUserTabIsLoaded, setDataUserTabIsLoaded] = useState(false); // Novo estado
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);

  const handleDeleteUser = async (userId) => {
    Swal.fire({
      title: 'Deseja realmente excluir a conta deste usuário?',
      text: 'Esta ação é irreversível!',
      showCancelButton: true,
      // width: 370,
      confirmButtonColor: defaultTheme.colors.alerts.danger.backgroundRed,
      confirmButtonText: 'Sim, excluir usuário!',
      cancelButtonText: 'Cancelar',
      reverseButtons: false,
      focusConfirm: false,
      focusCancel: true,
      customClass: {
        confirmButton: 'swal2-button-full-width',
        cancelButton: 'swal2-button-full-width',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const body = {
            id: userId,
            idTeam: currentTeam._id,
          };
          const { data } = await api.patch('/user/delete', body);
          console.log(data);
          // remove deleted user from teamData
          const newTeamData = teamData.filter((user) => user._id !== userId);
          setTeamData(newTeamData);
          // setReloadTeamData(true);
          setCurrentUserContextData((prevData) => ({
            ...prevData,
            workspaceContext: {
              ...prevData.workspaceContext,
              totalUsers: prevData.workspaceContext.totalUsers - 1,
              totalInvitedUsers: prevData.workspaceContext.totalInvitedUsers - 1,
            },
          }));
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
            ReactGA.event({
              category: 'Engajamento',
              action: 'convite_excluido',
            });
            mixpanel.track('convite_excluido', {
              category: 'Engajamento',
              company_id: currentUserContextData._idWorkspace[0],
            });
          }
          toast.success('Usuário excluído com sucesso!', {
            autoClose: 3000,
            position: 'top-center',
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handleResendInvite = async ({ userId }) => {
    // console.log(`Reenviar convite ${userId}`);
    const button = document.getElementById(`resend-invite-${userId}`);
    const prevInnerHtml = button.innerHTML;
    button.disabled = true;
    button.textContent = 'Reenviando...';
    try {
      const body = {
        id: userId,
      };
      await api.post('/user/reSendInvite', body);
      button.textContent = 'Convite reenviado! 😉';
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'convite_reenviado',
        });
        mixpanel.track('convite_reenviado', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
        });
      }
      toast.success('O convite foi reenviado por email! 😉', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (error) {
      toast.error('Tivemos um problema ao reenviar o convite. Tente novamente em instantes.', {
        autoClose: 3000,
        position: 'top-center',
      });
      button.innerHTML = prevInnerHtml;
      button.disabled = false;
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeTapBar === 'time') {
      setTeamData([]);
      setDataLoaded(false);
      (async () => {
        try {
          const { data } = await api.get('/user');
          setUserData(data.user);
          setDataLoaded(true); // Defina como true somente quando os dados forem carregados
        } catch (error) {
          console.log(error);
        }
      })();
      (async () => {
        try {
          const { data } = await api.get(`/user/getUsers?teamId=${currentTeam._id}`);
          // console.log('data tem getusers', data); // DEBUG
          setReloadTeamData(false);
          // order teamData first by firstAuth == true, then by type == 'Lider', then by name
          const sortedData = data.sort((a, b) => {
            if (a.displayedProfileLetters && !b.displayedProfileLetters) {
              return -1;
            }
            if (!a.displayedProfileLetters && b.displayedProfileLetters) {
              return 1;
            }
            if (a.type === 'Líder' && b.type !== 'Líder') {
              return -1;
            }
            if (a.type !== 'Líder' && b.type === 'Líder') {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          if (currentUserContextData.workspaceContext.profileSora) {
            sortedData.splice(1, 0, currentUserContextData.workspaceContext.profileSora);
          }
          // const index = sortedData.findIndex((user) => user._id === userData._id);
          // if (index !== -1) {
          //   sortedData.splice(0, 0, sortedData.splice(index, 1)[0]);
          // }
          setTeamData(sortedData);
          setCountCurrentTeamUsers(sortedData.length);
          setDataLoaded(true); // Defina como true somente quando os dados forem carregados
        } catch (error) {
          console.log(error);
        }
      })();
    }
    if (activeTapBar === 'home') {
      // setUserData({});
      // setDataLoaded(false);
      // console.log('dadosUsuarioAgora', userData); // DEBUG
      // (async () => {
      //   try {
      //     const { data } = await api.get('/user');
      //     setUserData(data.user);
      //     setDataLoaded(true); // Defina como true somente quando os dados forem carregados
      //   } catch (error) {
      //     console.log(error);
      //   }
      // })();
    }
    if (activeTapBar === 'user' && !dataUserTabIsLoaded) {
      setUserData({});
      setDataLoaded(false);
      setDataUserTabIsLoaded(true);
      // console.log('dadosUsuarioAgora', userData); // DEBUG
      (async () => {
        try {
          const { data } = await api.get('/user');
          setUserData(data.user);
          setDataLoaded(true); // Defina como true somente quando os dados forem carregados
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [activeTapBar, reloadTeamData]);
  useEffect(() => {
    if (activeTapBar === 'user' && !dataUserTabIsLoaded) {
      setUserData({});
      setDataLoaded(false);
      setDataUserTabIsLoaded(true);
      // console.log('dadosUsuarioAgora', userData); // DEBUG
      (async () => {
        try {
          const { data } = await api.get('/user');
          setUserData(data.user);
          setDataLoaded(true); // Defina como true somente quando os dados forem carregados
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  if (!dataLoaded) {
    // console.log('dadosUsuarioDepois', userData); // DEBUG
    return <PersoraLoader text="" />;
  }

  return (
    <>
      {(activeTapBar === 'user') && (
        <HomeTabs userData={userData} />
      )}
      {activeTapBar === 'time' && (
        <>
          {userData.disclaimers
            && 1 === 0
            && !userData.disclaimers.premiumDisclaimerAtTeamTab
            && userData.type === 'Líder'
            && currentUserContextData.workspaceContext.premium !== true
            && (
              <div
                className="pt-3"
                style={{
                  width: `calc(100% - (${defaultTheme.spaces.screenEdge} * 2)`,
                  marginLeft: defaultTheme.spaces.screenEdge,
                }}
              >
                <PremiumDisclaimer modelo="condensado" />
              </div>
            )}
          <TimeTabs
            userData={userData}
            teamData={teamData}
            currentTeam={currentTeam}
            dataLoaded={dataLoaded}
            handleDeleteUser={handleDeleteUser}
            handleResendInvite={handleResendInvite}
            setAddUserSheetOpen={setAddUserSheetOpen}
            setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
          />
        </>
      )}
      {activeTapBar === 'home' && (
        <>
          {userData.type === 'Líder'
            && 1 === 0
            && currentUserContextData.workspaceContext.premium !== true
            && (
            <div
              className="pt-3"
              style={{
                width: `calc(100% - (${defaultTheme.spaces.screenEdge} * 2)`,
                marginLeft: defaultTheme.spaces.screenEdge,
              }}
            >
              <PremiumDisclaimer modelo="expandido" />
            </div>
            )}
          <div className="mt-4" style={{ width: '100%' }}>
            <Tabs
              defaultActiveKey="overview"
              transition={false}
              id="time-tabs"
            >
              <Tab
                eventKey="overview"
                className="baseStyleTabs pb-4"
                title={(
                  <>
                    <Home1 />
                    <span>Inicio</span>
                  </>
                )}
              >
                {/* <h4 className="semibold sourcesans mb-0">Nudges</h4>
                <NudgesHomeSlider /> */}
                <h4 className="semibold sourcesans mt-2 mb-0">Em Destaque</h4>
                <HighlightsHomeSlider setActiveTapBar={setActiveTapBar} />
                <h4 className="semibold sourcesans mt-3">Para ficar em dia</h4>
                <NewGoalsTracker />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
      {activeTapBar === 'organizacao' && (
        <WorkspaceTabs
          userData={userData}
          currentTeam={currentTeam}
          setPipFormOpen={setPipFormOpen}
          pipEnableCurrentTeam={pipEnableCurrentTeam}
          setPipEnableCurrentTeam={setPipEnableCurrentTeam}
        />
      )}
    </>
  );
}
Main.propTypes = {
  activeTapBar: PropTypes.string.isRequired,
  setActiveTapBar: PropTypes.func.isRequired,
  reloadTeamData: PropTypes.bool.isRequired,
  setReloadTeamData: PropTypes.func.isRequired,
  setAddUserSheetOpen: PropTypes.func.isRequired,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
  setCountCurrentTeamUsers: PropTypes.func.isRequired,
  currentTeam: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  setPipFormOpen: PropTypes.func.isRequired,
  pipEnableCurrentTeam: PropTypes.bool.isRequired,
  setPipEnableCurrentTeam: PropTypes.func.isRequired,
};
