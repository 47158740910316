import PropTypes from 'prop-types';
import {
  useState, useRef, useEffect, useContext,
} from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { Sheet } from 'react-modal-sheet';
import {
  AudioContainer, Photo, PlayPauseButton, ProgressTime, RangeInput, SpeedButton,
  TranscriptButton,
} from './styles';
import {
  Cross,
  ListRight, Pause, Play,
} from '../DuotoneIcon';
import defaultTheme from '../../assets/styles/themes/default';
import { UserContext } from '../../Context/UserContext';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import PersoraButton from '../Button';

export default function AudioPlayer({
  src, photo, transcript, mixpanelAudioTitleToTrack, ...props
}) {
  const { currentUserContextData } = useContext(UserContext);
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [hasTracked70Percent, setHasTracked70Percent] = useState(false); // Estado para rastrear 70%
  const [transcriptSheetIsOpen, setTranscriptSheetOpen] = useState(false);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const handleSpeedChange = () => {
    setSpeed((prevSpeed) => {
      if (prevSpeed === 1) return 1.5;
      if (prevSpeed === 1.5) return 2;
      return 1;
    });
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed;
    }
  }, [speed]);

  const handleProgressChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setProgress(newValue);
    if (audioRef.current) {
      audioRef.current.currentTime = newValue;
    }
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const { currentTime } = audioRef.current;
        setProgress(currentTime);

        // Calcula a porcentagem do áudio escutado
        const percentage = (currentTime / duration) * 100;

        // Envia o evento quando atingir 70%
        if (percentage >= 70 && !hasTracked70Percent) {
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true' && mixpanelAudioTitleToTrack) {
            ReactGA.event({
              category: 'Engajamento',
              action: 'audio_escutado',
              label: 'Pip audio',
            });
            mixpanel.track('audio_escutado', {
              category: 'Engajamento',
              company_id: currentUserContextData._idWorkspace[0],
              title: 'Pip audio',
            });
            console.log('70% do audio escutado:', mixpanelAudioTitleToTrack);
          }
          setHasTracked70Percent(true);
        }
      }
    };
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [duration, hasTracked70Percent]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <AudioContainer {...props}>
      {/* eslint-disable-next-line */}
      <audio
        src={src}
        ref={audioRef}
        // eslint-disable-next-line
        onTimeUpdate={(e) => {
          if (audioRef.current) {
            setProgress(audioRef.current.currentTime);
          }
        }}
        // eslint-disable-next-line
        onLoadedMetadata={(e) => {
          if (audioRef.current) {
            setDuration(audioRef.current.duration);
          }
        }}
        onEnded={() => {
          setPlaying(false);
          setProgress(0);
        }}
      />
      <PlayPauseButton type="button" onClick={handlePlayPause}>
        {playing
          ? <Pause cor={defaultTheme.colors.primary.light} tamanho={28} />
          : <Play cor={defaultTheme.colors.secondary.dark} tamanho={30} />}
      </PlayPauseButton>
      <RangeInput
        type="range"
        value={progress}
        onChange={handleProgressChange}
        max={duration}
      />
      <ProgressTime>
        {progress === 0 ? formatTime(duration) : formatTime(progress)}
      </ProgressTime>
      <Photo style={{ backgroundImage: `url(${photo})` }}>
        <SpeedButton type="button" onClick={handleSpeedChange} disabled={!playing}>
          {speed === 1 ? '1x' : ''}
          {speed === 1.5 ? '1.5x' : ''}
          {speed === 2 ? '2x' : ''}
        </SpeedButton>
      </Photo>
      {transcript.length > 0 && (
        <>
          <TranscriptButton onClick={() => setTranscriptSheetOpen(true)}>
            <ListRight />
          </TranscriptButton>
          <Sheet
            isOpen={transcriptSheetIsOpen}
            onClose={() => setTranscriptSheetOpen(false)}
            onOpenStart={startAndroidSheetHack}
            onCloseEnd={breakAndroidSheetHack}
            detent="content-height"
            tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
            disableScrollLocking
            disableDrag={!transcriptSheetIsOpen}
          >
            <Sheet.Container>
              <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
                <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setTranscriptSheetOpen(false)}>
                  <Cross />
                </PersoraButton>
              </Sheet.Header>
              <Sheet.Content>
                <Sheet.Scroller>
                  <div
                    style={{
                      padding: defaultTheme.spaces.screenEdge,
                      paddingTop: 0,
                      color: defaultTheme.colors.primary.light,
                    }}
                  >
                    <h2 className="medium sourcesans mb-3" style={{ color: defaultTheme.colors.primary.main }}>Transcrição do áudio</h2>
                    {transcript.map((line, index) => (
                      // eslint-disable-next-line
                      <p key={`transcriptParagraph-${index}`} style={{ fontSize: '1.05rem', textWrap: 'pretty' }}>
                        {line}
                      </p>
                    ))}
                  </div>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onTap={() => setTranscriptSheetOpen(false)} />
          </Sheet>
        </>
      )}
    </AudioContainer>
  );
}

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  transcript: PropTypes.arrayOf(PropTypes.string),
  mixpanelAudioTitleToTrack: PropTypes.string,
};
AudioPlayer.defaultProps = {
  mixpanelAudioTitleToTrack: '',
  transcript: [],
};
