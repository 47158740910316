import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.surfaces.white};
  background-color: ${({ theme }) => theme.colors.primary.main};
  box-shadow: none;
  /* border: none; */
  border: 1px solid transparent;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.colors.primary.main};
  font-size: 18px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  height: fit-content;

  &.is-premium-button {
    background: linear-gradient(135deg,  rgba(34,59,89,1) 38%,rgba(33,131,166,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    &:hover {
      background: linear-gradient(135deg,  rgba(34,59,89,1) 0%,rgba(33,131,166,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }

  &:focus, &:hover {
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.surfaces.white};
    /* box-shadow: 0 4px 6px 0 rgba(67, 89, 113, 0.12); */
  }
  &:disabled {
    border-color: ${({ theme }) => theme.colors.black40};
    background: ${({ theme }) => theme.colors.black40};
    color: ${({ theme }) => theme.colors.black10};
    cursor: not-allowed;
    pointer-events: none;
  }

  &.secondary {
    background: ${({ theme }) => theme.colors.secondary.main};
    color: ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.secondary.main};
    &:hover {
      background: ${({ theme }) => theme.colors.secondary.light};
      color: ${({ theme }) => theme.colors.primary.main};
    }
    &:disabled {
      background: ${({ theme }) => theme.colors.black20};
      color: ${({ theme }) => theme.colors.black40};
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.outline {
    background: ${({ theme }) => theme.colors.surfaces.white};
    color: ${({ theme }) => theme.colors.primary.main};
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.primary.light};
    &.discWord {
      opacity: .7;
      border-radius: 12px;
      padding: 6px 12px;
      font-size: .9rem;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.black10};
      color: ${({ theme }) => theme.colors.primary.main};
    }
    &:disabled {
      background: ${({ theme }) => theme.colors.black5};
      color: ${({ theme }) => theme.colors.black40};
      border-color: ${({ theme }) => theme.colors.black40};
      cursor: not-allowed;
      pointer-events: none;
    }
    &.selected {
      background: ${({ theme }) => theme.colors.secondary.light};
      color: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
    }
    &.not-selected {
      background: ${({ theme }) => theme.colors.black5};
      color: ${({ theme }) => theme.colors.black40};
      border-color: ${({ theme }) => theme.colors.black40};
      b {
        color: ${({ theme }) => theme.colors.black40} !important;
      }
      opacity: .8;
    }
    &.discWord.selected {
      opacity: 1;
      background: ${({ theme }) => theme.colors.secondary.light};
      color: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
      box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
      text-shadow: 0px 0px .5px ${({ theme }) => theme.colors.alerts.success.text};
    }
  }

  &.ghost {
    background: transparent;
    color: ${({ theme }) => theme.colors.primary.main};
    border: 1px solid;
    border-color: transparent;

    &.is-premium-button {
      background: linear-gradient(135deg,  rgba(34,59,89,1) 38%,rgba(33,131,166,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:hover {
        background: linear-gradient(135deg,  rgba(34,59,89,1) 0%,rgba(33,131,166,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.primary.main};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.black40};
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.fit-content {
    width: fit-content;
  }
  &.just-icon {
    padding: 9px !important;
    &:disabled {
      opacity: .6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }


  /* if has class condensed */
  &.condensed {
    width: fit-content;
    font-size: 16px;
    padding: 9px 24px;
  }

  /* if has class mini */
  &.mini {
    width: fit-content;
    font-size: 16px;
    padding: 6px 16px;
  }

  /* if has class navtab */
  &.navtab {
    width: fit-content;
    font-size: 16px;
    padding: 6px 16px;
    background: ${({ theme }) => theme.colors.surfaces.white};
    color: ${({ theme }) => theme.colors.primary.main};
  }

  &.tapBarActive {
    background-color: #fff !important;
    border-radius: 40px;
  }

  &.tapBarActive::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    border-radius: 6px;
    margin-bottom: -42px;
    margin-left: -0px;
    background-color: ${({ theme }) => theme.colors.primary.main};
  }

`;
