import { useContext, useEffect, useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import { Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import EmojiPicker from 'emoji-picker-react';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';
import { zoomies } from 'ldrs';
import styled from 'styled-components';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import api from '../../services/backend';

import {
  ChevronSmallDown, ChevronSmallUp, Crown, Trash, UserPlus,
} from '../../components/DuotoneIcon';
import UserAdminActions from '../../components/UserAdminActions';
import { UserContext } from '../../Context/UserContext';
// import PersoraTextButton from '../../components/TextButton';

zoomies.register();

const CircledButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background: #fff;
  border: 1px solid #fff;
  &.isLeader {
    border-color: #E3A74F;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export default function TeamSettingsSheet({
  handleUpdateTeamSettings,
  currentTeam,
  setTeamSettingsSheetOpen,
  setAddUserSheetOpen,
  setPremiumPricesSheetOpen,
  countCurrentTeamUsers,
  setReloadTeamData,
  setPipEnableCurrentTeam,
}) {
  const [teamData, setTeamData] = useState([]);
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [teamEmoji, setTeamEmoji] = useState(currentTeam.emoji ? currentTeam.emoji : '🤖');
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [descriptionTeam, setDescriptionTeam] = useState(currentTeam.description);
  const [descriptionLenght, setDescriptionLenght] = useState(currentTeam.description?.length || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalLeaders, setTotalLeaders] = useState(0);
  const [totalLed, setTotalLed] = useState(0);
  // handleChangeDescription que setará descriptionTeam com setDescriptionTeam
  const handleChangeDescription = (e) => {
    // limit length to 230 chars
    if (e.target.value.length > 230) {
      e.target.value = e.target.value.substring(0, 230);
      setDescriptionLenght(230);
    }
    setDescriptionLenght(e.target.value.length);
    setDescriptionTeam(e.target.value);
  };

  const handleEmojiClick = (emoji) => {
    // console.log('Emoji selecionado:', emoji);
    setTeamEmoji(emoji.emoji);
    setEmojiPickerOpen(false);
  };

  // api get users (/user/getUsers) from currentTeam (_id) async
  // setTeamData(users)
  // TODO Refatorar para pegar os usuários de todo o workspace
  useEffect(() => {
    setTeamData([]);
    (async () => {
      try {
        const body = {
          idTeam: currentTeam._id,
        };
        // console.log('body tem getusers', body); // DEBUG
        const { data } = await api.get('/user/getUsers', body);
        // console.log('data tem getusers', data); // DEBUG
        // order teamData first by firstAuth == true, then by type == 'Lider', then by name
        const sortedData = data.sort((a, b) => {
          if (a.displayedProfileLetters && !b.displayedProfileLetters) {
            return -1;
          }
          if (!a.displayedProfileLetters && b.displayedProfileLetters) {
            return 1;
          }
          if (a.type === 'Líder' && b.type !== 'Líder') {
            return -1;
          }
          if (a.type !== 'Líder' && b.type === 'Líder') {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setTeamData(sortedData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentTeam]);

  useEffect(() => {
    const leaders = teamData.filter((user) => user.type === 'Líder');
    const led = teamData.filter((user) => user.type === 'Colaborador' && user.displayedProfileLetters);
    setTotalLeaders(leaders.length);
    setTotalLed(led.length);
  }, [teamData]);

  const handleDeleteUser = async (userId, teamId) => {
    if (userId === currentUserContextData._id) {
      toast.error('Você não pode excluir a si mesmo!', {
        autoClose: 3000,
        position: 'top-center',
      });
      return;
    }
    const userFullName = teamData.find((user) => user._id === userId).name;
    Swal.fire({
      // title: 'Deseja realmente excluir a conta deste usuário?',
      // text: 'Esta ação é irreversível!',
      html: `Tem certeza de que deseja excluir definitivamente <b>${userFullName || 'este usuário'}</b> do seu workspace?`,
      showCancelButton: true,
      // width: 370,
      confirmButtonColor: defaultTheme.colors.alerts.danger.backgroundRed,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: false,
      focusConfirm: false,
      focusCancel: true,
      customClass: {
        confirmButton: 'swal2-button-full-width',
        cancelButton: 'swal2-button-full-width',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const body = {
            id: userId,
            idTeam: currentTeam._id,
          };
          const { data } = await api.patch('/user/delete', body);
          console.log(data);
          // remove deleted user from teamData
          const newTeamData = teamData.filter((user) => user._id !== userId);
          const userType = teamData.find((user) => user._id === userId).type;
          const userTypeAjusted = userType === 'Líder' ? 'Líder' : 'Liderado';
          setTeamData(newTeamData);
          // setReloadTeamData(true);
          setCurrentUserContextData((prevData) => ({
            ...prevData,
            workspaceContext: {
              ...prevData.workspaceContext,
              totalUsers: prevData.workspaceContext.totalUsers - 1,
              totalInvitedUsers: prevData.workspaceContext.totalInvitedUsers - 1,
              ledUsers: prevData.workspaceContext.ledUsers - (teamData.filter((user) => user._id === userId && user.type === 'Colaborador') ? 1 : 0),
              ledUsersMapped: prevData.workspaceContext.ledUsersMapped - (teamData.filter((user) => user._id === userId && user.displayedProfileLetters && user.type === 'Colaborador') ? 1 : 0),
              leaderUsers: prevData.workspaceContext.leaderUsers - (teamData.filter((user) => user._id === userId && user.type === 'Líder') ? 1 : 0),
              leaderUsersMapped: prevData.workspaceContext.leaderUsersMapped - (teamData.filter((user) => user._id === userId && user.displayedProfileLetters && user.type === 'Líder') ? 1 : 0),
            },
          }));
          if ((totalLed - 1) === 2) {
            setPipEnableCurrentTeam(false);
            const newTeams = currentUserContextData.workspaceContext.teams.map((team) => {
              if (team._id === teamId) {
                return {
                  ...team,
                  toolsSettings: {
                    ...team.toolsSettings,
                    pipEnabled: false,
                  },
                };
              }
              return team;
            });
            setCurrentUserContextData((prevData) => ({
              ...prevData,
              toolsSettings: {
                ...prevData.workspaceContext.toolsSettings,
                pip: {
                  ...prevData.workspaceContext.toolsSettings.pip,
                  enabled: false,
                },
              },
              workspaceContext: {
                ...prevData.workspaceContext,
                teams: newTeams,
              },
            }));
          }
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
            ReactGA.event({
              category: 'Gerenciamento do time',
              action: 'usuario_excluido',
              type: userTypeAjusted,
            });
            mixpanel.track('usuario_excluido', {
              category: 'Gerenciamento do time',
              company_id: currentUserContextData._idWorkspace[0],
              type: userTypeAjusted,
            });
          }
          toast.success('Usuário excluído com sucesso!', {
            autoClose: 3000,
            position: 'top-center',
          });
          setReloadTeamData(true);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    });
  };

  // eslint-disable-next-line
  const handlePromoteLed = async (userId, teamId) => {
    console.log('Promover usuário para Líder:', userId, teamId);
    const userFullName = teamData.find((user) => user._id === userId).name;
    Swal.fire({
      title: ((totalLed - 1) === 2 ? 'Importante' : false),
      html: `${(totalLed - 1) === 2
        ? `Se você alterar a permissão de <b>${userFullName}</b> para líder, o número de liderados no seu time ficará abaixo do mínimo necessário para utilizar a PIP.`
        : `Tem certeza de que deseja alterar a permissão de <b>${userFullName}</b> para líder?`}
      `,
      showCancelButton: true,
      // width: 370,
      confirmButtonColor: ((totalLed - 1) === 2
        ? defaultTheme.colors.alerts.danger.backgroundRed
        : defaultTheme.colors.primary.main
      ),
      confirmButtonText: 'Alterar para líder',
      cancelButtonText: 'Cancelar',
      reverseButtons: false,
      focusConfirm: false,
      focusCancel: true,
      customClass: {
        confirmButton: 'swal2-button-full-width',
        cancelButton: 'swal2-button-full-width',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        // update user type to 'Líder' on teamData
        const newTeamData = teamData.map((user) => {
          if (user._id === userId) {
            return { ...user, type: 'Líder' };
          }
          return user;
        });
        setTeamData(newTeamData);
        try {
          const body = {
            idUser: userId,
            idTeam: teamId,
          };
          await api.patch('/user/promoteLed', body);
          toast.success('Permissão do usuário atualizada com sucesso!', {
            autoClose: 3000,
            position: 'top-center',
          });
          setCurrentUserContextData((prevData) => ({
            ...prevData,
            workspaceContext: {
              ...prevData.workspaceContext,
              ledUsers: prevData.workspaceContext.ledUsers - 1,
              ledUsersMapped: prevData.workspaceContext.ledUsersMapped - 1,
              leaderUsers: prevData.workspaceContext.leaderUsers + 1,
              leaderUsersMapped: prevData.workspaceContext.leaderUsersMapped + 1,
            },
          }));
          // if (totalLed - 1) === 2,
          // setCurrentUserContextData.workspaceContext.toolsSettings.pip.enabled = false
          // if ((totalLed - 1) === 2) {
          //   setCurrentUserContextData((prevData) => ({
          //     ...prevData,
          //     workspaceContext: {
          //       ...prevData.workspaceContext,
          //       toolsSettings: {
          //         ...prevData.workspaceContext.toolsSettings,
          //         pip: {
          //           ...prevData.workspaceContext.toolsSettings.pip,
          //           enabled: false,
          //         },
          //       },
          //     },
          //   }));
          // }
          // if (totalLed - 1) === 2,
          // setCurrentUserContextData.workspaceContext.teams (que tenha o teamId)
          // .toolSettings.pipEnabled = false
          if ((totalLed - 1) === 2) {
            setPipEnableCurrentTeam(false);
            const newTeams = currentUserContextData.workspaceContext.teams.map((team) => {
              if (team._id === teamId) {
                return {
                  ...team,
                  toolsSettings: {
                    ...team.toolsSettings,
                    pipEnabled: false,
                  },
                };
              }
              return team;
            });
            setCurrentUserContextData((prevData) => ({
              ...prevData,
              toolsSettings: {
                ...prevData.workspaceContext.toolsSettings,
                pip: {
                  ...prevData.workspaceContext.toolsSettings.pip,
                  enabled: false,
                },
              },
              workspaceContext: {
                ...prevData.workspaceContext,
                teams: newTeams,
              },
            }));
          }
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
            ReactGA.event({
              category: 'Gerenciamento do time',
              action: 'permissao_de_usuario_alterada',
              situation: 'Promoção',
            });
            mixpanel.track('permissao_de_usuario_alterada', {
              category: 'Gerenciamento do time',
              company_id: currentUserContextData._idWorkspace[0],
              situation: 'Promoção',
            });
          }
          setReloadTeamData(true);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          // rollback user type to 'Liderado' on teamData
          const rollbackTeamData = teamData.map((user) => {
            if (user._id === userId) {
              return { ...user, type: 'Colaborador' };
            }
            return user;
          });
          setTeamData(rollbackTeamData);
          setIsLoading(false);
        }
      }
    });
  };

  const handleDeomoteLeader = async (userId, teamId) => {
    // console.log('Despromover usuário para Liderado:', userId, teamId);
    const userFullName = teamData.find((user) => user._id === userId).name;
    if (userId === currentUserContextData._id) {
      toast.error('Você não pode despromover a si mesmo!', {
        autoClose: 3000,
        position: 'top-center',
      });
      return;
    }
    Swal.fire({
      // title: 'Deseja realmente desprover este usuário à Liderado?',
      html: `
        Tem certeza de que deseja alterar a permissão de <b>${userFullName}</b> para liderado?
      `,
      showCancelButton: true,
      // width: 370,
      confirmButtonColor: defaultTheme.colors.primary.main,
      confirmButtonText: 'Alterar para liderado',
      cancelButtonText: 'Cancelar',
      reverseButtons: false,
      focusConfirm: false,
      focusCancel: true,
      customClass: {
        confirmButton: 'swal2-button-full-width',
        cancelButton: 'swal2-button-full-width',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        // update user type to 'Colaborador' on teamData
        const newTeamData = teamData.map((user) => {
          if (user._id === userId) {
            return { ...user, type: 'Colaborador' };
          }
          return user;
        });
        setTeamData(newTeamData);
        try {
          const body = {
            idUser: userId,
            idTeam: teamId,
          };
          await api.patch('/user/demoteLeader', body);
          toast.success('Permissão do usuário atualizada com sucesso!', {
            autoClose: 3000,
            position: 'top-center',
          });
          setCurrentUserContextData((prevData) => ({
            ...prevData,
            workspaceContext: {
              ...prevData.workspaceContext,
              leaderUsers: prevData.workspaceContext.leaderUsers - 1,
              leaderUsersMapped: prevData.workspaceContext.leaderUsersMapped - 1,
              ledUsers: prevData.workspaceContext.ledUsers + 1,
              ledUsersMapped: prevData.workspaceContext.ledUsersMapped + 1,
            },
          }));
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
            ReactGA.event({
              category: 'Gerenciamento do time',
              action: 'permissao_de_usuario_alterada',
              situation: 'Despromoção',
            });
            mixpanel.track('permissao_de_usuario_alterada', {
              category: 'Gerenciamento do time',
              company_id: currentUserContextData._idWorkspace[0],
              situation: 'Despromoção',
            });
          }
          setReloadTeamData(true);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          // rollback user type to 'Lider' on teamData
          const rollbackTeamData = teamData.map((user) => {
            if (user._id === userId) {
              return { ...user, type: 'Líder' };
            }
            return user;
          });
          setTeamData(rollbackTeamData);
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              minHeight: 400,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <h3 className="medium">Configuração do time</h3>
            <h5 className="mb-4">
              Ajuste as informações sobre este time para todos em seu workspace.
            </h5>
            <form id="updateTeamSettingsForm" onSubmit={handleUpdateTeamSettings}>
              <Row>
                <Form.Control
                  type="hidden"
                  name="_id"
                  defaultValue={currentTeam._id}
                />
                <Form.Group className="col-8">
                  <Form.Label>
                    Nome
                    {' '}
                    <span> • Nomeie este time</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="É assim que ele será referenciado"
                    className="mb-3"
                    name="name"
                    defaultValue={currentTeam.name}
                  />
                </Form.Group>
                <Form.Group
                  className="col-4 mb-3 text-center"
                  style={{ position: 'relative' }}
                >
                  <Form.Label>Emoji</Form.Label>
                  <Form.Control
                    type="button"
                    className="mb-3 text-start"
                    name="emoji"
                    defaultValue={teamEmoji}
                    style={{ fontSize: '26px', padding: '3px', paddingLeft: '16px' }}
                    onClick={() => setEmojiPickerOpen(!isEmojiPickerOpen)}
                  />
                  <div style={{
                    position: 'absolute', top: '40px', right: '24px', pointerEvents: 'none',
                  }}
                  >
                    {isEmojiPickerOpen ? (<ChevronSmallUp />) : (<ChevronSmallDown />)}
                  </div>
                </Form.Group>
              </Row>
              <EmojiPicker
                lazyLoadEmojis
                className="mb-4"
                searchDisabled
                width="100%"
                height="265px"
                suggestedEmojisMode="recent"
                open={isEmojiPickerOpen}
                onEmojiClick={handleEmojiClick}
                emojiStyle="native"
                previewConfig={{ showPreview: false }}
              />
              <Form.Group style={{ position: 'relative' }}>
                <Form.Label>
                  Descrição
                  {' '}
                  <span> • Defina em uma frase o propósito do time</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Fique à vontade e utilize este espaço para descrever brevemente o propósito deste time."
                  className="mb-3"
                  name="description"
                  style={{
                    height: (descriptionLenght < 100 ? '100px' : '165px'),
                  }}
                  defaultValue={descriptionTeam}
                  onChange={handleChangeDescription}
                />
                <div style={{
                  position: 'absolute',
                  bottom: '3px',
                  right: '10px',
                  color: (descriptionLenght >= 200 ? 'red' : defaultTheme.colors.primary.light),
                  opacity: 0.8,
                }}
                >
                  <small>
                    {descriptionLenght}
                    /230
                  </small>
                </div>
              </Form.Group>
              <PersoraButton className="mt-4 mb-3" type="submit" form="updateTeamSettingsForm">Salvar</PersoraButton>
            </form>
            <div
              className="mt-4"
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',
              }}
            >
              <div>
                <h4 className="mb-0 medium">Membros do time</h4>
                <p style={{ color: defaultTheme.colors.primary.light }} className="mb-0">
                  <small>
                    Até
                    {' '}
                    <s>1 líder</s>
                    {' '}
                    3 líderes.
                  </small>
                </p>
              </div>
              <div>
                {/* quando clicado, fecha teamsettingssheet e abre addusersheet */}
                {currentUserContextData.type === 'Líder'
                && currentUserContextData.workspaceContext.premium !== true
                && (
                  countCurrentTeamUsers >= 100 ? (
                    <PersoraButton
                      estilo="ghost"
                      tamanho="mini"
                      style={{ paddingRight: 0 }}
                      onClick={() => {
                        setTeamSettingsSheetOpen(false);
                        setPremiumPricesSheetOpen(true);
                      }}
                    >
                      <UserPlus tamanho={22} />
                      {' '}
                      Convidar
                    </PersoraButton>
                  ) : (
                    <PersoraButton
                      estilo="ghost"
                      tamanho="mini"
                      style={{ paddingRight: 0 }}
                      onClick={() => {
                        setTeamSettingsSheetOpen(false);
                        setAddUserSheetOpen(true);
                      }}
                    >
                      <UserPlus tamanho={22} />
                      {' '}
                      Convidar
                    </PersoraButton>
                  )
                )}
                {currentUserContextData.workspaceContext.premium === true
                && currentUserContextData.type === 'Líder'
                && (
                  <PersoraButton
                    estilo="ghost"
                    tamanho="mini"
                    style={{ paddingRight: 0 }}
                    onClick={() => {
                      setTeamSettingsSheetOpen(false);
                      setAddUserSheetOpen(true);
                    }}
                  >
                    <UserPlus tamanho={22} />
                      {' '}
                    Convidar
                  </PersoraButton>
                )}
              </div>
            </div>
            {(teamData.length === 0 || isLoading)
            && (
              <div style={{
                width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1rem',
              }}
              >
                <l-zoomies
                  size="150"
                  stroke="4"
                  bg-opacity="0.1"
                  speed="1.2"
                  color={defaultTheme.colors.primary.light}
                />
              </div>
            )}
            <div className="row mt-3 mb-3">
              <div className="col-12">
                {teamData.map((user) => (
                  <div key={user._id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <UserAdminActions userData={user} />
                    {/* {user._id !== currentUserContextData._id && ( */}
                    <div className="pb-3" style={{ display: 'flex', gap: 6 }}>
                      {user.type === 'Líder'
                        ? (
                          <CircledButton onClick={() => { handleDeomoteLeader(user._id, currentTeam._id); }} className="semibold isLeader" disabled={isLoading || !user.displayedProfileLetters}>
                            <Crown tamanho={20} cor="#E3A74F" />
                          </CircledButton>
                        )
                        : (
                          <CircledButton onClick={() => { handlePromoteLed(user._id, currentTeam._id); }} className="semibold" disabled={isLoading || !user.displayedProfileLetters || totalLeaders >= 3}>
                            <Crown tamanho={20} />
                          </CircledButton>
                        )}
                      <CircledButton onClick={() => { handleDeleteUser(user._id, currentTeam._id); }} className="semibold" disabled={isLoading}>
                        <Trash tamanho={20} />
                      </CircledButton>
                    </div>
                    {/* )} */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

TeamSettingsSheet.propTypes = {
  handleUpdateTeamSettings: PropTypes.func.isRequired,
  currentTeam: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    emoji: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  setTeamSettingsSheetOpen: PropTypes.func.isRequired,
  setAddUserSheetOpen: PropTypes.func.isRequired,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
  countCurrentTeamUsers: PropTypes.number.isRequired,
  setReloadTeamData: PropTypes.func.isRequired,
  setPipEnableCurrentTeam: PropTypes.func.isRequired,
};
