import PropTypes from 'prop-types';
import { useContext, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import {
  Container, Row, Col,
} from 'react-bootstrap';
// import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { useHistory } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
// import logo from '../../assets/img/logo-horizontal.svg';
// import welcomeLider1 from '../../assets/img/welcome-lider-1.svg';
import ReactPlayer from 'react-player/youtube';
import welcomeLider2 from '../../assets/img/welcome-lider-2.svg';
// import welcomeLider3 from '../../assets/img/welcome-lider-3.svg';
import welcomeLider4 from '../../assets/img/welcome-lider-4.svg';
import welcomeColaborador1 from '../../assets/img/welcome-colaborador-1.svg';
import welcomeColaborador2 from '../../assets/img/welcome-colaborador-2.svg';
import api from '../../services/backend';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';
// modules styles
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  ButtonsDiv,
  Left,
  ListItensWithBlueBg,
  PaginationDiv,
  WelcomeBody,
  WelcomeContainer,
  WelcomeFooter,
  WelcomeImage,
} from './styles';
import { ArrowRight, Clock, InfoCircle } from '../DuotoneIcon';
import PersoraManifest from '../../pages/Sheets/PersoraManifest';
import PersoraTextButton from '../TextButton';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { UserContext } from '../../Context/UserContext';

SwiperCore.use([Pagination, Navigation]);

export default function Welcome() {
  const history = useHistory();
  const currentURL = new URL(window.location.href);
  const { currentUserContextData } = useContext(UserContext);
  console.log(currentUserContextData);

  // Verifica se a URL possui o parâmetro "revisit"
  const hasRevisitParam = currentURL.searchParams.has('revisit');

  const [isLoading, setIsLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [persoraManifestSheetIsOpen, setPersoraManifestSheetOpen] = useState(false);

  const handleWelcomeDone = async (event) => {
    event.preventDefault();
    if (hasRevisitParam) {
      history.push('/userSpace');
      return;
    }
    if (event.target.value === 'pular') {
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Onboarding',
          action: 'onboarding_concluido',
          onboarding_title: 'Boas-vindas',
        });
        mixpanel.track('onboarding_concluido', {
          category: 'Onboarding',
          company_id: currentUserContextData._idWorkspace[0],
          onboarding_title: 'Boas-vindas',
        });
      }
    }
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      ReactGA.event({
        category: 'Onboarding',
        action: 'onboarding_concluido',
        onboarding_title: 'Boas-vindas',
      });
      mixpanel.track('onboarding_concluido', {
        category: 'Onboarding',
        company_id: currentUserContextData._idWorkspace[0],
        onboarding_title: 'Boas-vindas',
      });
    }
    setIsLoading(true);
    try {
      await api.patch('/user/disclaimer', { welcomeLeader: true });
      // redirect user to /userSpace route
      history.push('/userSpace');
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const youtubeVideoLink = 'https://www.youtube.com/embed/Jga3e5ZB32Q';
  const handleProgress = (state) => {
    const playedPercentage = state.played * 100;
    if (playedPercentage >= 70 && playedPercentage < 71) {
      console.log('A reprodução atingiu 70%');
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'video_assistido',
          video_title: 'Boas-vindas à Persora',
        });
        mixpanel.track('video_assistido', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
          video_title: 'Boas-vindas à Persora',
        });
      }
    }
  };

  useEffect(() => {
    if (!hasRevisitParam) {
      if (!currentUserContextData?.tasksGoals?.initialProfile) {
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Cadastro',
            action: 'cadastro_concluido',
          });
          mixpanel.track('cadastro_concluido', {
            category: 'Cadastro',
            company_id: currentUserContextData._idWorkspace[0],
          });
        }
      }
    }
  }, []);

  return (
    <WelcomeContainer>
      {/* <WelcomeHeader>
        <img src={logo} alt="Logo" width="140px" />
      </WelcomeHeader> */}
      <WelcomeBody>
        {/* full width and centered bootstrap container */}
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoHeight
          style={{ zIndex: 0 }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}
          className="swiper-goals-tracker"
          onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          navigation={{
            nextEl: '.next',
          }}
        >
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 170px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-center">
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <div className="px-2 text-center w-100">
                        <h3 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Boas-vindas!</h3>
                        <p className="mb-4">
                          Uma mensagem dos fundadores para você
                          <br />
                          (menos de 1min).
                        </p>
                        <div
                          className="mb-3"
                          style={{
                            width: '230px', height: '410px', margin: '0 auto', borderRadius: '16px', overflow: 'hidden',
                          }}
                        >
                          <ReactPlayer
                            url={youtubeVideoLink}
                            width="100%"
                            height="100%"
                            onProgress={handleProgress}
                            config={{
                              youtube: {
                                playerVars: {
                                  controls: 0, // Ocultar os controles padrões do YouTube
                                  showinfo: 0,
                                  modestbranding: 1,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    )
                    : (
                      <div className="px-2 text-center w-100">
                        <WelcomeImage src={welcomeColaborador1} alt="Welcome 1" className="mb-2" style={{ maxWidth: '300px' }} />
                        <h3 className="bold mb-3" style={{ color: defaultTheme.colors.primary.main }}>Boas-vindas!</h3>
                        {currentUserContextData.invitedBy
                          ? (
                            <p className="mb-2">
                              {currentUserContextData.invitedBy.split(' ')[0]}
                              {' '}
                              te convidou para apoiar no fortalecimento de
                              {' '}
                              uma liderança ativa para a evolução do time.
                            </p>
                          )
                          : (
                            <p className="mb-2">
                              Você foi convidado para apoiar no fortalecimento de
                              uma liderança ativa para a evolução do time.
                            </p>
                          )}
                        <p className="mb-4">
                          Veja a seguir as vantagens para você e seu time.
                        </p>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 170px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-center">
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <div className="px-3 text-center w-100">
                        <div className="w-100 text-end">
                          <PersoraTextButton
                            className="mt-2 mb-4 semibold"
                            onClick={() => {
                              setPersoraManifestSheetOpen(true);
                            }}
                            style={{ opacity: '.9' }}
                          >
                            Nosso manifesto
                            {' '}
                            <InfoCircle className="ml-1" />
                          </PersoraTextButton>
                        </div>
                        <WelcomeImage src={welcomeLider2} alt="Welcome 2" className="mb-4" />
                        <h5 className="bold mb-2">
                          Liderar é difícil
                        </h5>
                        <p className="mb-0">
                          Mas, você não está sozinho. Agora você tem
                          <br />
                          {' '}
                          a Persora, a copiloto do líder.
                        </p>
                      </div>
                    )
                    : (
                      <div className="px-4 text-center w-100">
                        <WelcomeImage src={welcomeColaborador2} alt="Welcome 2" className="mb-3" style={{ maxWidth: '300px' }} />
                        <h5 className="bold mb-2">
                          Com a ajuda da Persora, o time vai,
                          <br />
                          de forma ágil:
                        </h5>
                        <ListItensWithBlueBg className="my-4">
                          <div className="emoji-paragraph">
                            <span>❤️</span>
                            <p className="mb-4">
                              Ter um líder compreendendo melhor o time
                              {' '}
                              e atuando para melhorar continuamente.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>💬️</span>
                            <p className="mb-1">
                              Saber como se comunicar melhor com cada pessoa.
                            </p>
                          </div>
                        </ListItensWithBlueBg>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
          {currentUserContextData.type === 'Líder' && (
            <SwiperSlide>
              <Container>
                <Row style={{ height: 'calc(100dvh - 170px)', marginTop: 10 }}>
                  <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-center">
                    <div className="px-4 text-center w-100">
                      <h5 className="bold mb-2">Utilizando a Persora, você, líder, vai:</h5>
                      <ListItensWithBlueBg className="my-4">
                        <div className="emoji-paragraph">
                          <span>💬</span>
                          <p className="mb-4">Saber como se comunicar e aproveitar o melhor de cada pessoa do time.</p>
                        </div>
                        <div className="emoji-paragraph">
                          <span>🚀</span>
                          <p className="mb-4">
                            Entender como o time está e saber o que fazer
                            {' '}
                            para melhorar continuamente.
                          </p>
                        </div>
                        <div className="emoji-paragraph">
                          <span>🎯</span>
                          <p className="mb-2">Manter todos alinhados com o propósito e as regras do jogo.</p>
                        </div>
                      </ListItensWithBlueBg>
                      <p className="mb-0">
                        Tudo isso de forma ágil e com a ajuda
                        {' '}
                        <br />
                        da Sora, a sua copiloto.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          )}
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 170px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-center">
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <div className="px-4 text-center w-100">
                        <WelcomeImage src={welcomeLider4} alt="Welcome 4" className="mb-4" />
                        <h5 className="bold mb-2">Só mais uma coisa!</h5>
                        <p className="mb-2">
                          Os líderes que ativam as notificações têm 3x
                          {' '}
                          mais chances de atingir seu objetivo.
                        </p>
                        <p className="mb-0">Não enviaremos propaganda.</p>
                      </div>
                    )
                    : (
                      <div className="px-4 text-center w-100">
                        <WelcomeImage src={welcomeLider4} alt="Welcome 4" className="mb-4" />
                        <h5 className="bold mb-2">Só mais uma coisa!</h5>
                        <p className="mb-1">
                          Ativando as notificações você recebe as comunicações
                          {' '}
                          essenciais da Persora e apoia o seu líder no dia a dia.
                        </p>
                        <p>Não enviaremos propaganda.</p>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      </WelcomeBody>
      {currentUserContextData.type === 'Líder'
        ? (
          <WelcomeFooter>
            <PaginationDiv>
              <div>
                <div className="swiper-pagination" />
              </div>
            </PaginationDiv>
            <ButtonsDiv>
              {currentSlide === 3 && (
              <PersoraButton
                className="gtm-boasVindas-concluir mt-0"
                onClick={handleWelcomeDone}
                disabled={isLoading}
                value="concluir"
              >
                {isLoading ? <Clock cor="#FFF" /> : 'Entendi, farei isso!'}
              </PersoraButton>
              )}
              <Left style={{ display: 'flex', flexDirection: 'column' }}>
                {currentSlide <= 2 && (
                <PersoraButton
                  tamanho="mini"
                  estilo="ghost"
                  style={{ color: defaultTheme.colors.black40 }}
                  onClick={handleWelcomeDone}
                  disabled={isLoading}
                  className="gtm-boasVindas-pular px-2"
                  value="pular"
                >
                  {isLoading ? 'Aguarde...' : 'Pular'}
                </PersoraButton>
                )}
              </Left>
              <div>
                {/* add class name d-none to button .next if slide < 2 */}
                <PersoraButton className={`next ${currentSlide > 2 && 'd-none'}`} style={{ borderRadius: 50 }} justIcon>
                  <ArrowRight cor="#FFF" />
                </PersoraButton>
                <PersoraButton
                  className={`next ${currentSlide !== 3 ? 'd-none' : null} d-none`}
                  onClick={handleWelcomeDone}
                  style={{ borderRadius: 50 }}
                  justIcon
                  disabled={isLoading}
                >
                  {isLoading ? <Clock cor="#FFF" /> : <ArrowRight cor="#FFF" />}
                </PersoraButton>
              </div>
            </ButtonsDiv>
          </WelcomeFooter>
        )
        : (
          <WelcomeFooter>
            <PaginationDiv>
              <div>
                <div className="swiper-pagination" />
              </div>
            </PaginationDiv>
            <ButtonsDiv>
              {currentSlide === 2 && (
              <PersoraButton
                className="gtm-boasVindas-concluir mt-0"
                onClick={handleWelcomeDone}
                disabled={isLoading}
                value="concluir"
              >
                {isLoading ? <Clock cor="#FFF" /> : 'Entendi, farei isso!'}
              </PersoraButton>
              )}
              <Left style={{ display: 'flex', flexDirection: 'column' }}>
                {currentSlide <= 1 && (
                <PersoraButton
                  tamanho="mini"
                  estilo="ghost"
                  style={{ color: defaultTheme.colors.black40 }}
                  onClick={handleWelcomeDone}
                  disabled={isLoading}
                  className="gtm-boasVindas-pular px-2"
                  value="pular"
                >
                  {isLoading ? 'Aguarde...' : 'Pular'}
                </PersoraButton>
                )}
              </Left>
              <div>
                {/* add class name d-none to button .next if slide < 2 */}
                <PersoraButton className={`next ${currentSlide > 1 && 'd-none'}`} style={{ borderRadius: 50 }} justIcon>
                  <ArrowRight cor="#FFF" />
                </PersoraButton>
                <PersoraButton
                  className={`next ${currentSlide !== 2 ? 'd-none' : null} d-none`}
                  onClick={handleWelcomeDone}
                  style={{ borderRadius: 50 }}
                  justIcon
                  disabled={isLoading}
                >
                  {isLoading ? <Clock cor="#FFF" /> : <ArrowRight cor="#FFF" />}
                </PersoraButton>
              </div>
            </ButtonsDiv>
          </WelcomeFooter>
        )}

      <Sheet
        isOpen={persoraManifestSheetIsOpen}
        onClose={() => setPersoraManifestSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="full-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!persoraManifestSheetIsOpen}
      >
        <PersoraManifest />
        <Sheet.Backdrop onTap={() => setPersoraManifestSheetOpen(false)} />
      </Sheet>
    </WelcomeContainer>
  );
}
Welcome.propTypes = {
  currentUser: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    owner: PropTypes.bool,
  }),
};
Welcome.defaultProps = {
  currentUser: {},
};
