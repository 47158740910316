import PropTypes from 'prop-types';

// import {
//   Container,
// } from './styles';

// import theme from '../../assets/styles/themes/default';
import { Sheet } from 'react-modal-sheet';
import { useState } from 'react';
import ProfileWaves from '../ProfileWaves';
import Disclaimer from '../Disclaimer';
import { discWords, replaceVoce } from '../../utils/profileUtils';
import { formatDateDDMMYYYY } from '../../utils/dateUtils';
import profileContents from '../../utils/profileContents';
import UserProfileCharsCards from './CharsCards';
import UserProfileComunicationCards from './ComunicationCards';
import UserProfileStrengthsChallengesCards from './StrengthsChallengesCards';
import { Brain, QuestionCircle } from '../DuotoneIcon';
import PersoraButton from '../Button';
import LearnMoreOsEstilosSheet from '../../pages/Sheets/LearnMoreOsEstilosSheet';
// import defaultTheme from '../../assets/styles/themes/default';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import defaultTheme from '../../assets/styles/themes/default';

function UserProfileResult({
  userName, userPronouns, userProfile, aboutAnotherUser,
}) {
  const lastProfile = userProfile;
  const firstName = userName.split(' ')[0];
  const userDiscWords = discWords(lastProfile.displayedProfileLetters);
  const resultDate = formatDateDDMMYYYY(lastProfile.createdAt);
  const resumo = profileContents[lastProfile.profileLetters].resumo[userPronouns];
  const caracteristicas = profileContents[lastProfile.profileLetters].caracteristicas.neutral;
  const perfisOpostos = ['DS', 'IC'];
  const perfisRaros = ['ISC', 'DIC', 'DIS', 'DSC'];
  const [learnMoreOsEstilosSheetIsOpen, setLearnMoreOsEstilosSheetOpen] = useState(false);

  let textoResumo = 'Ou seja, você possui características dos quatro grupos, mas, ao saber o que é predominante, é possível obter um melhor aproveitamento do seu potencial.';

  if (aboutAnotherUser) {
    textoResumo = replaceVoce({ text: textoResumo, name: firstName });
  }

  function isPerfilRaro(profileLetters) {
    if (perfisRaros.includes(profileLetters)) {
      return (
        <Disclaimer
          estilo="generic"
          titulo="Estilos equalizados"
          icone="🔷"
          className="mb-5 mt-4"
        >
          {aboutAnotherUser
            ? (
              <>
                O resultado mostrou que
                {' '}
                {firstName}
                {' '}
                se identifica com várias características
                {' '}
                de diferentes estilos e isso não é tão comum. Esse tipo de resultado faz
                {' '}
                com que estilo de
                {' '}
                {firstName}
                {' '}
                seja menos específico, pois seu jeito de agir é mais flexível.
              </>
            )
            : (
              <>
                Seu resultado mostrou que você se identifica com várias características
                {' '}
                de diferentes estilos e isso não é tão comum. Esse tipo de resultado faz
                {' '}
                com que seu estilo seja menos específico, pois seu jeito de agir é mais flexível.
              </>
            )}
        </Disclaimer>
      );
    }
    return null;
  }
  function isPerfilOposto(profileLetters) {
    console.log(profileLetters);
    if (perfisOpostos.includes(profileLetters)) {
      return (
        <Disclaimer
          estilo="generic"
          titulo="Estilos opostos"
          icone="🔷"
          className="mb-5 mt-4"
        >
          {aboutAnotherUser
            ? (
              <>
                O resultado mostrou que
                {' '}
                {firstName}
                {' '}
                se identifica com características
                {' '}
                de estilos opostos e isso não é tão comum. Em outras palavras, significa
                {' '}
                que
                {' '}
                {firstName}
                {' '}
                pode alternar entre comportamentos opostos e isso tende a ser desafiador.
              </>
            )
            : (
              <>
                Seu resultado mostrou que você se identifica com características
                {' '}
                de estilos opostos e isso não é tão comum. Em outras palavras, significa
                {' '}
                que você pode alternar entre comportamentos opostos e isso tende a ser desafiador.
              </>
            )}
        </Disclaimer>
      );
    }
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      {aboutAnotherUser
        ? (
          <h2 className="mb-4 dmsans medium">
            O estilo atual de
            {' '}
            {firstName}
            {' '}
            é
            {' '}
            <span
              dangerouslySetInnerHTML={{ __html: userDiscWords.persoraWordsAnd.toLowerCase() }}
            />
            .
          </h2>
        )
        : (
          <h2 className="mb-4 dmsans medium">
            {firstName}
            , o seu estilo atual é
            {' '}
            <span
              dangerouslySetInnerHTML={{ __html: userDiscWords.persoraWordsAnd.toLowerCase() }}
            />
            .
          </h2>
        )}
      {(!aboutAnotherUser || aboutAnotherUser)
      && (
        <PersoraButton
          estilo="ghost"
          justIcon
          style={{
            width: 'fit-content', position: 'absolute', right: '0', marginTop: '-10px',
          }}
          className="p-2"
          onClick={() => {
            setLearnMoreOsEstilosSheetOpen(true);
          }}
        >
          <QuestionCircle />
        </PersoraButton>
      )}
      <div style={{ pointerEvents: 'none' }}>
        <ProfileWaves perfil={lastProfile.displayedProfileLetters} />
      </div>
      <p className="small mt-2">
        Resultado atualizado em:
        {' '}
        {resultDate}
      </p>
      <p className="mt-4">
        {textoResumo}
      </p>
      {aboutAnotherUser
        ? (
          <div dangerouslySetInnerHTML={{
            __html: replaceVoce({ text: resumo, name: firstName }),
          }}
          />
        )
        : <div dangerouslySetInnerHTML={{ __html: resumo }} />}
      {isPerfilRaro(lastProfile.profileLetters)}
      {isPerfilOposto(lastProfile.profileLetters)}
      <UserProfileCharsCards lastProfile={lastProfile} chars={caracteristicas} className="mt-4" firstName={firstName} aboutAnotherUser={aboutAnotherUser} />
      {/* {!aboutAnotherUser &&
        <UserProfileComunicationCards lastProfile={lastProfile} className="mt-4" />} */}
      <UserProfileComunicationCards lastProfile={lastProfile} className="mt-4" />
      <UserProfileStrengthsChallengesCards lastProfile={lastProfile} userPronouns={userPronouns} className="pt-3 mt-4 pb-1" firstName={firstName} aboutAnotherUser={aboutAnotherUser} />
      <Disclaimer
        estilo="reference"
        titulo="Referência"
        icone={<Brain cor={defaultTheme.colors.primary.main} />}
        className="mt-2 mb-4"
      >
        <p className="mb-1">
          Fundamentado em instrumentos já validados,
          {' '}
          como
          {' '}
          <b>DISC</b>
          {' '}
          e
          {' '}
          <b>16personalities</b>
          , desenvolvemos um algoritmo
          {' '}
          próprio de Estilos Comportamentais da Persora.
        </p>
      </Disclaimer>
      <Sheet
        isOpen={learnMoreOsEstilosSheetIsOpen}
        onClose={() => setLearnMoreOsEstilosSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!learnMoreOsEstilosSheetIsOpen}
      >
        <LearnMoreOsEstilosSheet />
        <Sheet.Backdrop onTap={() => setLearnMoreOsEstilosSheetOpen(false)} />
      </Sheet>
    </div>
  );
}
UserProfileResult.propTypes = {
  userName: PropTypes.string.isRequired,
  userPronouns: PropTypes.string.isRequired,
  userProfile: PropTypes.shape({
    profileLetters: PropTypes.string,
  }).isRequired,
  aboutAnotherUser: PropTypes.bool,
};
UserProfileResult.defaultProps = {
  aboutAnotherUser: false,
};

export default UserProfileResult;
