import {
  useEffect, useRef, useState, useContext,
} from 'react';
import { Flip, toast } from 'react-toastify';

import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';

import {
  Col, Container, Row,
} from 'react-bootstrap';

import { Sheet } from 'react-modal-sheet';
// import SoraAvatar from '../../assets/img/sora-avatar.svg';
// import SoraPrazer from '../../assets/img/sora-prazer.svg';
import SoraDescontraida from '../../assets/img/SoraV2/Rosto/Descontraida.svg';
import SoraPiscando from '../../assets/img/SoraV2/Rosto/Piscando.svg';
import SoraReflexao from '../../assets/img/SoraV2/Rosto/Reflexao.svg';
import SoraBustoExtase from '../../assets/img/SoraV2/BustoCortado/Extase.svg';

import api from '../../services/backend';
import PersoraButton from '../Button';
import discWords from '../../utils/discWords';
import LearnMoreOsEstilosSheet from '../../pages/Sheets/LearnMoreOsEstilosSheet';
import FirstMapRate from '../../pages/Sheets/FirstMapRate';
import LearnMoreMapearPerfil from '../../pages/Sheets/LearnMoreMapearPerfil';
import UserProfileResult from '../UserProfileResult';
import { UserContext } from '../../Context/UserContext';

import {
  DiscWordsContainer,
  FormGroup,
  HeaderProgressBar,
  OnboardingBody,
  OnboardingBodyFullHeight,
  OnboardingContainer,
  OnboardingFooter,
  OnboardingHeader,
} from '../FirstMap/styles';
import defaultTheme from '../../assets/styles/themes/default';
import {
  Brain,
  ChevronLeft,
  Clock, QuestionCircle,
} from '../DuotoneIcon';
import MapeamentoLoader from '../MapeamentoLoader';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { Foto, SoraSpeech, SpeechBubble } from '../../pages/Sheets/SoraCopilotV2';
import Disclaimer from '../Disclaimer';
// import { removeSubstring } from '../../utils/formatters';
// import { validateEmail } from '../../utils/validators';

// const discWordsList randomized
const discWordsList = Object.keys(discWords).sort(() => Math.random() - 0.5);

export default function FirstMapSecondLeader({ user }) {
  // const history = useHistory();
  const { currentUserContextData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [learnMoreOsEstilosSheetIsOpen, setLearnMoreOsEstilosSheetOpen] = useState(false);
  const [learnMoreMapearPerfilSheetIsOpen, setLearnMoreMapearPerfilSheetOpen] = useState(false);
  const [firstMapRateSheetIsOpen, setFirstMapRateSheetOpen] = useState(false);
  const [userName, setUserName] = useState(user.name ? user.name : '');
  const [validUserName, setValidUserName] = useState(!!user.name);
  const [userPronouns, setUserPronouns] = useState(currentUserContextData.settings.pronouns ? currentUserContextData.settings.pronouns : '');
  // Quando você assumiu sua primeira liderança? ====================================>
  const [firstTimeLeader, setFirstTimeLeader] = useState(user.settings.firstTimeLeader ? user.settings.firstTimeLeader : '');
  const firstTimeLeaderOptions = [
    { label: '< 1990', value: '<1990' },
    { label: '1990-2000', value: '1990-2000' },
    { label: '2001-2010', value: '2001-2010' },
    { label: '2011-2020', value: '2011-2020' },
    { label: '2021-2022', value: '2021-2022' },
    { label: '2023-2024', value: '2023-2024' },
  ];
  // Quantas pessoas você lidera diretamente? ==============================================>
  const [ledNumber, setLedNumber] = useState(user.settings.ledNumber ? user.settings.ledNumber : ''); // user
  const ledNumberOptions = [
    { label: '< 3', value: '<3' },
    { label: '3-4', value: '3-4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8-12', value: '8-12' },
    { label: '13-16', value: '13-16' },
    { label: '17-20', value: '17-20' },
    { label: '> 20', value: '>20' },
  ];

  const [selectedDiscWords, setSelectedDiscWords] = useState([]);
  const [showLastNameReminder, setShowLastNameReminder] = useState(false);
  // const [selectedFitCultural, setSelectedFitCultural] = useState([]);
  const [mapId, setMapId] = useState(
    user.profiles[user.profiles.length - 1]
      ? user.profiles[user.profiles.length - 1]._id
      : null,
  );
  const [firstMapResult, setFirstMapResult] = useState(
    mapId === null
      ? null
      : user.profiles[user.profiles.length - 1],
  );
  const [currentStep, setCurrentStep] = useState(mapId === null ? 1 : 11); // 1, 2 ou 3

  // handleDiscWordClicked console.log definition of word
  const handleDiscWordClicked = (event) => {
    if (event.target.classList.contains('selected')) {
      toast.dismiss();
    }
    // toogle class "selected"
    event.target.classList.toggle('selected');
    // se o elemento clicado tiver a classe "selected", console.log "imprimir definição"
    if (event.target.classList.contains('selected')) {
      event.preventDefault();
      toast.dismiss();
      // show toast after 400ms
      setTimeout(() => {
        toast(discWords[event.target.innerText].definicao, {
          type: 'info',
          autoClose: 5000,
          position: 'top-center',
          draggablePercent: 20,
          // transition: Flip,
          icon: '👉',
        });
      }, 500);
    }
    // update selectedDiscWords with words with class "selected"
    const selectedWords = document.querySelectorAll('.discWord.selected');
    const selectedWordsArray = [];
    selectedWords.forEach((word) => {
      selectedWordsArray.push(word.innerText);
    });
    setSelectedDiscWords(selectedWordsArray);
  };

  const handleUserName = (event) => {
    const trimmedValue = event.target.value.trim(); // Remove espaços em branco no início e no final
    setUserName(trimmedValue);
    if (trimmedValue.split(' ').length >= 2 && trimmedValue !== '') {
      setValidUserName(true);
    } else {
      setValidUserName(false);
    }
    if (event.target.value.length >= 3 && !validUserName) {
      setShowLastNameReminder(true);
    } else {
      setShowLastNameReminder(false);
    }
  };

  const componenteRef = useRef();
  const timeoutRef = useRef();
  useEffect(() => {
    if (currentStep === 11) {
      const handleTimeout = () => {
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Onboarding',
            action: 'mapeamento_lido',
          });
          mixpanel.track('mapeamento_lido', {
            category: 'Onboarding',
            company_id: currentUserContextData._idWorkspace[0],
          });
        }
      };
      timeoutRef.current = setTimeout(handleTimeout, 45000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [currentStep]);

  const handlePrevStep = async () => {
    toast.dismiss();
    if (currentStep === 2) {
      setCurrentStep(1);
    }
    if (currentStep === 3) {
      setCurrentStep(2);
    }
    if (currentStep === 4) {
      setCurrentStep(3);
    }
    if (currentStep === 5) {
      setCurrentStep(4);
    }
    if (currentStep === 6) {
      setCurrentStep(5);
    }
    if (currentStep === 7) { // ?
      setCurrentStep(6);
    }
    if (currentStep === 8) {
      setCurrentStep(7);
    }
    if (currentStep === 9) {
      setCurrentStep(8);
    }
    window.scrollTo(0, 0);
  };

  const handleNextStep = async () => {
    toast.dismiss();
    if (currentStep === 1) {
      setIsLoading(true);
      try {
        const body = {
          name: userName,
        };
        await api.patch('/user/updateSettings', body);
        setCurrentStep(2);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    if (currentStep === 2) {
      setIsLoading(true);
      try {
        const body = {
          pronouns: userPronouns,
        };
        await api.patch('/user/updateSettings', body);
        setCurrentStep(3);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    if (currentStep === 3) {
      setIsLoading(true);
      try {
        const body = {
          firstTimeLeader,
        };
        await api.patch('/user/updateSettings', body);
        setCurrentStep(4);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    if (currentStep === 4) {
      setIsLoading(true);
      try {
        const body = {
          ledNumber,
        };
        await api.patch('/user/updateSettings', body);
        setCurrentStep(5);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    if (currentStep === 5) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentStep(6);
        setIsLoading(false);
      }, 600);
    }
    if (currentStep === 6) {
      console.log('onboarding done ?');
      setIsLoading(true);
      if (selectedDiscWords.length === 0) {
        toast.dismiss();
        toast.error('Você não selecionou nenhuma palavra. 😕 ', {
          autoClose: 5000,
          position: 'top-center',
          draggablePercent: 20,
          transition: Flip,
          icon: false,
        });
        setIsLoading(false);
        return;
      }
      if (selectedDiscWords.length === 32) {
        toast.dismiss();
        toast.error('Selecione menos palavras. 😕 ', {
          autoClose: 5000,
          position: 'top-center',
          draggablePercent: 20,
          transition: Flip,
          icon: false,
        });
        setIsLoading(false);
        return;
      }
      if (selectedDiscWords.length > 0 && selectedDiscWords.length < 8) {
        toast.dismiss();
        toast.error('Selecione pelo menos 8 palavras. 😉 ', {
          autoClose: 5000,
          position: 'top-center',
          draggablePercent: 20,
          transition: Flip,
          icon: false,
        });
        setIsLoading(false);
        return;
      }
      // setIsLoading(true);
      // setTimeout(() => {
      //   setCurrentStep(5);
      //   setIsLoading(false);
      //   setTimeout(() => {
      //     setCurrentStep(6);
      //   }, 2400);
      // }, 600);
      try {
        // Primeira requisição para /profile
        const responseProfile = await api.post('/profile', {
          // fitCultural: ['fulano', 'beltrano'],
          adjectives: selectedDiscWords,
        });
          // Verifica se a resposta da primeira requisição foi bem-sucedida
        if (responseProfile.status === 201) {
          // Segunda requisição para /user/onboarding apenas se a primeira for bem-sucedida
          // await api.patch('/user/disclaimer', { firstMap: true });
          // Redirect user to /userSpace route
          // history.push('/userSpace');
          setFirstMapResult(responseProfile.data);
          setMapId(responseProfile.data._id);
          setIsLoading(false);
          setCurrentStep(7);
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
            ReactGA.event({
              category: 'Onboarding',
              action: 'mapeamento_concluido',
            });
            mixpanel.track('mapeamento_concluido', {
              category: 'Onboarding',
              company_id: currentUserContextData._idWorkspace[0],
            });
          }
          setTimeout(() => {
            setCurrentStep(8);
          }, 2800);
        } else {
          console.log('Erro na requisição para /profile');
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
    window.scrollTo(0, 0);
  };

  if (currentStep === 1) {
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px', opacity: (currentStep === 1 ? 0.5 : 1) }}
            disabled={currentStep === 1}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="11%" />
        </OnboardingHeader>
        <OnboardingBody style={{ height: 'calc(100dvh - 84px)' }}>
          <Container className="d-flex flex-column justify-content-between h-100">
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div>
                  <h2 className="sourcesans mb-2">
                    Olá, sou a Sora!
                  </h2>
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <>
                        <h1 className="sourcesans mb-2" style={{ color: defaultTheme.colors.textDefault }}>Serei sua copiloto para potencializar sua liderança, tornando-a menos desafiadora para você.</h1>
                        <p className="mb-4">
                          Quero entender o seu contexto. São perguntas
                          {' '}
                          objetivas e leva menos de 1 minuto. 🕑
                        </p>
                      </>
                    )
                    : (
                      <h1 className="sourcesans mb-4" style={{ color: defaultTheme.colors.textDefault }}>Serei a copiloto do time para ajudar o indivíduo, o time e a liderança.</h1>
                    )}
                  <SoraSpeech className="mt-0">
                    <Foto src={SoraDescontraida} alt="Sora - Copiloto" />
                    <SpeechBubble>
                      Como posso te chamar?
                    </SpeechBubble>
                  </SoraSpeech>
                  <FormGroup>
                    <div htmlFor="signup-fullname">Seu nome e sobrenome</div>
                    <input
                      type="text"
                      className="form-control"
                      id="signup-fullname"
                      name="fullname"
                      onChange={handleUserName}
                      defaultValue={userName}
                      required
                    />
                    {showLastNameReminder
                    && <small className="mt-2" style={{ color: defaultTheme.colors.alerts.warning.titleColor }}>Não esqueça de informar o seu sobrenome! 😉</small>}
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="px-0 pt-4">
                <PersoraButton
                  tamanho="default"
                  disabled={isLoading || !validUserName}
                  onClick={handleNextStep}
                  className="align-self-end"
                >
                  {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Continuar'}
                </PersoraButton>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
      </OnboardingContainer>
    );
  }
  if (currentStep === 2) {
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px' }}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="22%" />
        </OnboardingHeader>
        <OnboardingBody>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div className="w-100">
                  <SoraSpeech className="mt-0">
                    <Foto src={SoraPiscando} alt="Sora - Copiloto" />
                    <SpeechBubble>
                      Qual é a melhor forma de me dirigir a você?
                    </SpeechBubble>
                  </SoraSpeech>
                  <PersoraButton estilo="outline" tamanho="condensed" className={`w-100 mb-3 d-block text-start ${userPronouns === 'female' && 'selected'} ${userPronouns === 'male' && 'not-selected'}`} onClick={() => setUserPronouns('female')}>
                    <span className="me-2">🌟</span>
                    {' '}
                    {(userName.split(' ')[0])}
                    {' '}
                    é
                    {' '}
                    <b className="bold" style={{ color: defaultTheme.colors.secondary.dark }}><u>uma</u></b>
                    {' '}
                    {currentUserContextData.type === 'Líder' ? 'líder human' : 'profissional human'}
                    <b className="bold" style={{ color: defaultTheme.colors.secondary.dark }}><u>a</u></b>
                    .
                  </PersoraButton>
                  <PersoraButton estilo="outline" tamanho="condensed" className={`w-100 mb-3 d-block text-start ${userPronouns === 'male' && 'selected'} ${userPronouns === 'female' && 'not-selected'}`} onClick={() => setUserPronouns('male')}>
                    <span className="me-2">⭐️</span>
                    {' '}
                    {(userName.split(' ')[0])}
                    {' '}
                    é
                    {' '}
                    <b className="bold" style={{ color: defaultTheme.colors.secondary.dark }}><u>um</u></b>
                    {' '}
                    {currentUserContextData.type === 'Líder' ? 'líder human' : 'profissional human'}
                    <b className="bold" style={{ color: defaultTheme.colors.secondary.dark }}><u>o</u></b>
                    .
                  </PersoraButton>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <OnboardingFooter>
          <PersoraButton
            tamanho="default"
            disabled={isLoading || !userPronouns}
            onClick={handleNextStep}
          >
            {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Continuar'}
          </PersoraButton>
        </OnboardingFooter>
      </OnboardingContainer>
    );
  }

  if (currentStep === 3) {
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px' }}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="33%" />
        </OnboardingHeader>
        <OnboardingBody>
          <Container className="h-100">
            <Row className="h-100">
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div
                  className="w-100"
                  style={{
                    display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column',
                  }}
                >
                  <div>
                    <SoraSpeech className="mt-0">
                      <Foto src={SoraReflexao} alt="Sora - Copiloto" />
                      <SpeechBubble>
                        Quando você assumiu sua primeira liderança?
                      </SpeechBubble>
                    </SoraSpeech>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', gap: '12px', width: '100%', justifyContent: 'center',
                    }}
                    >
                      {firstTimeLeaderOptions.map((option) => (
                        <PersoraButton
                          estilo="outline"
                          tamanho="condensed"
                          className={`px-3 text-start ${firstTimeLeader === option.value && 'selected'} ${firstTimeLeader !== '' && firstTimeLeader !== option.value && 'not-selected'}`}
                          onClick={() => setFirstTimeLeader(option.value)}
                          key={option.value}
                        >
                          {option.label}
                        </PersoraButton>
                      ))}
                    </div>
                  </div>
                  <p className="mt-4 mb-3">Essas informações ajudam no desenvolvimento da Persora.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <OnboardingFooter>
          <PersoraButton
            tamanho="default"
            disabled={isLoading || !firstTimeLeader}
            onClick={handleNextStep}
          >
            {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Continuar'}
          </PersoraButton>
        </OnboardingFooter>
      </OnboardingContainer>
    );
  }

  // TODO SAIU STEP 4 Qual é o segmento da empresa em que você trabalha hoje?

  // TODO SAIU STEP 5 Você lidera um time em qual área?

  if (currentStep === 4) { // era 6
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px' }}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="66%" />
        </OnboardingHeader>
        <OnboardingBody>
          <Container className="h-100">
            <Row className="h-100">
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div
                  className="w-100"
                  style={{
                    display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column',
                  }}
                >
                  <div>
                    <SoraSpeech className="mt-0">
                      <Foto src={SoraDescontraida} alt="Sora - Copiloto" />
                      <SpeechBubble>
                        Quantas pessoas você lidera diretamente?
                      </SpeechBubble>
                    </SoraSpeech>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', gap: '12px', width: '100%', justifyContent: 'center',
                    }}
                    >
                      {ledNumberOptions.map((option) => (
                        <PersoraButton
                          estilo="outline"
                          tamanho="condensed"
                          className={`px-3 text-start ${ledNumber === option.value && 'selected'} ${ledNumber !== '' && ledNumber !== option.value && 'not-selected'}`}
                          onClick={() => setLedNumber(option.value)}
                          key={option.value}
                        >
                          {option.label}
                        </PersoraButton>
                      ))}
                    </div>
                  </div>
                  <p className="mt-4 mb-3">Essas informações ajudam no desenvolvimento da Persora.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <OnboardingFooter>
          <PersoraButton
            tamanho="default"
            disabled={isLoading || !ledNumber}
            onClick={handleNextStep}
          >
            {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Continuar'}
          </PersoraButton>
        </OnboardingFooter>
      </OnboardingContainer>
    );
  }

  // TODO SAIU STEP 7 Como você conheceu a Persora?

  if (currentStep === 5) { // era 3 depois era 8
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px' }}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="88%" />
        </OnboardingHeader>
        <OnboardingBody>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div className="text-center">
                  <h1 className="sourcesans mb-2" style={{ color: defaultTheme.colors.textDefault, display: 'flex', alignItems: 'center' }}>
                    Conhecendo o seu estilo
                    <PersoraButton
                      estilo="ghost"
                      justIcon
                      style={{ display: 'inline-flex', width: 'fit-content' }}
                      className="p-2"
                      onClick={() => {
                        setLearnMoreOsEstilosSheetOpen(true);
                      }}
                    >
                      <QuestionCircle />
                    </PersoraButton>
                  </h1>
                  <img src={SoraBustoExtase} alt="Sora comemora sua chegada!" height="160px" className="mt-1 mb-1" />
                  <h1 className="sourcesans pt-2 mb-2 text-start">
                    Chegou a parte mais legal!
                  </h1>
                  <p className="text-start mb-2">
                    {currentUserContextData.type === 'Líder'
                      ? 'Conhecer seu estilo é o primeiro passo para eu te ajudar a se comunicar com as pessoas do seu time.'
                      : 'Conhecer seu estilo é o primeiro passo para eu te ajudar a se comunicar com as pessoas do seu time.'}
                  </p>
                  <Disclaimer
                    estilo="reference"
                    titulo="Referência"
                    icone={<Brain cor={defaultTheme.colors.primary.main} />}
                    className="mt-3 text-start"
                  >
                    <p className="mb-1">
                      Fundamentado em instrumentos já validados, como
                      {' '}
                      <b>DISC</b>
                      {' '}
                      e
                      {' '}
                      <b>16personalities</b>
                      ,
                      {' '}
                      desenvolvemos um algoritmo próprio de Estilos Comportamentais da Persora.
                    </p>
                  </Disclaimer>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <OnboardingFooter>
          <PersoraButton
            tamanho="default"
            disabled={isLoading}
            onClick={handleNextStep}
          >
            {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Vamos lá!'}
          </PersoraButton>
        </OnboardingFooter>
        <Sheet
          isOpen={learnMoreOsEstilosSheetIsOpen}
          onClose={() => setLearnMoreOsEstilosSheetOpen(false)}
        // detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableDrag={!learnMoreOsEstilosSheetIsOpen}
        >
          <LearnMoreOsEstilosSheet />
          <Sheet.Backdrop onTap={() => setLearnMoreOsEstilosSheetOpen(false)} />
        </Sheet>
      </OnboardingContainer>
    );
  }
  if (currentStep === 6) { // era 4 depois era 9
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px' }}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="100%" />
        </OnboardingHeader>
        <OnboardingBody style={{ overflowY: 'scroll', height: 'calc(100dvh - 90px)' }}>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div className="w-100">
                  <h1 className="sourcesans mb-2" style={{ color: defaultTheme.colors.textDefault, display: 'flex', alignItems: 'center' }}>
                    Identificando seu estilo
                    <PersoraButton
                      estilo="ghost"
                      justIcon
                      style={{ display: 'inline-flex', width: 'fit-content' }}
                      className="p-2"
                      onClick={() => {
                        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                          ReactGA.event({
                            category: 'Mapeamento',
                            action: 'abrir_como_fazer',
                          });
                          mixpanel.track('abrir_como_fazer', {
                            category: 'Mapeamento',
                            company_id: currentUserContextData._idWorkspace[0],
                          });
                        }
                        setLearnMoreMapearPerfilSheetOpen(true);
                      }}
                    >
                      <QuestionCircle />
                    </PersoraButton>
                  </h1>
                  <p className="lead">
                    Na sua percepção sobre você mesmo, quais das
                    {' '}
                    palavras abaixo realmente te representam?
                  </p>
                  <DiscWordsContainer>
                    {discWordsList.map((word) => (
                      <PersoraButton
                        tamanho="mini"
                        estilo="outline"
                        className="mb-3 me-2 discWord"
                        key={word}
                        onClick={handleDiscWordClicked}
                      >
                        {word}
                      </PersoraButton>
                    ))}
                  </DiscWordsContainer>
                  <PersoraButton
                    tamanho="default"
                    onClick={handleNextStep}
                    disabled={isLoading}
                    className=""
                  >
                    {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar'}
                  </PersoraButton>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <Sheet
          isOpen={learnMoreMapearPerfilSheetIsOpen}
          onClose={() => setLearnMoreMapearPerfilSheetOpen(false)}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableDrag={!learnMoreMapearPerfilSheetIsOpen}
        >
          <LearnMoreMapearPerfil />
          <Sheet.Backdrop onTap={() => setLearnMoreMapearPerfilSheetOpen(false)} />
        </Sheet>
      </OnboardingContainer>
    );
  }
  if (currentStep === 7) { // era 5 depois era 10
    return (
      <OnboardingContainer>
        <OnboardingBody>
          <Container>
            <MapeamentoLoader text="Você conhecerá o seu estilo em poucos segundos…" />
          </Container>
        </OnboardingBody>
      </OnboardingContainer>
    );
  }
  if (currentStep === 8) { // era 6 depois era 11
    return (
      <OnboardingContainer ref={componenteRef}>
        <OnboardingBodyFullHeight>
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-0 px-0">
                <div className="w-100">
                  <UserProfileResult
                    userName={userName}
                    userPronouns={userPronouns}
                    userProfile={firstMapResult}
                  />
                  <PersoraButton tamanho="default" onClick={() => setFirstMapRateSheetOpen(true)} disabled={isLoading} className="mt-4 mb-5">
                    Continuar
                  </PersoraButton>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBodyFullHeight>
        <Sheet
          isOpen={firstMapRateSheetIsOpen}
          onClose={() => setFirstMapRateSheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableDrag={!firstMapRateSheetIsOpen}
        >
          <FirstMapRate mapId={mapId} />
          <Sheet.Backdrop onTap={() => setFirstMapRateSheetOpen(false)} />
        </Sheet>
      </OnboardingContainer>
    );
  }
}

FirstMapSecondLeader.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    owner: PropTypes.bool,
    firstGoal: PropTypes.string,
    name: PropTypes.string,
    workspaceContext: PropTypes.shape({
      segment: PropTypes.string,
      teamDepartment: PropTypes.string,
    }),
    settings: PropTypes.shape({
      pronouns: PropTypes.string,
      firstTimeLeader: PropTypes.string,
      teamDepartment: PropTypes.string,
      ledNumber: PropTypes.string,
      howMetPersora: PropTypes.string,
    }),
    profiles: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
    })),
  }).isRequired,
};
