/* eslint-disable react/prop-types */

import {
  useState, useEffect, useContext,
} from 'react';

import { scroller } from 'react-scroll';

// import {
//   Container, Row, Col,
// } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Sheet } from 'react-modal-sheet';
import { Modal } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { driver } from 'driver.js';
import Header from '../../components/Header';
import api from '../../services/backend';
import { Context } from '../../Context/AuthContext';
import { UserContext } from '../../Context/UserContext';
import PersoraLoader from '../../components/PersoraLoader';
import Welcome from '../../components/Welcome';
// eslint-disable-next-line
import FirstMap from '../../components/FirstMap';
// eslint-disable-next-line
// import Onboarding from '../../components/Onboarding';
import { MainContainer, PageFlexContainer } from './styles';
import TapBar from '../../components/TapBar';
import Main from '../../components/Main';
import TeamSettingsSheet from '../Sheets/TeamSettingsSheet';
import AddUserSheet from '../Sheets/AddUserSheet';
// import GoalsTracker from '../../components/GoalsTracker';
import PersoraButton from '../../components/Button';
import { Copy } from '../../components/DuotoneIcon';
import defaultTheme from '../../assets/styles/themes/default';
import { textToClipboard } from '../../utils/copyToClipboard';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import PremiumPrices from '../Sheets/PremiumPrices';
import 'driver.js/dist/driver.css';
// eslint-disable-next-line
import WelcomePip from '../../components/WelcomePip';
import {
  LiderAvaliaEfetividade,
  // LiderAvaliaResultado,
  LiderColocouPlanoEmPratica,
  LideradoPercebeuPlanoEmPratica,
  PipForm,
} from '../Sheets/PipForms';
import { discLettersToPersoraLetters } from '../../utils/profileUtils';
import OnesignalCookieWatcher from '../../utils/OnesignalCookieWatcher';
import UpdateOwnerLeaderData from '../../components/UpdateOwnerLeaderData';
import FirstMapLeaderOwner from '../../components/FirstMapLeaderOwner';
import FirstMapSecondLeader from '../../components/FirstMapSecondLeader';
import UpdateSecondLeaderData from '../../components/UpdateSecondLeaderData';
import DisclaimerUserTypeHasChanged from '../../components/DisclaimerUserTypeHasChanged';

function UserSuccessModal({ ...props }) {
  const mensagemConvite = 'Tô entrando como beta tester da Persora, uma ferramenta que pode ajudar na gestão do nosso time. Se receberem um e-mail, fui eu que enviei, não é spam. Vocês vão baixar o app pelo convite do e-mail e fazer um micro teste super rápido. Ele mostra o seu perfil e o do time. Depois, passamos a acessar como interagir melhor em equipe.';
  const mensagemConviteLider = 'Adicionei você como líder, com as mesmas permissões que eu, no nosso workspace da Persora. Estamos usando como beta testers, e conto com sua ajuda para explorar as funcionalidades e acompanhar os resultados no nosso time.';
  const { currentUserContextData } = useContext(UserContext);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="pt-0">
        <h1 className="sourcesans mb-3 text-start" style={{ color: defaultTheme.colors.primary.main }}>
          Convites enviados! ✈️
        </h1>
        <p className="text-start mb-3 d-none">
          Informe aos membros da sua equipe que foram adicionados
          {' '}
          à Persora. Você pode usar a mensagem abaixo:
        </p>
        <div className="form-label">Sugestão de mensagem para enviar no Teams/Slack/Discord/WhatsApp:</div>
        <div className="form-control">
          <p className="mb-0">
            {currentUserContextData.auxLastTypeOfUserInvited === 'Líder' && mensagemConviteLider}
            {currentUserContextData.auxLastTypeOfUserInvited === 'Colaborador' && mensagemConvite}
          </p>
        </div>
        <PersoraButton
          className="mt-4"
          onClick={() => {
            if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
              ReactGA.event({
                category: 'Engajamento',
                action: 'mensagem_copiada',
                msg_title: 'Sugestão de mensagem após convite',
              });
              mixpanel.track('mensagem_copiada', {
                category: 'Engajamento',
                company_id: currentUserContextData._idWorkspace[0],
                msg_title: 'Sugestão de mensagem após convite',
              });
            }
            textToClipboard(currentUserContextData.auxLastTypeOfUserInvited === 'Líder' ? mensagemConviteLider : mensagemConvite);
          }}
        >
          <Copy cor="#fff" />
          Copiar mensagem
        </PersoraButton>
      </Modal.Body>
    </Modal>
  );
}

export default function LoggedUser() {
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const { handleLogout } = useContext(Context);
  const { handleCurrentUserData } = useContext(UserContext);
  const [currentUser, setUser] = useState([]);
  // eslint-disable-next-line
  const [workspaceData, setWorkspace] = useState([]);
  const [workspaceTeams, setWorkspaceTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState([]); // [id, name, emoji, description]
  const [dataLoaded, setDataLoaded] = useState(false); // Novo estado
  const [addUserSheetIsOpen, setAddUserSheetOpen] = useState(false);
  const [premiumPricesSheetIsOpen, setPremiumPricesSheetOpen] = useState(false);
  const [addUserSuccessModal, setAddUserSuccessModalShow] = useState(false);
  const [teamSettingsSheetIsOpen, setTeamSettingsSheetOpen] = useState(false);
  const [reloadTeamData, setReloadTeamData] = useState(false);
  const [activeTapBar, setActiveTapBar] = useState('time'); // ['home', 'time', 'organizacao' ou 'treinamentos']
  const [countCurrentTeamUsers, setCountCurrentTeamUsers] = useState(0);
  // console.log('countCurrentTeamUsers', countCurrentTeamUsers);
  const [handleInviteUsersIsLoading, setHandleInviteUsersLoading] = useState(false);
  // Constantes para recursos e modais de PIP
  const [dispararWelcomePip, setDispararWelcomePip] = useState(false);
  const [liderColocouPlanoEmPraticaIsOpen, setLiderColocouPlanoEmPraticaOpen] = useState(false);
  const [clarityDataSeted, setClarityDataSeted] = useState(false);
  // const [liderAvaliaResultadoIsOpen, setLiderAvaliaResultadoOpen] = useState(false);
  const [liderAvaliaEfetividadeIsOpen, setLiderAvaliaEfetividadeOpen] = useState(false);
  const [
    lideradoPercebeuPlanoEmPraticaIsOpen, setLideradoPercebeuPlanoEmPraticaOpen,
  ] = useState(false);
  const [pipFormIsOpen, setPipFormOpen] = useState(false);

  // PARA MAIN -> WorkspaceTabs e TEAMSETTINGSSHEET
  const [pipEnableCurrentTeam, setPipEnableCurrentTeam] = useState(false);

  const handleTapBarClick = (option) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveTapBar(option);
    // Atualiza a URL com o argumento 'activeTapBar'
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('activeTapBar', option);
    const newURL = `${window.location.pathname}?${urlSearchParams.toString()}`;
    window.history.pushState({ path: newURL }, '', newURL);
    // console.log(option); // DEBUG
  };

  const handleInviteUsers = async (event, emails, type) => {
    event.preventDefault();

    // remove de emails qualquer posição que tenha value vazio
    const filteredEmails = emails.filter((email) => email.value.trim() !== '');

    // Verificar emails repetidos
    const emailValues = filteredEmails.map((email) => email.value);
    const duplicateEmails = emailValues.filter(
      (value, index) => emailValues.indexOf(value) !== index,
    );
    if (duplicateEmails.length > 0) {
      toast.error('Ops! Parece que você repetiu algum e-mail!', {
        autoClose: 3000,
        position: 'top-center',
      });
      return;
    }
    if (emailValues.length === 0) {
      toast.error('Digite pelo menos um e-mail!', {
        autoClose: 3000,
        position: 'top-center',
      });
      return;
    }
    setHandleInviteUsersLoading(true);
    try {
      console.log('invitou');
      const body = {
        emails: emailValues,
        msg: event.target.msg.value,
        team: event.target.team.value, /* TODO CHEGAR SE ESTÁ CERTO */
        type: (type === 'Líder' ? 'Líder' : 'Colaborador'),
      };
      // console.log('body', body);
      const { data } = await api.post('/user/guest', body);
      const { message } = data;
      const totalInvitedUsers = (message.match(/@/g) || []).length;
      console.log(`${totalInvitedUsers} usuários convidados.`);
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'convite_enviado',
          type: body.type,
        });
        mixpanel.track('convite_enviado', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
          n_invites: totalInvitedUsers,
          type: body.type,
        });
      }
      setReloadTeamData(true);
      // aguarde 3 segundos e faça setAddUserSuccessModalShow(true) sem travar o fluxo
      toast.success('Usuário(s) convidado(s) com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setTimeout(() => {
        setAddUserSuccessModalShow(true);
      }, 1000);
      setCurrentUserContextData((prevData) => ({
        ...prevData,
        workspaceContext: {
          ...prevData.workspaceContext,
          totalUsers: prevData.workspaceContext.totalUsers + emailValues.length,
          totalInvitedUsers: prevData.workspaceContext.totalInvitedUsers + emailValues.length,
        },
      }));
      if (type === 'Líder') {
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          auxLastTypeOfUserInvited: 'Líder',
        }));
      }
      if (type === 'Liderado' || type === 'Colaborador') {
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          auxLastTypeOfUserInvited: 'Colaborador',
          workspaceContext: {
            ...prevData.workspaceContext,
            ledUsers: prevData.workspaceContext.ledUsers + emailValues.length,
            totalInvitedUsers: prevData.workspaceContext.totalInvitedUsers + emailValues.length,
          },
        }));
      }
      setAddUserSheetOpen(false);
      setHandleInviteUsersLoading(false);
    } catch (error) {
      setAddUserSheetOpen(false);
      setHandleInviteUsersLoading(false);
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleUpdateTeamSettings = async (event) => {
    event.preventDefault();
    try {
      const body = {
        id: event.target._id.value,
        name: event.target.name.value,
        emoji: event.target.emoji.value,
        description: event.target.description.value,
      };
      const { data } = await api.put('/team/update', body);
      console.debug(data);
      // const updatedCurrentTeam = {
      //   ...currentTeam, name: body.name, emoji: body.emoji, description: body.description,
      // }; // não funcionou
      const updatedCurrentTeam = workspaceTeams.find((team) => team._id === currentTeam._id);
      updatedCurrentTeam.name = body.name;
      updatedCurrentTeam.emoji = body.emoji;
      updatedCurrentTeam.description = body.description;
      setCurrentTeam(updatedCurrentTeam);
      setTeamSettingsSheetOpen(false);
      toast.success('Configurações do time atualizadas com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Funcão para verificar se o formulário de PIP precisa ser aberto para liderado
  // isLedAt não pode ser vazio, pip tem que estar ativa
  // ao menos um time contido em isLedAt tem que estar com pip ativa
  // dia/hora tem que estar entre dia/hora inicio e fim
  // id do usuário não pode estar entre as answers da pip (ele ainda não respondeu)
  const checkIfPipFormNeedsToBeOpen = () => {
    const teamOptions = currentUserContextData.workspaceContext?.teams
      .filter((team) => currentUserContextData.isLedAt.includes(team._id));
    if (
      currentUserContextData?.isLedAt?.length > 0
      && currentUserContextData?.workspaceContext?.toolsSettings?.pip?.enabled
      && teamOptions.some(
        (team) => team?.toolsSettings?.pipEnabled,
      )
      && (new Date() >= new Date(currentUserContextData?.pipContext[0]?.startDate)
        && new Date() <= new Date(currentUserContextData?.pipContext[0]?.finalDate))
      && !currentUserContextData?.pipContext[0]?.answers?.some(
        (answer) => answer?.idUser === currentUserContextData?._id,
      )
      && currentUserContextData.disclaimers?.welcomeLeader
      && currentUserContextData.disclaimers?.firstMap
      && currentUserContextData.disclaimers?.teamHomeOverview
      && currentUserContextData.disclaimers?.indicadoresHomeOverview
      && currentUserContextData.disclaimers?.welcomePip
    ) {
      setPipFormOpen(true);
    }
  };

  // useEffect para checar se o usuário é do tipo lider,
  // se teamHomeOverview em disclaimers é true
  // se countCurrentTeamUsers => currentUserContextData.workspaceContext.ledUsers
  // é maior que 0 e menor que 5
  // se addUserSuccessModal é false
  // e se triggerAddUserAlreadOpen é false
  // se sim, setAddUserSheetOpen para true
  const [triggerAddUserAlreadOpen, setTriggerAddUserAlreadyOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (currentUser.type === 'Líder'
        && currentUserContextData.disclaimers?.teamHomeOverview
        && countCurrentTeamUsers > 0
        // && countCurrentTeamUsers < (4 + 1) // +1 da sora
        && currentUserContextData?.workspaceContext?.ledUsers < (3) // +1 da sora
        && addUserSuccessModal === false
        && !triggerAddUserAlreadOpen
      ) {
        setAddUserSheetOpen(true);
        setTriggerAddUserAlreadyOpen(true);
      }
      console.log('countCurrentTeamUsers', countCurrentTeamUsers);
    }, 1500);
  }, [countCurrentTeamUsers, currentUserContextData, addUserSuccessModal]);

  // ONBOARDING INDICADORES HOME
  const driverObjWelcomePip = driver({
    showProgress: true,
    allowClose: false,
    stagePadding: 5,
    stageRadius: 16,
    disableActiveInteraction: true,
    progressText: '{{current}} de {{total}}',
    onNextClick: async () => {
      setTimeout(async () => {
        scroller.scrollTo('thisIsPipCard', {
          duration: 100,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -90, // Para que o elemento fique 50px abaixo do topo da tela
        });
        if (!driverObjWelcomePip.hasNextStep()) {
          driverObjWelcomePip.moveNext();
          console.log('ultimoPasso');
          // setWelcomePipShow(true);
          try {
            const body = {
              indicadoresHomeOverview: true,
            };
            await api.patch('/user/disclaimer', body);
            setCurrentUserContextData((prevData) => ({
              ...prevData,
              disclaimers: {
                ...prevData.disclaimers,
                indicadoresHomeOverview: true,
              },
            }));
          } catch (error) {
            console.log(error);
          }
        }
        // console.log('moveNext');
      }, 500);
      // after 300ms
      setTimeout(() => {
        driverObjWelcomePip.moveNext();
      }, 500);
    },
    steps: [
      {
        element: '.TapBarIndicadores',
        popover: {
          title: 'Este é o ambiente de indicadores!',
          description: (currentUser.type === 'Líder'
            ? 'Acompanhe em detalhes como o seu time está e acesse estratégias para melhorias contínuas.'
            : 'Acompanhe como o seu time está para apoiar sua liderança de forma eficaz.'
          ),
          side: 'top',
          align: 'center',
          showButtons: 'next',
          nextBtnText: 'Próximo',
          popoverClass: 'tapbartime-popover',
        },
      },
      {
        element: '.thisIsPipCard',
        popover: {
          title: 'Novo recurso disponível: PIP!',
          description: (currentUser.type === 'Líder'
            ? 'Entenda como esse recurso pode aprimorar a sua liderança no dia a dia.'
            : 'Descubra como esse recurso ajudará seu time no dia a dia.'
          ),
          side: 'bottom',
          align: 'center',
          showButtons: 'next',
          nextBtnText: 'Vamos lá',
        },
      },
    ],
  });

  // Função para verificar a presença de 'activeTapBar' na URL
  useEffect(() => {
    const checkURLParams = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.activeTapBar) {
        const tapBarValue = params.activeTapBar;
        // Certifique-se de que o valor está entre os permitidos
        // ('home', 'time', 'organizacao' ou 'user')
        if (['home', 'time', 'organizacao', 'user'].includes(tapBarValue)) {
          setActiveTapBar(tapBarValue);
        }
      }
    };
    // Chama a função ao montar o componente
    checkURLParams();
  }, []); // A dependência vazia garante que a função seja chamada apenas na montagem do componente

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/user');
        setUser(data.user);
        // console.log('Todos os dados da rota GET /user', data); // DEBUG
        handleCurrentUserData(data);
        setWorkspace(data.workspace);
        setWorkspaceTeams(data.teams);
        setCurrentTeam(data.teams[0]);
        setDataLoaded(true);
      } catch (error) {
        if (error.response.data.error === 'Usuário não encontrado.') {
          // Tratamento de erro específico para "Usuário não encontrado"
          handleLogout();
        } else {
          // Outros tratamentos de erro aqui, se necessário
          handleLogout();
          console.error('Erro ao buscar o usuário:', error);
        }
      }
    })();
  }, [handleLogout]);
  // console.log('Carregou dados do usuário em LoggedUser', currentUser); // DEBUG
  // console.log('Carregou timeSelecionado em LoggedUser', currentTeam); // DEBUG

  useEffect(() => {
    if (currentUser._id && currentUser._idWorkspace && !clarityDataSeted) {
      if (window.clarity) {
        setClarityDataSeted(true);
        // console.log(
        //   'Clarity está ativo',
        //   'user_id',
        //   currentUser._id,
        //   'company_id',
        //   currentUser._idWorkspace[0],
        // );
        window.clarity('set', 'user_id', currentUser._id);
        window.clarity('set', 'company_id', currentUser._idWorkspace[0]);
      }
    }
    if (currentUser.email) {
      setDataLoaded(true);
      checkIfPipFormNeedsToBeOpen();
    }
    // console.log(workspaceData); // DEBUG
  }, [currentUser, currentUserContextData?.disclaimers]);

  useEffect(() => {
    const teamOptions = currentUserContextData.workspaceContext?.teams
      .filter((team) => currentUserContextData.isLedAt.includes(team._id));
    if (currentUserContextData?.isLeaderAt?.length > 0
      && currentUserContextData?.workspaceContext?.toolsSettings?.pip?.enabled
      && currentUserContextData?.disclaimers?.teamHomeOverview
      && currentUserContextData?.workspaceContext?.teams.some(
        (team) => team?.toolsSettings?.pipEnabled,
      )
      && currentUserContextData?.pipContext[0]
    ) {
      if (!currentUserContextData?.disclaimers?.indicadoresHomeOverview
      && !currentUserContextData?.disclaimers?.welcomePip
      && currentUserContextData?.disclaimers?.showOnboardingOnTypeChange !== true
      ) {
        if (activeTapBar !== 'organizacao') {
          handleTapBarClick('organizacao');
        }
        // delay de 300ms
        setTimeout(() => {
          driverObjWelcomePip.drive();
        }, 800);
      }
      if (currentUserContextData?.disclaimers?.indicadoresHomeOverview
        && !currentUserContextData?.disclaimers?.welcomePip) {
        setDispararWelcomePip(true);
      } else {
        setDispararWelcomePip(false);
      }
    }
    if (
      currentUserContextData?.isLedAt?.length > 0
      && currentUserContextData?.workspaceContext?.toolsSettings?.pip?.enabled
      && teamOptions.some(
        (team) => team?.toolsSettings?.pipEnabled,
      )
      && (new Date() >= new Date(currentUserContextData?.pipContext[0]?.startDate)
        && new Date() <= new Date(currentUserContextData?.pipContext[0]?.finalDate))
      && currentUserContextData.disclaimers?.welcomeLeader
      && currentUserContextData.disclaimers?.firstMap
      && currentUserContextData.disclaimers?.teamHomeOverview
    ) {
      if (!currentUserContextData.disclaimers?.indicadoresHomeOverview
      && !currentUserContextData.disclaimers?.welcomePip
      && currentUserContextData?.disclaimers?.showOnboardingOnTypeChange !== true
      ) {
        if (activeTapBar !== 'organizacao') {
          handleTapBarClick('organizacao');
        }
        // delay de 300ms
        setTimeout(() => {
          driverObjWelcomePip.drive();
        }, 800);
      }
      if (currentUserContextData?.disclaimers?.indicadoresHomeOverview
        && !currentUserContextData?.disclaimers?.welcomePip) {
        setDispararWelcomePip(true);
      } else {
        setDispararWelcomePip(false);
      }
    }
  }, [currentUserContextData?.disclaimers]);

  if (!dataLoaded) {
    // console.log(currentUser); // DEBUG
    return <PersoraLoader />;
  }
  if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
    ReactGA.set({
      user_id: currentUserContextData._id,
      user_properties: {
        company_id: currentUserContextData._idWorkspace[0],
        // user_plan: (currentUserContextData.workspaceContext.premium ? 'Pago' : 'Gratuito'),
        user_type: currentUserContextData.type,
        user_owner: currentUserContextData.owner,
        // eslint-disable-next-line
        user_style: (
          currentUserContextData.displayedProfileLetters
          && discLettersToPersoraLetters(currentUserContextData.displayedProfileLetters)
        ),
        name: currentUserContextData.name,
        email: currentUserContextData.email,
        user_created_at: currentUserContextData.createdAt,
        user_adoption_version: currentUserContextData?.adoption_version,
        first_leadership: currentUserContextData?.settings?.firstTimeLeader,
        n_led: currentUserContextData?.settings?.ledNumber,
        source: currentUserContextData?.settings?.howMetPersora,
      },
    });
    // MIXPANEL => Identifica o usuário no Mixpanel usando o ID do usuário
    mixpanel.identify(currentUserContextData._id);
    // MIXPANEL => Define as propriedades do usuário
    mixpanel.people.set({
      // Propriedades padrões do mixpanel
      $name: currentUserContextData.name,
      $email: currentUserContextData.email,
      $created: currentUserContextData.createdAt, // antes era user_created_at
      // Propriedades personalizadas
      // company_id: currentUserContextData._idWorkspace[0], // propriedade de grupo do mixpanel
      user_type: currentUserContextData.type,
      user_owner: !!currentUserContextData.owner,
      user_style: (
        currentUserContextData.displayedProfileLetters
        && discLettersToPersoraLetters(currentUserContextData.displayedProfileLetters)
      ),
      first_leadership: currentUserContextData?.settings?.firstTimeLeader,
      n_led: currentUserContextData?.settings?.ledNumber,
      source: currentUserContextData?.settings?.howMetPersora,
      emails_activated: !!currentUserContextData?.notificationSettings?.general?.email,
      user_adoption_version: currentUserContextData?.adoption_version,
    });
    // MIXPANEL => Associa o usuário ao grupo (empresa)
    mixpanel.set_group('company_id', currentUserContextData._idWorkspace[0]);
    // MIXPANEL => Define as propriedades do grupo (empresa)
    mixpanel.get_group('company_id', currentUserContextData._idWorkspace[0]).set({
      company_plan: currentUserContextData?.workspaceContext?.premium ? 'Pago' : 'Gratuito',
      company_segment: currentUserContextData?.workspaceContext?.segment,
      team_area: currentUserContextData?.workspaceContext?.teamDepartment,
      n_users: currentUserContextData?.workspaceContext?.totalUsers,
      n_mapped_users: currentUserContextData?.workspaceContext?.totalUsersMapped,
      pip_enabled: (!!currentUserContextData?.workspaceContext?.toolsSettings.pip.enabled),
    });
  }

  // Retorna o componente de boas-vindas se o usuário não tiver feito o onboarding
  if (currentUser.disclaimers === undefined
      || currentUser.disclaimers.welcomeLeader === undefined
      || currentUser.disclaimers.welcomeLeader === false
  ) {
    return (
      <>
        <OnesignalCookieWatcher />
        <Welcome currentUser={currentUser} />
      </>
    );
  }

  // Retorna o componente de boas-vindas se o usuário não tiver feito o primeiro mapeamento
  if (currentUser.disclaimers === undefined
    || currentUser.disclaimers.firstMap === undefined
    || currentUser.disclaimers.firstMap === false
  ) {
    return (
      <>
        <OnesignalCookieWatcher />
        {/* eslint-disable-next-line */}
        {currentUser.type === 'Líder'
          ? (currentUser.owner === true
            ? <FirstMapLeaderOwner user={currentUser} />
            : <FirstMapSecondLeader user={currentUser} />)
          : <FirstMap user={currentUser} />}
      </>
    );
  }

  // Retorna o componente para atualização de dados cadastrais
  if (currentUser.type === 'Líder' && currentUser.owner === true && (
    !currentUserContextData.settings?.firstTimeLeader
      || !currentUserContextData.settings?.ledNumber
      || !currentUserContextData.settings?.howMetPersora
      || !currentUserContextData.workspaceContext?.segment
      || !currentUserContextData.workspaceContext?.teamDepartment
  )) {
    return (
      <>
        <OnesignalCookieWatcher />
        <UpdateOwnerLeaderData user={currentUser} />
      </>
    );
  }
  // Retorna o componente para atualização de dados cadastrais caso seja um lider promovido
  // e não tenha preenchiemento de dados
  if (currentUser.type === 'Líder' && currentUser.owner === false && (
    !currentUserContextData.settings?.firstTimeLeader
      || !currentUserContextData.settings?.ledNumber
  )) {
    return (
      <>
        <OnesignalCookieWatcher />
        <UpdateSecondLeaderData user={currentUser} />
      </>
    );
  }

  const userName = currentUser.name;
  const lastProfile = currentUser.profiles[currentUser.profiles.length - 1];
  const userProfileLetters = lastProfile.displayedProfileLetters;

  // useEffect(() => {
  //   if (currentUserContextData?.disclaimer?.indicadoresHomeOverview === true) {
  //     console.log('AOOOOOO ENTROU AQUI');
  //       <WelcomePip currentUser={currentUser} />;
  //   }
  // }, [currentUserContextData?.disclaimer?.indicadoresHomeOverview]);

  // useEffect(() => {
  //   if (currentUserContextData?.isLeaderAt?.length > 0
  //     && currentUserContextData?.workspaceContext?.toolsSettings?.pip?.enabled
  //     && !currentUserContextData?.disclaimers?.welcomePip
  //     && !currentUserContextData?.disclaimers?.indicadoresHomeOverview
  //     && currentUserContextData?.disclaimers?.teamHomeOverview
  //     && currentUserContextData?.workspaceContext?.teams.some(
  //       (team) => team?.toolsSettings?.pipEnabled,
  //     )
  // ) {
  //   handleTapBarClick('organizacao');
  //   driverObjWelcomePip.drive();
  // }}, [currentUserContextData?.disclaimers]);

  if (dispararWelcomePip) {
    return (
    // TODO: tratar setWelcomePipShow em WelcomePip -> setWelcomePipShow={setWelcomePipShow}
      <>
        <OnesignalCookieWatcher />
        <WelcomePip
          currentUser={currentUser}
          currentTeam={currentTeam}
          setDispararWelcomePip={setDispararWelcomePip}
        />
      </>
    );
  }

  return (
    <PageFlexContainer>
      <OnesignalCookieWatcher />
      <DisclaimerUserTypeHasChanged />
      <UserSuccessModal
        show={addUserSuccessModal}
        onHide={() => setAddUserSuccessModalShow(false)}
      />
      {/* Header Superior Fixo */}
      <Header
        userData={currentUser}
        workspaceTeams={workspaceTeams}
        currentTeam={currentTeam}
        activeTapBar={activeTapBar}
        setActiveTapBar={setActiveTapBar}
        handleInviteUsers={handleInviteUsers}
        setAddUserSheetOpen={setAddUserSheetOpen}
        setTeamSettingsSheetOpen={setTeamSettingsSheetOpen}
        setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
        countCurrentTeamUsers={countCurrentTeamUsers}
      />
      {/* Conteúdo Scrollável Main */}
      <MainContainer>
        {/* <GoalsTracker
          userFirstGoal={currentUser.firstGoal}
          userTasksGoals={currentUser.tasksGoals}
        /> */}
        <Main
          activeTapBar={activeTapBar}
          currentTeam={currentTeam}
          setActiveTapBar={handleTapBarClick}
          addUserSheetOpen={addUserSheetIsOpen}
          setAddUserSheetOpen={setAddUserSheetOpen}
          setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
          reloadTeamData={reloadTeamData}
          setReloadTeamData={setReloadTeamData}
          setCountCurrentTeamUsers={setCountCurrentTeamUsers}
          setPipFormOpen={setPipFormOpen}
          pipEnableCurrentTeam={pipEnableCurrentTeam}
          setPipEnableCurrentTeam={setPipEnableCurrentTeam}
        />
      </MainContainer>
      {/* TapBar Inferior Inferior Fixa */}
      <TapBar
        onTapBarClick={handleTapBarClick}
        activeTapBar={activeTapBar}
        userName={userName}
        userProfileLetters={userProfileLetters}
        currentUser={currentUser}
        currentTeam={currentTeam}
      />
      {/* Sheets */}
      {/* Sheet AddUserSheet */}
      <Sheet
        isOpen={addUserSheetIsOpen}
        onClose={() => setAddUserSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag={!addUserSheetIsOpen}
      >
        <AddUserSheet
          handleInviteUsers={handleInviteUsers}
          handleInviteUsersIsLoading={handleInviteUsersIsLoading}
          workspaceTeams={workspaceTeams}
        />
        <Sheet.Backdrop onTap={() => setAddUserSheetOpen(false)} />
      </Sheet>
      {/* Sheet PremiumPrices */}
      <Sheet
        isOpen={premiumPricesSheetIsOpen}
        onClose={() => setPremiumPricesSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        disableDrag={!premiumPricesSheetIsOpen}
      >
        <PremiumPrices
          setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
          exceededLimitVersion
        />
        <Sheet.Backdrop onTap={() => setPremiumPricesSheetOpen(false)} />
      </Sheet>
      {/* Sheet TeamSettingsSheet */}
      <Sheet
        isOpen={teamSettingsSheetIsOpen}
        onClose={() => setTeamSettingsSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag={!teamSettingsSheetIsOpen}
      >
        <TeamSettingsSheet
          handleUpdateTeamSettings={handleUpdateTeamSettings}
          currentTeam={currentTeam}
          setTeamSettingsSheetOpen={setTeamSettingsSheetOpen}
          setAddUserSheetOpen={setAddUserSheetOpen}
          setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
          countCurrentTeamUsers={countCurrentTeamUsers}
          setReloadTeamData={setReloadTeamData}
          pipEnableCurrentTeam={pipEnableCurrentTeam}
          setPipEnableCurrentTeam={setPipEnableCurrentTeam}
        />
        <Sheet.Backdrop onTap={() => setTeamSettingsSheetOpen(false)} />
      </Sheet>

      <Sheet
        isOpen={liderColocouPlanoEmPraticaIsOpen}
        onClose={() => setLiderColocouPlanoEmPraticaOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag
      >
        <LiderColocouPlanoEmPratica />
        <Sheet.Backdrop style={{ background: 'red' }} />
      </Sheet>

      {/* <Sheet
        isOpen={liderAvaliaResultadoIsOpen}
        onClose={() => setLiderAvaliaResultadoOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag
      >
        <LiderAvaliaResultado />
        <Sheet.Backdrop style={{ background: 'red' }} />
      </Sheet> */}

      <Sheet
        isOpen={liderAvaliaEfetividadeIsOpen}
        onClose={() => setLiderAvaliaEfetividadeOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag
      >
        <LiderAvaliaEfetividade />
        <Sheet.Backdrop style={{ background: 'red' }} />
      </Sheet>

      <Sheet
        isOpen={lideradoPercebeuPlanoEmPraticaIsOpen}
        onClose={() => setLideradoPercebeuPlanoEmPraticaOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag
      >
        <LideradoPercebeuPlanoEmPratica />
        <Sheet.Backdrop style={{ background: 'red' }} />
      </Sheet>

      <Sheet
        isOpen={pipFormIsOpen}
        onClose={() => setPipFormOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        disableScrollLocking
        disableDrag
      >
        <PipForm setPipFormOpen={setPipFormOpen} handleTapBarClick={handleTapBarClick} />
        <Sheet.Backdrop style={{ background: 'red' }} onTap={() => setPipFormOpen(false)} />
      </Sheet>

    </PageFlexContainer>
  );
}
