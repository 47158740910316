import {
  useEffect,
  useState,
  useContext,
} from 'react';

// import ReactGA from 'react-ga4';
// import mixpanel from 'mixpanel-browser';

import { Sheet } from 'react-modal-sheet';
// import SoraDisclaimer from '../../assets/img/SoraFace/NeutraUserData.svg';
import SoraLeader from '../../assets/img/soraLeader.svg';
import SoraLed from '../../assets/img/soraLed.svg';
import api from '../../services/backend';
import PersoraButton from '../Button';
import { UserContext } from '../../Context/UserContext';

import defaultTheme from '../../assets/styles/themes/default';

import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';

export default function DisclaimerUserTypeHasChanged() {
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [sheetIsOpen, setSheetOpen] = useState(false);

  useEffect(() => {
    if (currentUserContextData.disclaimers.showOnboardingOnTypeChange === true) {
      setSheetOpen(true);
    }
  }, []);

  const okay = async () => {
    setSheetOpen(false);
    try {
      const bodyDisclaimer = {
        showOnboardingOnTypeChange: false,
      };
      await api.patch('/user/disclaimer', bodyDisclaimer);
      setCurrentUserContextData({
        ...currentUserContextData,
        disclaimers: {
          ...currentUserContextData.disclaimers,
          showOnboardingOnTypeChange: false,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Sheet
      isOpen={sheetIsOpen}
      onClose={okay}
      onOpenStart={startAndroidSheetHack}
      onCloseEnd={breakAndroidSheetHack}
      detent="content-height"
      tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
      disableDrag={!sheetIsOpen}
    >
      <Sheet.Container>
        <Sheet.Header style={{ textAlign: 'center' }}>
          {currentUserContextData.type === 'Líder' && <img src={SoraLeader} alt="Sora - Copiloto" className="mb-3 mt-4" style={{ width: '80px' }} />}
          {currentUserContextData.type === 'Colaborador' && <img src={SoraLed} alt="Sora - Copiloto" className="mb-3 mt-4" style={{ width: '80px' }} />}
        </Sheet.Header>
        <Sheet.Content>
          <Sheet.Scroller>
            <div
              style={{
                padding: defaultTheme.spaces.screenEdge,
                paddingTop: 0,
                color: defaultTheme.colors.primary.main,
                textAlign: 'center',
              }}
            >
              {currentUserContextData.type === 'Líder' && (
                <h3 className="sourcesans">
                  Você agora tem permissão de
                  <br />
                  líder neste workspace!
                </h3>
              )}
              {currentUserContextData.type === 'Colaborador' && (
                <h3 className="sourcesans">Atualização de permissão!</h3>
              )}
              {currentUserContextData.type === 'Líder' && (
                <p className="mb-4" style={{ fontSize: '1.1rem' }}>
                  Alinhe responsabilidades e expectativas com a liderança existente
                  {' '}
                  para fortalecer a colaboração e impulsionar os resultados do time.
                </p>
              )}
              {currentUserContextData.type === 'Colaborador' && (
                <p className="mb-4" style={{ fontSize: '1.1rem' }}>
                  Sua permissão neste workspace foi atualizada para liderado. A liderança
                  {' '}
                  existente conta com sua colaboração para o bom funcionamento do time.
                </p>
              )}

              <PersoraButton
                tamanho="default"
                onClick={okay}
                className="mb-4"
              >
                Entendi
              </PersoraButton>
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={okay} />
    </Sheet>
  );
}
