/* eslint-disable max-len */

import {
  BumperContent,
  BlueContainer,
  P,
  Title,
  EmojiItem,
  Emoji,
  Citacao,
  Fonte,
  Depoimento,
  DepoimentoFooter,
  Foto,
  Nome,
  Gif,
} from './styles';

import SoraBumperCover from '../SoraBumperCover';
import SoraAvatar from '../../assets/img/sora-avatar.svg';
// import Amanda from '../../assets/img/bumpers/depoimentos/amanda.jpg';
import Elaine from '../../assets/img/bumpers/depoimentos/elaine.jpg';
import Felipe from '../../assets/img/bumpers/depoimentos/felipe.jpg';
import Juliana from '../../assets/img/bumpers/depoimentos/juliana.jpg';
import Murilo from '../../assets/img/bumpers/depoimentos/murilo.jpg';
import Nayara from '../../assets/img/bumpers/depoimentos/nayara.jpg';
import Renato from '../../assets/img/bumpers/depoimentos/renato.jpg';
import Pedro from '../../assets/img/bumpers/depoimentos/pedro.jpg';
import { Cardiology, Star1 } from '../DuotoneIcon';

import GifConversaDificil from '../../assets/img/bumpers/gif_conversa_dificil_cp.gif';
import GifProdutividade from '../../assets/img/bumpers/gif_produtividade_cp.gif';
import GifConfianca from '../../assets/img/bumpers/gif_confianca_cp.gif';
import GifReuniao from '../../assets/img/bumpers/gif_reuniao_cp.gif';
import GifComunicacao from '../../assets/img/bumpers/gif_comunicacao_cp.gif';
import GifPontosFortes from '../../assets/img/bumpers/gif_pontosfortes_cp.gif';

export const bumperContents = (
  {
    bumperId,
    customContent1prop,
    customContent2prop,
  },
) => {
  // const urlParams = new URLSearchParams(window.location.search);
  // const firstCustomContent = (urlParams.get('customContent1') ? urlParams.get('customContent1') : customContent1prop || '');
  // const secondCustomContent = (urlParams.get('customContent2') ? urlParams.get('customContent2') : customContent2prop || '');
  const firstCustomContent = customContent1prop;
  const secondCustomContent = customContent2prop;
  // console.log(firstCustomContent, secondCustomContent);
  switch (bumperId) {
    case 'demo':
      return (
        {
          title: 'Liderar um squad pode ser mais desafiador do que se imagina.',
          content: (
            <>
              <SoraBumperCover />
              <BumperContent>
                <Title>Liderar um squad pode ser mais desafiador do que se imagina.</Title>
                <P>
                  Os times geralmente são compostos por desenvolvedores, designers,
                  analistas de dados e especialistas em marketing. Manter todo alinhados
                  é um grande desafio.
                </P>
                <P>
                  Mas, agora você conta com todos os recursos da Persora gratuitamente para
                  utilizar com até 4 liderados.
                </P>
                <P>Você não precisa mais liderar sozinho. 🤗</P>
                <Title>Gerir times tech está exigindo uma nova forma de liderar</Title>
                <P>Com a nossa tecnologia e metodologia, de forma ágil, você vai:</P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>💬</Emoji>
                    <P>Saber como se comunicar e aproveitar o melhor de cada pessoa do time.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🚀</Emoji>
                    <P>Entender como o time está e saber o que fazer para melhorar continuamente.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🎯</Emoji>
                    <P>Manter todos alinhados com o propósito e as regras do jogo.</P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Como prometido, o seu acesso total aos recursos da Persora
                  gratuitamente está disponível. Você pode utilizá-lo com até 4 liderados.
                </P>
                <Title>
                  44% dos colaboradores não confiam em sua liderança
                </Title>
                <BlueContainer>
                  <Citacao>
                    Geralmente, a confiança surge quando há uma conexão significativa.
                    A copiloto do líder auxilia na construção dessa conexão individualizada, permitindo
                    compreender o que motiva cada pessoa. Isso é fundamental para líderes que querem
                    equipes altamente comprometidas.
                  </Citacao>
                  <hr />
                  <Fonte>
                    Fonte: Leadership IQ (7.209 entrevistados)
                  </Fonte>
                </BlueContainer>
                <P>
                  Aproveite! O seu acesso total aos recursos da Persora gratuitamente
                  está disponível. Você pode utilizá-lo com até 4 liderados.
                </P>
                <Title>O líder tech não gosta de ser pego de surpresa.</Title>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Geralmente, a confiança surge quando há uma conexão significativa.
                    A copiloto do líder auxilia na construção dessa conexão individualizada, permitindo
                    compreender o que motiva cada pessoa. Isso é fundamental para líderes que querem
                    equipes altamente comprometidas.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                    <Nome>
                      <b>Sora</b>
                      <br />
                      Copiloto
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  O seu acesso total aos recursos da Persora gratuitamente
                  está disponível. Você pode utilizá-lo com até 4 liderados.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    // shape 1 e 2
    case 'l-signUp-b1':
      return (
        {
          title: '🤝 Como a Persora te ajuda a liderar?',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  🤝 Como a Persora te ajuda a liderar?
                </Title>
                <P>
                  Liderar é difícil! Mas, você não está sozinho. A Persora une tecnologia com um método ágil e fundamentado para apoiar o líder no dia a dia.
                </P>
                <P>
                  E se durante uma one-on-one (1:1) com um dev back-end do time você acessasse como se comunicar de maneira efetiva com esse liderado?
                </P>
                <P>
                  <b>Exemplos de como a Persora ajuda na sua liderança.</b>
                </P>
                <BlueContainer>
                  <p className="mb-3">Por exemplo:</p>
                  <Depoimento>
                    Lembre-se de manter um tom de voz calmo com o João, evite pressioná-lo para tomar decisões imediatas e dê espaço para reflexão antes de fazer perguntas[...]
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                    <Nome>
                      <b>Sora</b>
                      <br />
                      Copiloto
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  A partir de mapeamentos e algoritmos validados, a copiloto entrega orientações personalizadas para te auxiliar em conversas difíceis, na integração de novos membros na equipe e muito mais.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-signUp-b2':
      return (
        {
          title: '😃 Como a Persora apoia o líder tech',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  😃 Como a Persora apoia o líder tech
                </Title>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Somos full home office e o contato com o time tende a ser mais robótico. A Persora tornou simples a comunicação com cada liderado em diversas situações, como feedbacks e reuniões. Isso tem sido um grande diferencial na minha liderança.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Felipe} alt="Felipe Shimada" />
                    <Nome>
                      <b>Felipe Shimada</b>
                      <br />
                      CTO na Exent
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Liderar é difícil, mas você não está sozinho. Acesse a Persora e teste gratuitamente com todo o seu time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-signUp-b3':
      return (
        {
          title: '😃 Uma nova forma de liderar times tech',
          content: (
            <>
              <SoraBumperCover sora="Animada" bg={3} shape={1} />
              <BumperContent>
                <Title>
                  😃 Uma nova forma de liderar times tech
                </Title>
                <P>
                  A Persora une tecnologia com um método ágil e fundamentado para apoiar o líder no dia a dia. Você vai:
                </P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>💬</Emoji>
                    <P>Saber como se comunicar e aproveitar o melhor de cada pessoa do time.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🚀</Emoji>
                    <P>Entender como o time está e saber o que fazer para melhorar continuamente.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🎯</Emoji>
                    <P>Manter todos alinhados com o propósito e as regras do jogo.</P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Estamos empenhados em apoiá-lo nesse desafio. Adicione todo o seu time e teste gratuitamente.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-signUp-b4':
      return (
        {
          title: '😃 O resultado da Persora nos times',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  😃 O resultado da Persora nos time
                </Title>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Na nossa experiência com profissionais de criação, em determinadas situações é necessário orientá-los para entregas que exigem menos criatividade, porém é desafiador comunicar isso e aproveitá-los corretamente. A Persora entrega essa conexão do time com o líder e isso tem impacto rápido.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Elaine} alt="Elaine Colombo" />
                    <Nome>
                      <b>Elaine Colombo</b>
                      <br />
                      Co-founder da Hubee
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Acesse a Persora e teste gratuitamente com o seu time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-welcome-b1':
      return (
        {
          title: '😱 44% dos colaboradores não confiam em sua liderança',
          content: (
            <>
              <SoraBumperCover sora="Chateada" bg={4} shape={3} />
              <BumperContent>
                <Title>
                  😱 44% dos colaboradores não confiam em sua liderança
                </Title>
                <BlueContainer>
                  <Citacao>
                    Geralmente, a confiança surge quando há uma conexão significativa. A copiloto do líder auxilia na construção dessa conexão individualizada, permitindo compreender o que motiva cada pessoa. Isso é fundamental para líderes que querem equipes altamente comprometidas.
                  </Citacao>
                  <hr />
                  <Fonte>
                    Fonte: Leadership IQ (7.209 entrevistados)
                  </Fonte>
                </BlueContainer>
                <P>
                  Liderar é difícil, mas você não está sozinho. Acesse a Persora e teste gratuitamente com todo o seu time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-welcome-b2':
      return (
        {
          title: 'Como a Persora impacta no dia a dia do time?',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={5} shape={3} />
              <BumperContent>
                <Title>
                  Como a Persora impacta no dia a dia do time?
                </Title>
                <P>
                  Abaixo inserimos o depoimento da Juliana, designer de um squad em que o líder implementou a Persora:
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Percebi que muitas das pessoas com as quais eu tinha dificuldade em me relacionar eram diferentes de mim, o que me proporcionou uma perspectiva valiosa. Sendo mais introvertida, comecei a me preparar para interações mais intensas e oferecer suporte às suas dificuldades de uma maneira mutuamente benéfica.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Juliana} alt="Juliana Gomes Pimenta" />
                    <Nome>
                      <b>Juliana Gomes Pimenta</b>
                      <br />
                      Designer
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Liderar é difícil, mas você não está sozinho. Acesse a Persora e teste gratuitamente com todo o seu time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-welcome-b3':
      return (
        {
          title: '🌪️ Liderar um squad é desafiador',
          content: (
            <>
              <SoraBumperCover sora="Confusa" bg={2} shape={2} />
              <BumperContent>
                <Title>
                  🌪️ Liderar um squad é desafiador
                </Title>
                <P>
                  Os times geralmente são compostos por desenvolvedores, designers, analistas de dados e especialistas em marketing. Manter todo alinhados é um grande desafio.
                </P>
                <P>
                  A partir de mapeamentos e algoritmos validados, a copiloto entrega orientações personalizadas para te auxiliar em conversas difíceis, na integração de novos membros na equipe e muito mais.
                </P>
                <P>
                  Estamos empenhados em apoiá-lo nesse desafio. Adicione todo o seu time e teste gratuitamente.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-welcome-b4':
      return (
        {
          title: '"Vocês embalaram tudo de forma simples"',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  &quot;Vocês embalaram tudo de forma simples&quot;
                </Title>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    A Persora embalou tudo de forma simples, aplicável, e de um jeito que dá resultado rápido.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Renato} alt="Renato Guitti" />
                    <Nome>
                      <b>Renato Guitti</b>
                      <br />
                      CEO na Exent
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  A partir de mapeamentos e algoritmos validados, a copiloto entrega orientações personalizadas para te auxiliar em conversas difíceis, na integração de novos membros na equipe e muito mais.
                </P>
                <P>
                  Adicione todo o seu time e teste gratuitamente.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-firstMap-b1':
      return (
        {
          title: 'Vou te ajudar a se comunicar com cada liderado',
          content: (
            <>
              <SoraBumperCover sora="OlhosCarinhosos" bg={4} shape={4} />
              <BumperContent>
                <Title>
                  Vou te ajudar a se comunicar com cada liderado
                </Title>
                <P>
                  O primeiro passo para ter a Persora no seu flow de trabalho é concluir o onboarding e mapear o seu estilo de liderança, leva menos de 3 minutos.
                </P>
                <P>
                  Fundamentado em instrumentos já validados, como DISC e 16personalities, desenvolvemos um algoritmo próprio de Estilos Comportamentais da Persora.
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Percebi que muitas das pessoas com as quais eu tinha dificuldade em me relacionar eram diferentes de mim, o que me proporcionou uma perspectiva valiosa. Sendo mais introvertida, comecei a me preparar para interações mais intensas e oferecer suporte às suas dificuldades de uma maneira mutuamente benéfica.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Juliana} alt="Juliana Gomes Pimenta" />
                    <Nome>
                      <b>Juliana Gomes Pimenta</b>
                      <br />
                      Designer
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Acesse o app da Persora e faça o mapeamento do seu estilo para eu te ajudar a se comunicar de maneira efetiva com todo o time.
                </P>
                <P />
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-firstMap-b2':
      return (
        {
          title: 'Como líder, você acessa as informações do time',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>Como líder, você acessa as informações do time</Title>
                <P>
                  Ao realizar o mapeamento do seu estilo (leva menos de 3 minutos), trabalharemos juntos para maximizar seus pontos fortes - suas habilidades mais avançadas -, em sua liderança.
                </P>
                <P>
                  E envolve todo o time. Como líder, você tem acesso às informações de cada pessoa do seu time, como por exemplo:
                </P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Como conduzir uma one-on-one (1:1);</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Como aumentar a produtividade;</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Qual é a maneira mais efetiva de enviar um e-mail;</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Abordagem em relação à inovação.</P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Acesse o app da Persora e faça o mapeamento do seu estilo para eu te ajudar a se comunicar de maneira efetiva com todo o time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-firstMap-b3':
      return (
        {
          title: 'Liderar um squad é sobre negociar com pessoas',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Liderar um squad é sobre negociar com pessoas
                </Title>
                <P>
                  Um time tech tem a missão de entregar produtos de alta qualidade dentro dos prazos estabelecidos, o que envolve negociação contínua. Negociar requer entender as próprias necessidades e as das outras pessoas.
                </P>
                <P>
                  Esse é um depoimento do Murilo, Sênior UX Designer de um time em que o líder implementou a Persora:
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Eu sou muito cético com mapeamentos comportamentais, eu preciso de provas o tempo todo. E com a Persora eu me convenci que isso é real. A gente vai vendo os detalhes e as coisas se encaixam de uma forma muito perfeita.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Murilo} alt="Murilo Ravani Viana" />
                    <Nome>
                      <b>Murilo Ravani Viana</b>
                      <br />
                      FlutterFlow Developer
                      {' '}
                      <br />
                      Senior UX Designer
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Acesse o aplicativo da Persora e realize o seu mapeamento para prosseguirmos juntos. Você não precisa mais liderar sozinho.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-firstMap-b4':
      return (
        {
          title: 'Qual é o seu estilo de liderança?',
          content: (
            <>
              <SoraBumperCover sora="Deslumbrada" bg={1} shape={4} />
              <BumperContent>
                <Title>
                  Qual é o seu estilo de liderança?
                </Title>
                <P>
                  Fundamentado em metodologias validadas como DISC e 16 Personalidades, desenvolvemos e validamos um algoritmo próprio de Estilos Comportamentais. É rápido de responder. É menos coaching, mais direto e prático para o dia a dia.
                </P>
                <P>
                  Ao compreender seu estilo comportamental atual, perceberá que ele é baseado na combinação de quatro grupos: Ação, Conexão, Organização e Visão.
                </P>
                <P>
                  Qual grupo predomina em você? 😊
                </P>
                <P>
                  Conclua o mapeamento do seu estilo para eu te ajudar a se comunicar de maneira efetiva com todo o time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-useActionButton-b1':
      return (
        {
          title: '👮 A copiloto entrou no seu time!',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  👮 A copiloto entrou no seu time!
                </Title>
                <P>
                  Sou a Sora, sua copiloto. Meu objetivo é te ajudar a liderar no dia a dia.
                </P>
                <P>
                  E se durante uma conversa difícil com um dev back-end do time você acessasse como se comunicar de maneira efetiva com esse liderado?
                </P>
                <BlueContainer>
                  <p className="mb-3">Por exemplo:</p>
                  <Depoimento>
                    Lembre-se de manter um tom de voz calmo com o João, evite pressioná-lo para tomar decisões imediatas e dê espaço para reflexão antes de fazer perguntas...
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                    <Nome>
                      <b>Sora</b>
                      <br />
                      Copiloto
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Estou acessível no ambiente de ação, basta tocar na
                  {' '}
                  <b>varinha mágica</b>
                  {' '}
                  da barra inferior.
                </P>
                <Gif src={GifConversaDificil} width={280} alt="Gif Conversa Difícil" />
                <P>
                  Adicione todo o seu time e teste gratuitamente.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-useActionButton-b2':
      return (
        {
          title: 'A copiloto funciona. Quer ver? 👀',
          content: (
            <>
              <SoraBumperCover sora="RindoSutilmente" bg={4} shape={5} />
              <BumperContent>
                <Title>
                  A copiloto funciona. Quer ver? 👀
                </Title>
                <P>
                  O meu estilo comportamental é o oposto do seu. Enquanto espera o seu time aceitar o convite da Persora, simule como interagir comigo.
                </P>
                <P>
                  É muito simples:
                </P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>1️⃣</Emoji>
                    <P>
                      Acesse o ambiente de ação, basta tocar na
                      {' '}
                      <b>varinha mágica</b>
                      {' '}
                      da barra inferior.
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>2️⃣</Emoji>
                    <P>
                      Selecione a
                      {' '}
                      <b>situação: Melhorar a produtividade</b>
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>3️⃣</Emoji>
                    <P>
                      Escolha uma pessoa do seu time:
                      {' '}
                      <b>Sora</b>
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>4️⃣</Emoji>
                    <P>
                      Acesse o resultado:
                      {' '}
                      <b>
                        Para melhorar a produtividade de Sora.
                      </b>
                    </P>
                  </EmojiItem>
                  <Gif src={GifProdutividade} width={280} alt="Gif Conversa Difícil" />
                </BlueContainer>
                <P>
                  Você não precisa mais liderar sozinho.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-useActionButton-b3':
      return (
        {
          title: 'Antes de uma conversa difícil use a Persora',
          content: (
            <>
              <SoraBumperCover sora="Deslumbrada" bg={1} shape={4} />
              <BumperContent>
                <Title>
                  Antes de uma conversa difícil use a Persora
                </Title>
                <P>
                  💭 Imagine que você precisa ter uma conversa difícil com um analista de dados do time.
                </P>
                <P>
                  E se você pudesse acessar a melhor maneira de conduzir uma conversa difícil com esse liderado?
                </P>
                <BlueContainer>
                  <p className="mb-3">Por exemplo:</p>
                  <Depoimento>
                    Lembre-se de manter um tom de voz calmo com o João, evite pressioná-lo para tomar decisões imediatas e dê espaço para reflexão antes de fazer perguntas[...]
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                    <Nome>
                      <b>Sora</b>
                      <br />
                      Copiloto
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Acesse a
                  {' '}
                  <b>Copiloto tocando na varinha mágica</b>
                  , na barra inferior do app. Basta selecionar a situação que precisa de ajuda, escolher a pessoa do time, e eu fornecerei as
                  {' '}
                  <b>orientações personalizadas para te apoiar.</b>
                </P>
                <Gif src={GifConversaDificil} width={280} alt="Gif Conversa Difícil" />
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invite3Users-b1':
      return (
        {
          title: '⏰ É hora de selecionar o seu time',
          content: (
            <>
              <SoraBumperCover sora="Comemorando" bg={1} shape={3} />
              <BumperContent>
                <Title>
                  ⏰ É hora de selecionar o seu time
                </Title>
                <P>
                  Agora que você baixou a Persora para apoiar sua liderança no dia a dia, chegou a hora de adicionar o seu time e testar.
                </P>
                <P>
                  Percebemos que muitos líderes preferem usar algum ponto de contato já existente (daily ou outras agendas) para falar com o time sobre o teste da Persora.
                </P>
                <P>
                  Então, para facilitar essa comunicação, conversamos com alguns dos usuários que tiveram uma boa adoção da copiloto no dia a dia da equipe e criamos algumas sugestões.
                </P>
                <P>
                  Ao tocar no botão
                  {' '}
                  <b>&quot;Convidar pessoas&quot;,</b>
                  {' '}
                  você terá acesso a um áudio da Milena, co-founder da Persora, com instruções rápidas e práticas sobre como conduzir essa conversa.
                </P>
                <P>Depois de adicionar os e-mails dos seus liderados, você também acessará um modelo de mensagem de texto para enviar ao seu time e comunicar sobre o convite.</P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invite3Users-b2':
      return (
        {
          title: 'Você ja se sentiu inseguro como líder?',
          content: (
            <>
              <SoraBumperCover sora="Triste" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Você ja se sentiu inseguro como líder?
                </Title>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>🤔</Emoji>
                    <P>Inseguro após fazer um bom curso de liderança, mas perceber que a teoria era genérica e difícil de aplicar no dia a dia.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>😬</Emoji>
                    <P>Ansioso por não ter certeza se está fazendo um bom trabalho como líder.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>😨</Emoji>
                    <P>Com medo de errar e acabar prejudicando o time.</P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Adicione o time na Persora e a copiloto te ajudará a liderar cada pessoa de forma prática.
                </P>
                <P>
                  Se surgir qualquer dúvida na hora comunicar com o seu time sobre o teste, ao tocar em [ADICIONAR O TIME] você acessará algumas dicas que preparamos para apoiá-lo nessa conversa.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invite3Users-b3':
      return (
        {
          title: 'O seu time e a Lei de Metcalfe',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Sabe porque é tão importante inserir todo o seu time no app?
                </Title>
                <P>
                  Inserir todo o seu time no app é crucial porque, de acordo com a Lei de Metcalfe, o valor de uma rede de comunicação aumenta exponencialmente com o número de usuários conectados. Quanto mais membros da equipe adotarem o app, mais valioso ele se tornará para cada indivíduo e time, proporcionando mais autonomia e oportunidades de conexão.
                </P>
                <P>
                  👮 Ver as pessoas com os olhos da copiloto acelera o conexão do time!
                </P>
                <P>
                  Você não precisa mais liderar sozinho. Adicione todo o seu time e teste a Persora gratuitamente.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invite3Users-b4':
      return (
        {
          title: '✨ "Observamos um rápido aumento na sinergia da equipe…"',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  A experiência de um time de tecnologia e inovação
                </Title>
                <P>
                  O livro &quot;a organização sem medo&quot; de Amy C. Edmondson traz um dado interessante sobre a mudança na dinâmica dos times:
                  {' '}
                  <i>&quot;atualmente, os times gastam 50% mais tempo colaborando do que há 20 anos&quot;</i>
                  . Ou seja, quanto mais rápido o mundo muda, mais precisamos nos unir para resolver problemas complexos.
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Com a Persora, a sinergia da equipe aumentou rapidamente, as pessoas se conheceram melhor e o espírito de colaboração foi fortalecido. As funcionalidades são valorizadas pelo time, não apenas no ambiente de trabalho, mas também em suas vidas pessoais, impactando positivamente sua produtividade e relacionamentos.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Nayara} alt="Nayara Kelly da Silva" />
                    <Nome>
                      <b>Nayara Kelly da Silva</b>
                      <br />
                      Líder no Findeslab - Hub de inovação e tecnologia da Findes
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Desenvolvemos uma metodologia, apoiada por tecnologia, que compartilha a responsabilidade de melhorar a gestão com todo o time, transformando o desenvolvimento das pessoas em uma jornada coletiva.
                </P>
                <P>
                  Adicione todo o seu time e teste a Persora gratuitamente.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    // shape 3
    case 'c-firstMap-b1':
      return (
        {
          title: '👋 Vou apoiar na gestão do time',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  👋 Vou apoiar na gestão do time
                </Title>
                <P>
                  Sou a Sora. Meu objetivo é apoiar na gestão do seu time, e para isso a Persora envolve toda a equipe, acreditamos em uma gestão compartilhada.
                </P>
                <P>
                  Esse é um depoimento da Juliana, designer de um squad em que o líder implementou a Persora:
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Percebi que muitas das pessoas com as quais eu tinha dificuldade em me relacionar eram diferentes de mim, o que me proporcionou uma perspectiva valiosa. Sendo mais introvertida, comecei a me preparar para interações mais intensas e oferecer suporte às suas dificuldades de uma maneira mutuamente benéfica.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Juliana} alt="Juliana Gomes Pimenta" />
                    <Nome>
                      <b>Juliana Gomes Pimenta</b>
                      <br />
                      Designer
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  A partir de mapeamentos e algoritmos validados, a copiloto entrega orientações personalizadas para te auxiliar em conversas difíceis, na integração de novos membros na equipe e muito mais. Leva menos de 3 minutos para responder. É direto e prático para o dia a dia.
                </P>
                <P>
                  <b>
                    O primeiro passo é acessar o app da Persora e realizar o mapeamento do seu estilo.
                  </b>
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-firstMap-b2':
      return (
        {
          title: 'Acesse o seu estilo na Persora',
          content: (
            <>
              <SoraBumperCover sora="Deslumbrada" bg={1} shape={4} />
              <BumperContent>
                <Title>Acesse o seu estilo na Persora</Title>
                <P>
                  A partir de mapeamentos e algoritmos validados, a copiloto entrega orientações personalizadas para te auxiliar em conversas difíceis, na integração de novos membros na equipe e muito mais.
                </P>
                <P>
                  Leva menos de 3 minutos para responder. É menos coaching, mais direto e prático para o dia a dia.
                </P>
                <P>
                  Ao realizar o mapeamento do seu estilo, você terá acesso a informações importantes relacionadas a você, como, por exemplo:
                </P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Características predominantes;</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Fatores estressantes e motivacionais;</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Preferências de comunicação (em conversas, e-mails, feedbacks etc.);</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>Qual a sua abordagem em relação à inovação.</P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Acesse o aplicativo Persora e faça seu mapeamento para contar com minha ajuda como copiloto. 😊
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-firstMap-b3':
      return (
        {
          title: 'Compor um squad é sobre negociar com pessoas',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Compor um squad é sobre negociar com pessoas
                </Title>
                <P>
                  Esse é um relato do Murilo, Designer UX em um squad que usa a Persora:
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Eu sou muito cético com mapeamentos comportamentais, eu preciso de provas o tempo todo. E com a Persora eu me convenci que isso é real. A gente vai vendo os detalhes e as coisas se encaixam de uma forma muito perfeita.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Murilo} alt="Murilo Ravani Viana" />
                    <Nome>
                      <b>Murilo Ravani Viana</b>
                      <br />
                      FlutterFlow Developer
                      {' '}
                      <br />
                      Senior UX Designer
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  Fundamentado em metodologias validadas como DISC e 16 Personalidades, desenvolvemos e validamos um algoritmo próprio de Estilos Comportamentais.
                </P>
                <P>
                  Realize o seu mapeamento para que eu possa te apoiar nas interações com o time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-firstMap-b4':
      return (
        {
          title: 'Estamos quase lá!',
          content: (
            <>
              <SoraBumperCover sora="OlhosCarinhosos" bg={4} shape={4} />
              <BumperContent>
                <Title>
                  Estamos quase lá!
                </Title>
                <P>
                  Ao mapear o seu estilo comportamental na Persora, perceberá que ele é baseado na combinação de quatro grupos: Ação, Conexão, Organização e Visão.
                </P>
                <P>
                  Fundamentado em instrumentos já validados, como DISC e 16personalities, desenvolvemos um algoritmo próprio de Estilos Comportamentais da Persora.
                </P>
                <P>
                  Leva menos de 3 minutos para responder. É menos coaching, mais direto e prático para o dia a dia.
                </P>
                <P>
                  Qual grupo predomina em você? 😊
                </P>
                <P>
                  Mapear o seu estilo é o primeiro passo para que eu possa te apoiar nas interações com o time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesAccepted-b1':
      return (
        {
          title: 'Convite(s) pendente(s)',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Convite(s) pendente(s)
                </Title>
                <P>
                  <b>Você ainda possui convidados com primeiro acesso pendente:</b>
                  <br />
                  <span dangerouslySetInnerHTML={
                { __html: secondCustomContent }
              }
                  />
                </P>
                <P>
                  Aqui está uma sugestão de mensagem para reforçar o convite (envie individualmente):
                </P>
                <BlueContainer>
                  <P>
                    Tô entrando como beta tester da Persora, uma ferramenta que pode ajudar na gestão do nosso time. Se receberem um e-mail, fui eu que enviei, não é spam. Vocês vão baixar o app pelo convite do e-mail e fazer um micro teste super rápido. Ele mostra o seu perfil e o do time. Depois, passamos a acessar como interagir melhor em equipe.
                  </P>
                </BlueContainer>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesAccepted-b2':
      return (
        {
          title: '🏆 Seu time pode ser destaque na sua organização',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  🏆 Seu time pode ser destaque na sua organização
                </Title>
                <P>
                  Você sabia que a Lei de Metcalfe pode lançar um novo olhar sobre os times?
                </P>
                <P>
                  Conhecida do universo tecnológico, a Lei de Metcalfe afirma que o valor de uma rede cresce de forma quadrática em relação ao número de usuários conectados.
                </P>
                <P>
                  Cada pessoa que você insere no time carrega um conjunto único de habilidades e experiências. Para ampliar esses talentos, precisamos interconectá-los, seguindo a lógica da Lei de Metcalfe.
                </P>
                <P>
                  <b>Mas aqui vai uma dica:</b>
                  {' '}
                  para atingir o resultado proposto pela Persora, é imprescindível que você adicione no mínimo 3 liderados.
                </P>
                <P>
                  Fazendo isso, você desbloqueia uma nova funcionalidade gratuita que te permite saber como o time está e receber orientações práticas para melhorar.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesAccepted-b3':
      return (
        {
          title: 'Convidei uma pessoa que não acessou a Persora. E agora?',
          content: (
            <>
              <SoraBumperCover sora="Chateada" bg={4} shape={3} />
              <BumperContent>
                <Title>
                  Inseri uma pessoa no time e ela não avançou
                </Title>
                <P>
                  Calma. Se isso aconteceu com você, sugiro duas ações:
                </P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>1️⃣</Emoji>
                    <P>
                      Estabeleça um prazo para o progresso dessa pessoa;
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>2️⃣</Emoji>
                    <P>
                      Reforce que a Persora é um ambiente seguro e independente entre você e seu time.
                    </P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Acompanhe o primeiro acesso de todo o time e tenha o apoio da Persora no dia a dia.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesAccepted-b4':
      return (
        {
          title: 'Com a Persora, o time pode se comunicar melhor',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Com a Persora, o time pode se comunicar melhor
                </Title>
                <P>
                  <b>Você ainda possui convidados com primeiro acesso pendente:</b>
                  <br />
                  <span dangerouslySetInnerHTML={{ __html: secondCustomContent }} />
                </P>
                <P>
                  Aqui está uma nova sugestão de mensagem para reforço do convite (envie individualmente):
                </P>
                <BlueContainer>
                  <P>
                    Já te enviei o convite da Persora, aquela ferramenta que pode nos apoiar na gestão do time. Queria que você também participasse para avaliarmos se é aplicável para o nosso dia. Por favor, dá uma olhada no seu e-mail e baixa o app, o primeiro acesso é bem rápido.
                  </P>
                </BlueContainer>
                <P>
                  Quando
                  {' '}
                  <b>pelo menos 3 liderados</b>
                  {' '}
                  completarem o primeiro acesso, você desbloqueará a funcionalidade que te permite saber como o time está e o que fazer para melhorar.
                </P>
                <P>
                  Acompanhe o primeiro acesso de todo o time e tenha o apoio da copiloto no dia a dia.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesFirstMap-b1':
      return (
        {
          title: 'Acompanhando o seu time',
          content: (
            <>
              <SoraBumperCover sora="Triste" bg={2} shape={5} />
              <BumperContent>
                <Title>
                  As pessoas a seguir ainda não realizaram o mapeamento do estilo na Persora:
                </Title>
                <P dangerouslySetInnerHTML={
                { __html: firstCustomContent }
            }
                />
                <P>
                  Aqui está uma sugestão de mensagem para reforçar sobre a importância dessa etapa (envie individualmente):
                </P>
                <BlueContainer>
                  <P>
                    Já te enviei o convite da Persora, aquela ferramenta que pode nos apoiar na gestão do time. Queria que você também participasse para avaliarmos se é aplicável para o nosso dia a dia. Por favor, dá uma olhada no seu e-mail e baixa o app.
                  </P>
                </BlueContainer>
                <P>
                  Quando
                  {' '}
                  <b>pelo menos 3 liderados</b>
                  {' '}
                  realizarem o mapeamento de estilo, você desbloqueará a funcionalidade que te permite saber como o time está e o que fazer para melhorar.
                </P>
                <P>
                  Acompanhe o progresso dos primeiros acessos de todo o time e tenha o apoio da copiloto no dia a dia.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesFirstMap-b2':
      return (
        {
          title: 'Reforçando a nossa dica',
          content: (
            <>
              <SoraBumperCover sora="Preocupada" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Reforçando a nossa dica
                </Title>
                <P>
                  Cada pessoa que você insere no time tem habilidades e experiências únicas. Para ampliar esses talentos, precisamos interconectá-los, seguindo a lógica da Lei de Metcalfe.
                </P>
                <P>
                  Para alcançar o resultado proposto pela Persora, é imprescindível que você adicione no mínimo 3 liderados, se não, corre-se o risco do time não passar de uma simples adição de indivíduos.
                </P>
                <P>
                  Fazendo isso, você desbloqueia uma nova funcionalidade gratuita que te permite saber como o time está e receber orientações práticas para melhorar.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesFirstMap-b3':
      return (
        {
          title: 'Convidei uma pessoa que não acessou a Persora. E agora?',
          content: (
            <>
              <SoraBumperCover sora="Pensativa2" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Convidei uma pessoa que não acessou a Persora. E agora?
                </Title>
                <P>
                  Calma. Se isso aconteceu com você, sugiro duas ações:
                </P>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>1️⃣</Emoji>
                    <P>
                      Estabeleça um prazo para o progresso dessa pessoa;
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>2️⃣</Emoji>
                    <P>
                      Reforce que a Persora é um ambiente seguro e independente entre você e seu time.
                    </P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Acompanhe o primeiro acesso de todo o time e tenha o apoio da Persora no dia a dia.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-invitesFirstMap-b4':
      return (
        {
          title: 'Atualização sobre o seu time',
          content: (
            <>
              <SoraBumperCover sora="Chateada" bg={4} shape={3} />
              <BumperContent>
                <Title>
                  As pessoas a seguir ainda não realizaram o mapeamento do estilo na Persora:
                </Title>
                <P dangerouslySetInnerHTML={
                { __html: firstCustomContent }
            }
                />
                <P>
                  Aqui está uma sugestão de mensagem para reforçar sobre a importância dessa etapa (envie individualmente):
                </P>
                <BlueContainer>
                  <P>
                    Já te enviei o convite da Persora, aquela ferramenta que pode nos apoiar na gestão do time. Queria que você também participasse para avaliarmos se é aplicável para o nosso dia a dia. Por favor, dá uma olhada no seu e-mail e baixa o app, o primeiro acesso é bem rápido.
                  </P>
                </BlueContainer>
                <P>
                  Quando pelo menos 3 liderados completarem o primeiro acesso, você desbloqueará a funcionalidade que te permite saber como o time está e o que fazer para melhorar.
                </P>
                <P>
                  Acompanhe o primeiro acesso de todo o time e tenha o apoio da copiloto no dia a dia.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    // shape 4
    case 'l-useActionButtonWithUser-b1':
      return (
        {
          title: 'Uma pessoa da sua equipe realizou o mapeamento de estilo',
          content: (
            <>
              <SoraBumperCover sora="Animada" bg={3} shape={1} />
              <BumperContent>
                <Title>
                  Uma pessoa da sua equipe realizou o mapeamento de estilo
                </Title>
                <P>
                  👋 Tenho uma boa notícia. Uma pessoa que você convidou para usar a Persora realizou o mapeamento de estilo e está disponível no seu time.
                </P>
                <P>
                  Uma pessoa que você convidou para usar a Persora realizou o mapeamento de estilo e está disponível no seu time.
                </P>
                <P>
                  Estou acessível no ambiente de ação, basta tocar na
                  {' '}
                  <b>varinha mágica</b>
                  {' '}
                  da barra inferior.
                </P>
                <Gif src={GifConfianca} width={280} alt="Gif Confiança" />
                <P>
                  A principal habilidade de uma liderança é a comunicação e você não está sozinho.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-useActionButtonWithUser-b2':
      return (
        {
          title: '📆 Com quem do time você tem agenda individual hoje?',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  📆 Com quem do time você tem agenda individual hoje?
                </Title>
                <P>
                  A forma como você se comunica com sua equipe influencia significativamente os resultados do time.
                </P>
                <P>
                  A Persora personaliza sugestões de como se comunicar com cada pessoa do seu time em e-mails/mensagens, reuniões, feedbacks, conversas difíceis, construção de confiança e etc.
                </P>
                <Gif src={GifReuniao} width={280} alt="Gif Reunião" />
                <P>
                  Acesse o ambiente de ação tocando na
                  {' '}
                  <b>varinha mágica</b>
                  {' '}
                  da barra inferior e veja como interagir de forma assertiva em uma reunião com alguém do time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-useActionButtonWithUser-b3':
      return (
        {
          title: 'Veja como foi a experiência do Felipe com a Persora',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Veja como foi a experiência do Felipe com a Persora
                </Title>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    Somos full home office e o contato com o time tende a ser mais robótico. A Persora tornou simples a comunicação com cada liderado em diversas situações, como feedbacks e reuniões. Isso tem sido um grande diferencial na minha liderança.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Felipe} alt="Felipe Shimada" />
                    <Nome>
                      <b>Felipe Shimada</b>
                      <br />
                      CTO na Exent
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <Gif src={GifProdutividade} width={280} alt="Gif Produtividade" />
                <P>
                  Um líder eficaz se comunica com clareza e empatia, transformando palavras em ação coletiva. Acesse o ambiente de ação tocando na varinha mágica da barra inferior e veja como interagir com cada pessoa do time.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-useActionButtonWithUser-b4':
      return (
        {
          title: '📆 Com quem será a sua próxima conversa difícil?',
          content: (
            <>
              <SoraBumperCover sora="Pensativa2" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  📆 Com quem será a sua próxima conversa difícil?
                </Title>
                <P>
                  Prepare-se antecipadamente. Utilize as orientações personalizadas fornecidas pela sua copiloto para uma comunicação assertiva.
                </P>
                <BlueContainer>
                  <P className="mb-3">
                    Acesse o ambiente de ação tocando na varinha mágica da barra inferior:
                  </P>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a
                      {' '}
                      <b>situação</b>
                      {' '}
                      que precisa de ajuda
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a pessoa do time
                      {' '}
                      <b>com quem</b>
                      {' '}
                      terá a interação
                    </P>
                  </EmojiItem>
                  <Gif src={GifConversaDificil} width={280} alt="Gif Conversa Difícil" />
                </BlueContainer>
                <P>
                  Não adiar conversas importantes com sua equipe é essencial para manter um ambiente de trabalho saudável e produtivo. 😉
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useApp-b1':
      return (
        {
          title: '👋 Você pode ser mais assertivo na comunicação com o time',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Tenho uma boa notícia
                </Title>
                <P>
                  Você já pode usar a copiloto para se comunicar de forma assertiva com o seu time, incluindo a sua liderança.
                </P>
                <P>
                  A copiloto personaliza sugestões de como se comunicar com cada pessoa em reuniões, e-mails/mensagens, feedbacks, conversas difíceis, construção de confiança e etc.
                </P>
                <Gif src={GifComunicacao} width={280} alt="Gif Comunicação" />
                <P>
                  Acesse o ambiente de ação tocando na
                  {' '}
                  <b>varinha mágica</b>
                  {' '}
                  da barra inferior e veja como interagir com cada pessoa do time (inclusive a sua liderança). 😊
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useApp-b2':
      return (
        {
          title: 'Sua comunicação influencia significativamente no seu bem estar e nos resultados do time',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={5} shape={3} />
              <BumperContent>
                <Title>
                  Sua comunicação influencia significativamente no seu bem estar e nos resultados do time
                </Title>
                <BlueContainer>
                  <P>
                    Toque na
                    {' '}
                    <b>varinha mágica</b>
                    {' '}
                    na barra inferior do App.
                  </P>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a pessoa do time
                      {' '}
                      <b>com quem</b>
                      {' '}
                      terá a interação
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha o tipo de
                      {' '}
                      <b>ação</b>
                      {' '}
                      e solicite ajuda personalizada
                    </P>
                  </EmojiItem>
                  <Gif src={GifPontosFortes} width={280} alt="Gif Pontos Fortes" />
                </BlueContainer>
                <P>
                  Utilize as minhas orientações personalizadas para uma comunicação mais efetiva. 😊
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useApp-b3':
      return (
        {
          title: 'Quer dar um feedback para a sua liderança?',
          content: (
            <>
              <SoraBumperCover sora="Pensativa2" bg={4} shape={3} />
              <BumperContent>
                <Title>
                  Quer dar um feedback para a sua liderança?
                </Title>
                <P>
                  E se antes de dar um feedback para a sua liderança você pudesse acessar a melhor maneira de conduzir essa conversa?
                </P>
                <BlueContainer>
                  <p className="mb-3">Por exemplo:</p>
                  <Depoimento>
                    Lembre-se de manter um tom de voz calmo com o João, evite pressioná-lo para tomar decisões imediatas e dê espaço para reflexão antes de fazer perguntas[...]
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                    <Nome>
                      <b>Sora</b>
                      <br />
                      Copiloto
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  <b>Acesse o ambiente de ação tocando na varinha mágica,</b>
                  {' '}
                  na barra inferior do app. Basta selecionar a situação que precisa de ajuda, escolher a pessoa do time, e eu fornecerei as orientações personalizadas para te apoiar.
                </P>
                <Gif src={GifConversaDificil} width={280} alt="Gif Conversa Difícil" />
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useApp-b4':
      return (
        {
          title: 'Sua comunicação molda a percepção sobre você no time',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Sua comunicação molda a percepção sobre você no time
                </Title>
                <P>
                  No universo tech, as reuniões não produtivas podem ter um impacto particularmente acentuado, dada a rápida evolução do setor e a importância das entregas no prazo.
                </P>
                <P>
                  Quando a comunicação é falha, será necessário mais interrupções, o que dificulta a manutenção do foco e aprofundamento em tarefas complexas.
                </P>
                <BlueContainer className="pt-4">
                  <Gif src={GifReuniao} width={280} alt="Gif Reunião" />
                  <P className="pt-2 mb-3">
                    Acesse o ambiente de ação tocando na
                    {' '}
                    <b>varinha mágica</b>
                    {' '}
                    da barra inferior:
                  </P>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a
                      {' '}
                      <b>situação</b>
                      {' '}
                      que precisa de ajuda
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a pessoa do time
                      {' '}
                      <b>com quem</b>
                      {' '}
                      terá a interação
                    </P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Uma boa comunicação otimiza o fluxo de trabalho do time. 😉
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useActionButton-b1':
      return (
        {
          title: '🗣 Como você se comunica com seus pares?',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  🗣 Como você se comunica com seus pares?
                </Title>
                <P>
                  Em ambientes técnicos, a informação crucial muitas vezes se isola em silos, prejudicando a colaboração e o avanço dos projetos. Assim, uma comunicação clara, eficiente e personalizada é essencial para o sucesso desses projetos.
                </P>
                <BlueContainer>
                  <P className="pt-2 mb-3">
                    Acesse o ambiente de ação tocando na
                    {' '}
                    <b>varinha mágica</b>
                    {' '}
                    da barra inferior:
                  </P>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a
                      {' '}
                      <b>situação</b>
                      {' '}
                      que precisa de ajuda
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a pessoa do time
                      {' '}
                      <b>com quem</b>
                      {' '}
                      terá a interação
                    </P>
                  </EmojiItem>
                  <Gif src={GifComunicacao} width={280} alt="Gif Comunicação" />
                </BlueContainer>
                <P>
                  Você vai otimizar o fluxo de trabalho do time. 😉
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useActionButton-b2':
      return (
        {
          title: ' 🔎 Interagindo de forma personalizada',
          content: (
            <>
              <SoraBumperCover sora="OlhosCarinhosos" bg={4} shape={4} />
              <BumperContent>
                <Title>
                  🔎 Interagindo de forma personalizada
                </Title>
                <P>
                  Em times tech, divergências técnicas, de prioridades e soluções são normais, mas sem comunicação eficaz, podem levar a conflitos que prejudicam o moral e a produtividade da equipe. Portanto, é vital manter uma comunicação clara, eficiente e personalizada.
                </P>
                <BlueContainer>
                  <P className="pt-2 mb-3">
                    Acesse o ambiente de ação tocando na
                    {' '}
                    <b>varinha mágica</b>
                    {' '}
                    da barra inferior:
                  </P>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a
                      {' '}
                      <b>situação</b>
                      {' '}
                      que precisa de ajuda
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a pessoa do time
                      {' '}
                      <b>com quem</b>
                      {' '}
                      terá a interação
                    </P>
                  </EmojiItem>
                  <Gif src={GifConfianca} width={280} alt="Gif Confiança" />
                </BlueContainer>
                <P>
                  A comunicação é o coração de um time; sem ela, os objetivos ficam inatingíveis. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useActionButton-b3':
      return (
        {
          title: 'Quer dar um feedback para a sua liderança?',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Quer dar um feedback para a sua liderança?
                </Title>
                <P>
                  E se antes de dar um feedback para a sua liderança você pudesse acessar a melhor maneira de conduzir essa conversa?
                </P>
                <BlueContainer>
                  <p className="mb-3">Por exemplo:</p>
                  <Depoimento>
                    Lembre-se de manter um tom de voz calmo com o João, evite pressioná-lo para tomar decisões imediatas e dê espaço para reflexão antes de fazer perguntas[...]
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                    <Nome>
                      <b>Sora</b>
                      <br />
                      Copiloto
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  <b>Acesse a Copiloto tocando na varinha mágica,</b>
                  {' '}
                  na barra inferior do app. Basta selecionar a situação que precisa de ajuda, escolher a pessoa do time, e eu fornecerei as orientações personalizadas para te apoiar.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-useActionButton-b4':
      return (
        {
          title: 'Sua comunicação com a equipe molda a percepção dos outros sobre você',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  Sua comunicação com a equipe molda a percepção dos outros sobre você
                </Title>
                <BlueContainer className="pt-4">
                  <P className="pt-2 mb-3">
                    Acesse o ambiente de ação tocando na
                    {' '}
                    <b>varinha mágica</b>
                    {' '}
                    da barra inferior:
                  </P>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a
                      {' '}
                      <b>situação</b>
                      {' '}
                      que precisa de ajuda
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>🔹</Emoji>
                    <P>
                      escolha a pessoa do time
                      {' '}
                      <b>com quem</b>
                      {' '}
                      terá a interação
                    </P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  A copiloto personaliza sugestões de como se comunicar em e-mails/mensagens, feedback, conversas difíceis, construção de confiança e etc.
                </P>
                <Gif src={GifConfianca} width={280} alt="Gif Confiança" />
                <P>
                  Experimente os efeitos de aplicar as orientações personalizadas na sua comunicação. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-enablePip-b1':
    case 'l-enablePip-b2':
      return (
        {
          title: 'Última chance de utilizar um novo recurso da Copiloto ainda nesta semana!',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Última chance de utilizar um novo recurso da Copiloto ainda nesta semana!
                </Title>
                <P>
                  <b>Como habilitar:</b>
                  <br />
                  Para habilitar a PIP, vá até a tela
                  {' '}
                  <b>Indicadores</b>
                  ,
                  {' '}
                  <b>expanda</b>
                  {' '}
                  o card da
                  {' '}
                  <b>PIP</b>
                  {' '}
                  e acesse a aba
                  {' '}
                  <b>Agendamento</b>
                  . 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-openPipActionPlan-b1':
      return (
        {
          title: 'Ao assumir uma liderança, naturalmente você deseja que a gestão do seu time seja o mais fluida possível.',
          content: (
            <>
              <SoraBumperCover sora="Pensativa1" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Ao assumir uma liderança, naturalmente você deseja que a gestão do seu time seja o mais fluida possível.
                </Title>
                <P>
                  Com base na PIP, a Sora personaliza o plano de ação com
                  {' '}
                  <b>&quot;o que fazer&quot;</b>
                  {' '}
                  para dar suporte efetivo à sua equipe.
                </P>
                <P>
                  Na tela de
                  {' '}
                  <b style={{ display: 'inline-block' }}>
                    <Cardiology />
                    {' '}
                    Indicadores
                  </b>
                  ,
                  {' '}
                  <b>expanda</b>
                  {' '}
                  o card da
                  {' '}
                  <b>PIP</b>
                  {' '}
                  para acessar a aba de resultados e identificar o ponto mais crítico. Acesse
                  {' '}
                  <b>&quot;Ver plano de ação&quot;</b>
                  {' '}
                  e aplique com a sua equipe. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-openPipActionPlan-b2':
      return (
        {
          title: 'Quando um time percebe que está sendo ouvido?',
          content: (
            <>
              <SoraBumperCover sora="Pensativa2" bg={3} shape={2} />
              <BumperContent>
                <Title>
                  Quando um time percebe que está sendo ouvido?
                </Title>
                <P>
                  Ao implementar o Plano de Ação baseado nos resultados da PIP, você não apenas mostra ao seu time que está comprometido em ser um líder melhor, mas também que está atento ao ambiente de trabalho e ao grupo. Isso reforça a importância da comunicação aberta, fortalecendo a confiança e o engajamento da equipe.
                </P>
                <P>
                  <b>
                    Como acessar o meu plano de ação?
                  </b>
                </P>
                <P>
                  Na tela de
                  {' '}
                  <b style={{ display: 'inline-block' }}>
                    <Cardiology />
                    {' '}
                    Indicadores
                  </b>
                  ,
                  {' '}
                  <b>expanda</b>
                  {' '}
                  o card da
                  {' '}
                  <b>PIP</b>
                  {' '}
                  para acessar a aba de resultados e identificar o ponto mais crítico. Acesse
                  {' '}
                  <b>&quot;Ver plano de ação&quot;</b>
                  {' '}
                  e aplique com a sua equipe. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-openPipActionPlan-b3':
      return (
        {
          title: 'Com a Persora, ganhei mais respeito...',
          content: (
            <>
              <SoraBumperCover sora="Nerd" bg={2} shape={4} />
              <BumperContent>
                <Title>
                  &quot;…com a Persora, ganhei mais respeito.&quot;
                </Title>
                <P>
                  Assumir uma liderança envolve aprender a motivar, transmitir conhecimento, acompanhar a evolução das pessoas e resolver conflitos, entre outras responsabilidades significativas. Definitivamente, não é uma tarefa fácil.
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    As ações aplicadas impactam na minha liderança positivamente. Antes eu tentava ser uma líder &apos;boazinha&apos;, mas com a Persora, ganhei mais respeito. No contexto geral recomendo fortemente.
                  </Depoimento>
                  <DepoimentoFooter>
                    <Nome>
                      <b>Danielli Garcia</b>
                      <br />
                      Líder Comercial
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  <b>
                    Você sabe como ser um líder melhor?
                  </b>
                </P>
                <P>
                  Vá até a tela de
                  {' '}
                  <b style={{ display: 'inline-block' }}>
                    <Cardiology />
                    {' '}
                    Indicadores
                  </b>
                  ,
                  {' '}
                  <b>expanda</b>
                  {' '}
                  o card da
                  {' '}
                  <b>PIP</b>
                  {' '}
                  para acessar a aba de resultados e identificar o ponto mais crítico. Acesse
                  {' '}
                  <b>&quot;Ver plano de ação&quot;</b>
                  {' '}
                  e aplique com a sua equipe. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-answerPip-b1':
      return (
        {
          title: 'Seu prazo está acabando…',
          content: (
            <>
              <SoraBumperCover sora="Chateada" bg={4} shape={3} />
              <BumperContent>
                <Title>
                  Seu prazo está acabando…
                </Title>
                <P>
                  A PIP quinzenal do seu time fecha hoje e você ainda não respondeu.
                </P>
                <P>
                  Leva menos de 1 minuto para responder, valorize a sua voz no time! 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'c-answerPip-b2':
      return (
        {
          title: 'Poxa, a PIP quinzenal está quase fechando e você ainda não contribuiu com o seu time.',
          content: (
            <>
              <SoraBumperCover sora="Triste" bg={4} shape={3} />
              <BumperContent>
                <Title>
                  Poxa, a PIP quinzenal está quase fechando e você ainda não contribuiu com o seu time.
                </Title>
                <P>
                  Embora a PIP possa parecer mais do mesmo, ela tem uma dinâmica bem diferente. Mas, só teremos resultado se atingirmos o mínimo de respostas efetivas.
                </P>
                <P>
                  Não fique de fora, ainda dá tempo de responder! Leva menos de 1 minuto. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-detached-b1':
      return (
        {
          title: 'Como líder, você já se sentiu assim?',
          content: (
            <>
              <SoraBumperCover sora="OlhosSuplicantes" bg={4} shape={5} />
              <BumperContent>
                <Title>
                  Como líder, você já se sentiu assim?
                </Title>
                <BlueContainer>
                  <EmojiItem>
                    <Emoji>
                      🤔
                    </Emoji>
                    <P>
                      Inseguro após fazer um bom curso de liderança, mas perceber que a teoria era genérica e difícil de aplicar no dia a dia.
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>
                      😬
                    </Emoji>
                    <P>
                      Ansioso por não ter certeza se está fazendo um bom trabalho como líder.
                    </P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>
                      😨
                    </Emoji>
                    <P>
                      Com medo de errar e acabar prejudicando o time.
                    </P>
                  </EmojiItem>
                </BlueContainer>
                <P>
                  Adicionar o time na Persora é rápido e a copiloto te ajuda a liderar cada pessoa de forma prática.
                </P>
                <P>
                  Leva menos de 5 minutos para adicionar o time. Agora você conta com a Persora.
                </P>
                <P>
                  Sora.
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-detached-b2':
      return (
        {
          title: 'Liberamos uma nova funcionalidade para você! 🎉',
          content: (
            <>
              <SoraBumperCover sora="OlhosSuplicantes" bg={4} shape={5} />
              <BumperContent>
                <Title>
                  Liberamos uma nova funcionalidade para você! 🎉
                </Title>
                <P>Já se perguntou se está sendo um líder efetivo? 🤔</P>
                <P>
                  Agora você pode adicionar todo o seu time gratuitamente na Persora para ativar essa nova
                  {' '}
                  <i>feature</i>
                  .
                </P>
                <P>Com isso, você vai ter uma visão clara de como o time está e o que pode fazer para melhorar.</P>
                <P>Ao incluir todo o time, você promove uma gestão compartilhada.</P>
                <P>Liderar fica muito mais leve quando você tem seu time e a Persora ao seu lado. 🙌</P>
                <P>Sora</P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-detached-b3':
      return (
        {
          title: 'Acabamos de liberar até 20 usuários gratuitos no seu workspace. 🎉',
          content: (
            <>
              <SoraBumperCover sora="Deslumbrada" bg={1} shape={4} />
              <BumperContent>
                <Title>
                  Acabamos de liberar até 20 usuários gratuitos no seu workspace. 🎉
                </Title>
                <P>
                  Como líder, já sentiu aquele aperto de estar
                  {' '}
                  <b>afogado nos desafios com pessoas</b>
                  ?
                </P>
                <P>
                  Isso geralmente acontece quando não conseguimos nos comunicar do jeito que cada pessoa entende. Mas calma, a copiloto está aqui para te ajudar a melhorar essa comunicação com o seu time!
                </P>
                <P>
                  Isso vai transformar seus feedbacks, suas one-on-ones e vai te ajudar a criar um ambiente onde todos se sintam motivados a dar o seu melhor.
                </P>
                <P>
                  <b>
                    Adicione todo o seu time ao seu workspace
                  </b>
                  {' '}
                  e ganhe um canal seguro e independente para desenvolver sua liderança.
                </P>
                <P>
                  Liderar pode ser desafiador, mas agora você tem a Persora ao seu lado.
                </P>
                <P>Sora 💜</P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-detached-b4':
      return (
        {
          title: 'Acabamos de liberar até 20 usuários gratuitos no seu workspace. 🎉',
          content: (
            <>
              <SoraBumperCover sora="Confusa" bg={4} shape={2} />
              <BumperContent>
                <P className="mt-3">
                  Sinta-se mais seguro(a)!
                </P>
                <Title>
                  Antes de uma conversa difícil com alguém do time, acesse a Persora para uma comunicação mais assertiva.
                </Title>

                <BlueContainer>
                  <P className="mb-3 bold">
                    É muito simples:
                  </P>
                  <EmojiItem>
                    <Emoji>1️⃣</Emoji>
                    <P>Acesse o botão flutuante.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>2️⃣</Emoji>
                    <P>Selecione a pessoa com quem vai ter essa conversa.</P>
                  </EmojiItem>
                  <EmojiItem>
                    <Emoji>3️⃣</Emoji>
                    <P>Escolha a opção &quot;Conversa Difícil&quot;.</P>
                  </EmojiItem>
                </BlueContainer>
                <P>A copiloto vai te mostrar os cuidados essenciais para conduzir o papo com segurança.</P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-backTo3LedUsers-b1':
      return (
        {
          title: '👋 A PIP do seu workspace foi desabilitada',
          content: (
            <>
              <SoraBumperCover sora="Triste" bg={4} shape={2} />
              <BumperContent>
                <Title>
                  👋 A PIP do seu workspace foi desabilitada
                </Title>
                <P>
                  Notamos que o seu workspace está com menos de 3 liderados, e por isso a PIP foi desabilitada.
                </P>
                <P>
                  Para reativar a PIP, é necessário ter no mínimo 3 liderados no seu workspace.
                </P>
                <P>
                  A PIP é uma ferramenta essencial para ajudar lideranças a entenderem como o time está e acessarem planos de ação personalizados.
                </P>
                <P>
                  Sem ela, se torna mais desafiador obter informações claras e gerir a equipe de forma eficiente. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    case 'l-backTo3LedUsers-b2':
      return (
        {
          title: '👋 A PIP do seu workspace ainda está desabilitada',
          content: (
            <>
              <SoraBumperCover sora="Triste" bg={4} shape={2} />
              <BumperContent>
                <Title>
                  👋 A PIP do seu workspace ainda está desabilitada
                </Title>
                <P>
                  Notamos que o seu workspace ainda está com menos de 3 liderados, e por isso a PIP continua desabilitada. Para reativar a PIP, é necessário ter no mínimo 3 liderados.
                </P>
                <BlueContainer>
                  <Depoimento>
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <Star1 />
                    <br />
                    O momento mais marcante foi ao receber o resultado da primeira PIP do time, que apontava: &quot;🚨 Parte do time sente que suas melhores competências estão sendo subutilizadas&quot;. Isso me fez pensar imediatamente: preciso agir, meu time precisa ser bem aproveitado!
                  </Depoimento>
                  <DepoimentoFooter>
                    <Foto src={Pedro} alt="Pedro Faro" />
                    <Nome>
                      <b>Pedro Faro</b>
                      <br />
                      CTO da Takeat
                    </Nome>
                  </DepoimentoFooter>
                </BlueContainer>
                <P>
                  A PIP é uma ferramenta essencial para ajudar lideranças a entenderem como o time está e acessarem planos de ação personalizados. Sem ela, se torna mais desafiador obter informações claras e gerir a equipe de forma eficiente. 🙌
                </P>
              </BumperContent>
            </>
          ),
        }
      );
    default:
      return (
        {
          title: 'Bumper não encontrado!',
          content: (
            <>
              <SoraBumperCover />
              <BumperContent>
                <Title>
                  Bumper não encontrado!
                </Title>
              </BumperContent>
            </>
          ),
        }
      );
  }
};
