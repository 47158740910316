import {
  useState, useContext,
} from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

// import ReactGA from 'react-ga4';
// import mixpanel from 'mixpanel-browser';

import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import {
  Col, Container, Row,
} from 'react-bootstrap';

import { Sheet } from 'react-modal-sheet';
// import Comemorando from '../../assets/img/Sora/OlhosCarinhosos.svg';
// import SoraAvatar from '../../assets/img/sora-avatar.svg';
// import SoraDisclaimer from '../../assets/img/SoraFace/NeutraUserData.svg';
import SoraDescontraida from '../../assets/img/SoraV2/Rosto/Descontraida.svg';
import SoraReflexao from '../../assets/img/SoraV2/Rosto/Reflexao.svg';
import SoraLeader from '../../assets/img/soraLeader.svg';
// import SoraBustoExtase from '../../assets/img/SoraV2/BustoCortado/Extase.svg';
import SoraBustoAdmiracao from '../../assets/img/SoraV2/BustoCortado/Admiracao.svg';
import api from '../../services/backend';
import PersoraButton from '../Button';
import { UserContext } from '../../Context/UserContext';

import {
  HeaderProgressBar,
  OnboardingBody,
  OnboardingContainer,
  OnboardingFooter,
  OnboardingHeader,
} from '../FirstMap/styles';
import defaultTheme from '../../assets/styles/themes/default';
import {
  ChevronLeft,
  Clock,
} from '../DuotoneIcon';
import { Foto, SoraSpeech, SpeechBubble } from '../../pages/Sheets/SoraCopilotV2';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';

export default function UpdateSecondLeaderData({ user }) {
  // const history = useHistory();
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [firstMapRateSheetIsOpen, setFirstMapRateSheetOpen] = useState(true);
  // const [userName, setUserName] = useState(user.name ? user.name : '');
  // Quando você assumiu sua primeira liderança? ====================================>
  const [firstTimeLeader, setFirstTimeLeader] = useState(user.settings.firstTimeLeader ? user.settings.firstTimeLeader : '');
  const firstTimeLeaderOptions = [
    { label: '< 1990', value: '<1990' },
    { label: '1990-2000', value: '1990-2000' },
    { label: '2001-2010', value: '2001-2010' },
    { label: '2011-2020', value: '2011-2020' },
    { label: '2021-2022', value: '2021-2022' },
    { label: '2023-2024', value: '2023-2024' },
  ];
  // Quantas pessoas você lidera diretamente? ==============================================>
  const [ledNumber, setLedNumber] = useState(user.settings.ledNumber ? user.settings.ledNumber : ''); // user
  const ledNumberOptions = [
    { label: '< 3', value: '<3' },
    { label: '3-4', value: '3-4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8-12', value: '8-12' },
    { label: '13-16', value: '13-16' },
    { label: '17-20', value: '17-20' },
    { label: '> 20', value: '>20' },
  ];

  const [currentStep, setCurrentStep] = useState(1); // 1, 2 ou 3

  const handlePrevStep = async () => {
    toast.dismiss();
    if (currentStep === 2) {
      setCurrentStep(1);
    }
    window.scrollTo(0, 0);
  };

  const handleNextStep = async () => {
    toast.dismiss();
    if (currentStep === 1) {
      setIsLoading(true);
      try {
        const body = {
          firstTimeLeader,
        };
        await api.patch('/user/updateSettings', body);
        setCurrentUserContextData({
          ...currentUserContextData,
          settings: {
            ...currentUserContextData.settings,
            firstTimeLeader,
          },
        });
        setCurrentStep(2);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    if (currentStep === 2) {
      setIsLoading(true);
      try {
        const body = {
          ledNumber,
        };
        const bodyDisclaimer = {
          showOnboardingOnTypeChange: false,
        };
        await api.patch('/user/updateSettings', body);
        await api.patch('/user/disclaimer', bodyDisclaimer);
        setCurrentUserContextData({
          ...currentUserContextData,
          settings: {
            ...currentUserContextData.settings,
            ledNumber,
          },
          disclaimers: {
            ...currentUserContextData.disclaimers,
            showOnboardingOnTypeChange: false,
          },
        });
        Swal.fire({
          html: `
          <img src=${SoraBustoAdmiracao} alt='Sora Nerd' class='mb-3'/><br/>
          <h4 class="sourcesans semibold">Valeu!</h4>
          Você está contribuindo com o desenvolvimento da Persora.
        `,
          showCancelButton: false,
          confirmButtonColor: defaultTheme.colors.primary.main,
          showConfirmButton: false,
          showCloseButton: true,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }

    window.scrollTo(0, 0);
  };

  if (currentStep === 1) {
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px', opacity: (currentStep === 1 ? 0.5 : 1) }}
            disabled={currentStep === 1}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="50%" />
        </OnboardingHeader>
        <OnboardingBody>
          <Container className="h-100">
            <Row className="h-100">
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div
                  className="w-100"
                  style={{
                    display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column',
                  }}
                >
                  <div>
                    <SoraSpeech className="mt-0">
                      <Foto src={SoraReflexao} alt="Sora - Copiloto" />
                      <SpeechBubble>
                        Quando você assumiu sua primeira liderança?
                      </SpeechBubble>
                    </SoraSpeech>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', gap: '12px', width: '100%', justifyContent: 'center',
                    }}
                    >
                      {firstTimeLeaderOptions.map((option) => (
                        <PersoraButton
                          estilo="outline"
                          tamanho="condensed"
                          className={`px-3 text-start ${firstTimeLeader === option.value && 'selected'} ${firstTimeLeader !== '' && firstTimeLeader !== option.value && 'not-selected'}`}
                          onClick={() => setFirstTimeLeader(option.value)}
                          key={option.value}
                        >
                          {option.label}
                        </PersoraButton>
                      ))}
                    </div>
                  </div>
                  <p className="mt-4 mb-3">Essas informações ajudam no desenvolvimento da Persora.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <OnboardingFooter>
          <PersoraButton
            tamanho="default"
            disabled={isLoading || !firstTimeLeader}
            onClick={handleNextStep}
          >
            {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Próximo'}
          </PersoraButton>
        </OnboardingFooter>
        <Sheet
          isOpen={firstMapRateSheetIsOpen}
          onClose={() => setFirstMapRateSheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableDrag={!firstMapRateSheetIsOpen}
        >
          <Sheet.Container>
            <Sheet.Header style={{ textAlign: 'center' }}>
              <img src={SoraLeader} alt="Sora - Copiloto" className="mb-3 mt-4" style={{ width: '80px' }} />
            </Sheet.Header>
            <Sheet.Content>
              <Sheet.Scroller>
                <div
                  style={{
                    padding: defaultTheme.spaces.screenEdge,
                    paddingTop: 0,
                    color: defaultTheme.colors.primary.main,
                    textAlign: 'center',
                  }}
                >
                  <h3 className="sourcesans">
                    Você agora tem permissão de
                    <br />
                    {' '}
                    líder neste workspace!
                  </h3>
                  <p className="mb-2" style={{ fontSize: '1.1rem' }}>
                    Alinhe responsabilidades e expectativas com a liderança existente
                    {' '}
                    para fortalecer a colaboração e impulsionar os resultados do time.
                  </p>
                  <p className="mb-4" style={{ fontSize: '1.1rem' }}>
                    Queremos conhecer seu contexto para melhorar a
                    {' '}
                    sua experiência. É bem rápido, prometo.
                  </p>
                  <PersoraButton
                    tamanho="default"
                    onClick={() => setFirstMapRateSheetOpen(false)}
                    className="mb-4"
                  >
                    Vamos lá!
                  </PersoraButton>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setFirstMapRateSheetOpen(false)} />
        </Sheet>
      </OnboardingContainer>
    );
  }

  // TODO REMOVIDO STEP 2

  // TODO REMOVIDO STEP 3

  if (currentStep === 2) {
    return (
      <OnboardingContainer>
        <OnboardingHeader>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handlePrevStep}
            style={{ marginLeft: '-10px' }}
          >
            <ChevronLeft />
          </PersoraButton>
          <HeaderProgressBar percentual="100%" />
        </OnboardingHeader>
        <OnboardingBody>
          <Container className="h-100">
            <Row className="h-100">
              <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start pt-4 px-0">
                <div
                  className="w-100"
                  style={{
                    display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column',
                  }}
                >
                  <div>
                    <SoraSpeech className="mt-0">
                      <Foto src={SoraDescontraida} alt="Sora - Copiloto" />
                      <SpeechBubble>
                        Quantas pessoas você lidera diretamente?
                      </SpeechBubble>
                    </SoraSpeech>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap', gap: '12px', width: '100%', justifyContent: 'center',
                    }}
                    >
                      {ledNumberOptions.map((option) => (
                        <PersoraButton
                          estilo="outline"
                          tamanho="condensed"
                          className={`px-3 text-start ${ledNumber === option.value && 'selected'} ${ledNumber !== '' && ledNumber !== option.value && 'not-selected'}`}
                          onClick={() => setLedNumber(option.value)}
                          key={option.value}
                        >
                          {option.label}
                        </PersoraButton>
                      ))}
                    </div>
                  </div>
                  <p className="mt-4 mb-3">Essas informações ajudam no desenvolvimento da Persora.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </OnboardingBody>
        <OnboardingFooter>
          <PersoraButton
            tamanho="default"
            disabled={isLoading || !ledNumber}
            onClick={handleNextStep}
          >
            {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar respostas'}
          </PersoraButton>
        </OnboardingFooter>
      </OnboardingContainer>
    );
  }
}

UpdateSecondLeaderData.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    owner: PropTypes.bool,
    firstGoal: PropTypes.string,
    name: PropTypes.string,
    workspaceContext: PropTypes.shape({
      segment: PropTypes.string,
      teamDepartment: PropTypes.string,
    }),
    settings: PropTypes.shape({
      pronouns: PropTypes.string,
      firstTimeLeader: PropTypes.string,
      teamDepartment: PropTypes.string,
      ledNumber: PropTypes.string,
      howMetPersora: PropTypes.string,
    }),
    profiles: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
    })),
  }).isRequired,
};
