import PropTypes from 'prop-types';
import { useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import {
  Col, Container, Ratio, Row,
} from 'react-bootstrap';
import defaultTheme from '../../../assets/styles/themes/default';
import profileContents from '../../../utils/profileContents';
// import profileContents from '../../../utils/profileContents';
// import coverPontosFortesCegos from '../../../assets/img/cover_PontosFortesCegos.svg';
// import coverMotivadoresEstressores from '../../../assets/img/cover_MotivadoresEstressores.svg';
// import coverEstiloLiderança from '../../../assets/img/cover_EstiloLideranca.svg';
// import coverInovacao from '../../../assets/img/cover_Inovacao.svg';
import PersoraCardButton from '../../CardButton';
import { replaceVoce } from '../../../utils/profileUtils';
// import PersoraButton from '../../Button';
// import { Cross } from '../../DuotoneIcon';

import coverInovacaoD from '../../../assets/img/forcas-desafios/d-inovacao.svg';
import coverInovacaoI from '../../../assets/img/forcas-desafios/i-inovacao.svg';
import coverInovacaoS from '../../../assets/img/forcas-desafios/s-inovacao.svg';
import coverInovacaoC from '../../../assets/img/forcas-desafios/c-inovacao.svg';
import coverMotivadoresEstressoresD from '../../../assets/img/forcas-desafios/d-motivadores-estressores.svg';
import coverMotivadoresEstressoresI from '../../../assets/img/forcas-desafios/i-motivadores-estressores.svg';
import coverMotivadoresEstressoresS from '../../../assets/img/forcas-desafios/s-motivadores-estressores.svg';
import coverMotivadoresEstressoresC from '../../../assets/img/forcas-desafios/c-motivadores-estressores.svg';
import coverLiderancaD from '../../../assets/img/forcas-desafios/d-lideranca.svg';
import coverLiderancaI from '../../../assets/img/forcas-desafios/i-lideranca.svg';
import coverLiderancaS from '../../../assets/img/forcas-desafios/s-lideranca.svg';
import coverLiderancaC from '../../../assets/img/forcas-desafios/c-lideranca.svg';
import converPontosFortesCegosD from '../../../assets/img/forcas-desafios/d-pontos-fortes-cegos.svg';
import converPontosFortesCegosI from '../../../assets/img/forcas-desafios/i-pontos-fortes-cegos.svg';
import converPontosFortesCegosS from '../../../assets/img/forcas-desafios/s-pontos-fortes-cegos.svg';
import converPontosFortesCegosC from '../../../assets/img/forcas-desafios/c-pontos-fortes-cegos.svg';

function getCoverImage(profileLetter, type) {
  const profileLetterUpperCase = profileLetter.toUpperCase();
  const profileLetterFirst = profileLetterUpperCase.charAt(0);
  const coverImages = {
    inovacao: {
      D: coverInovacaoD,
      I: coverInovacaoI,
      S: coverInovacaoS,
      C: coverInovacaoC,
    },
    motivadoresEstressores: {
      D: coverMotivadoresEstressoresD,
      I: coverMotivadoresEstressoresI,
      S: coverMotivadoresEstressoresS,
      C: coverMotivadoresEstressoresC,
    },
    lideranca: {
      D: coverLiderancaD,
      I: coverLiderancaI,
      S: coverLiderancaS,
      C: coverLiderancaC,
    },
    pontosFortesCegos: {
      D: converPontosFortesCegosD,
      I: converPontosFortesCegosI,
      S: converPontosFortesCegosS,
      C: converPontosFortesCegosC,
    },
  };
  return coverImages[type][profileLetterFirst];
}

export default function UserProfileStrengthsChallengesCards({
  lastProfile,
  userPronouns,
  firstName,
  aboutAnotherUser,
  ...props
}) {
  // const firstLetterProfile = lastProfile.displayedProfileLetters[0].toLowerCase();
  // const profilebackgroundcolor = defaultTheme.colors.disc[firstLetterProfile].light;
  const [pontosFortesCegosSheetIsOpen, setPontosFortesCegosSheetOpen] = useState(false);
  const [motivadoresEstressoresSheetIsOpen, setMotivadoresEstressoresSheetOpen] = useState(false);
  const [estiloLiderancaSheetIsOpen, setEstiloLiderancaSheetOpen] = useState(false);
  const [inovacaoSheetIsOpen, setInovacaoSheetOpen] = useState(false);

  return (
    <div {...props}>
      <h4 className="sourcesans mb-3 semibold">
        Forças e Desafios
      </h4>
      <Container>
        <Row style={{ gap: '16px', marginBottom: '20px' }}>
          <Col className="p-0">
            <PersoraCardButton
              imageCoverUrl={getCoverImage(lastProfile.displayedProfileLetters, 'pontosFortesCegos')}
              imageProportion="1x1"
              cardLabel="Seus pontos fortes <br/>e cegos"
              onClick={() => setPontosFortesCegosSheetOpen(true)}
            />
          </Col>
          <Col className="p-0">
            <PersoraCardButton
              imageCoverUrl={getCoverImage(lastProfile.displayedProfileLetters, 'motivadoresEstressores')}
              imageProportion="1x1"
              cardLabel="Seus motivadores <br/>e estressores"
              onClick={() => setMotivadoresEstressoresSheetOpen(true)}
            />
          </Col>
        </Row>
        <Row style={{ gap: '16px', marginBottom: '20px' }}>
          <Col className="p-0">
            <PersoraCardButton
              imageCoverUrl={getCoverImage(lastProfile.displayedProfileLetters, 'lideranca')}
              imageProportion="1x1"
              cardLabel="Seu estilo <br/>de liderança"
              onClick={() => setEstiloLiderancaSheetOpen(true)}
            />
          </Col>
          <Col className="p-0">
            <PersoraCardButton
              imageCoverUrl={getCoverImage(lastProfile.displayedProfileLetters, 'inovacao')}
              imageProportion="1x1"
              cardLabel="Seu estilo <br/>de inovação"
              onClick={() => setInovacaoSheetOpen(true)}
            />
          </Col>
        </Row>
      </Container>
      <Sheet
        isOpen={pontosFortesCegosSheetIsOpen}
        onClose={() => setPontosFortesCegosSheetOpen(false)}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!pontosFortesCegosSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  minHeight: 400,
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.main,
                }}
              >
                <h3 className="medium">Pontos fortes e cegos</h3>
                <Ratio aspectRatio="16x9" className="mt-4 mb-4">
                  <div style={{
                    backgroundImage: `url(${getCoverImage(lastProfile.displayedProfileLetters, 'pontosFortesCegos')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    borderRadius: '12px',
                  }}
                  />
                </Ratio>
                <div
                  dangerouslySetInnerHTML={{
                    __html: profileContents[lastProfile.profileLetters].pontos.resumo.neutral,
                  }}
                />
                {aboutAnotherUser
                  && (
                  <p>
                    Foque em aproveitar as forças de
                    {' '}
                    {firstName}
                    {' '}
                    e mitigar seus pontos cegos.
                  </p>
                  )}
                <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Forças 💪</h5>
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .pontos.forcas[userPronouns].map(
                      (forca) => (
                        <li key={forca}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: forca, name: firstName })
                            : forca}
                        </li>
                      ),
                    )}
                </ul>
                <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Pontos cegos 👀</h5>
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .pontos.cegos[userPronouns].map(
                      (pontoCego) => (
                        <li key={pontoCego}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: pontoCego, name: firstName })
                            : pontoCego}
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setPontosFortesCegosSheetOpen(false)} />
      </Sheet>

      <Sheet
        isOpen={motivadoresEstressoresSheetIsOpen}
        onClose={() => setMotivadoresEstressoresSheetOpen(false)}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!motivadoresEstressoresSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  minHeight: 400,
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.main,
                }}
              >
                <h3 className="medium">Motivadores e estressores</h3>
                <Ratio aspectRatio="16x9" className="mt-4 mb-4">
                  <div style={{
                    backgroundImage: `url(${getCoverImage(lastProfile.displayedProfileLetters, 'motivadoresEstressores')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    borderRadius: '12px',
                  }}
                  />
                </Ratio>
                <div
                  dangerouslySetInnerHTML={{
                    __html: profileContents[lastProfile.profileLetters].motEstr.resumo.neutral,
                  }}
                />
                {aboutAnotherUser
                  ? (
                    <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                      O que energiza
                      {' '}
                      {firstName}
                      {' '}
                      🌟
                    </h5>
                  )
                  : <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>O que te energiza 🌟</h5>}
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .motEstr.energiza[userPronouns].map(
                      (energiza) => (
                        <li key={energiza}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: energiza, name: firstName })
                            : energiza}
                        </li>
                      ),
                    )}
                </ul>
                {aboutAnotherUser
                  ? (
                    <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                      O que drena
                      {' '}
                      {firstName}
                      {' '}
                      🌪
                    </h5>
                  )
                  : <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>O que te drena 🌪</h5>}
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .motEstr.drena[userPronouns].map(
                      (drena) => (
                        <li key={drena}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: drena, name: firstName })
                            : drena}
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setMotivadoresEstressoresSheetOpen(false)} />
      </Sheet>

      <Sheet
        isOpen={estiloLiderancaSheetIsOpen}
        onClose={() => setEstiloLiderancaSheetOpen(false)}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!estiloLiderancaSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  minHeight: 400,
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.main,
                }}
              >
                <h3 className="medium">Estilo de liderança</h3>
                <Ratio aspectRatio="16x9" className="mt-4 mb-4">
                  <div style={{
                    backgroundImage: `url(${getCoverImage(lastProfile.displayedProfileLetters, 'lideranca')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    borderRadius: '12px',
                  }}
                  />
                </Ratio>
                {aboutAnotherUser
                  ? (
                    <>
                      <p>
                        O estilo de liderança compreende comportamentos que podem
                        {' '}
                        impulsionar a produtividade, engajamento e integração, ou prejudicar o
                        {' '}
                        trabalho, confiança e cooperação da equipe.
                      </p>
                      <p>
                        Ao conhecer mais sobre o estilo de liderança das pessoas
                        {' '}
                        é possível trabalhar melhor junto.
                      </p>
                    </>
                  )
                  : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profileContents[lastProfile.profileLetters]
                          .lideranca.resumo.neutral,
                      }}
                    />
                  )}
                <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                  {aboutAnotherUser ? 'Estilo de liderança 🔥' : 'Seu estilo de liderança 🔥'}
                </h5>
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .lideranca.estilo[userPronouns].map(
                      (estilo) => (
                        <li key={estilo}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: estilo, name: firstName })
                            : estilo}
                        </li>
                      ),
                    )}
                </ul>
                <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                  {aboutAnotherUser ? 'Pontos cegos do estilo de liderar 👀' : 'Pontos cegos do seu estilo de liderar 👀'}
                </h5>
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .lideranca.pontosCegos[userPronouns].map(
                      (pontoCego) => (
                        <li key={pontoCego}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: pontoCego, name: firstName })
                            : pontoCego}
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setEstiloLiderancaSheetOpen(false)} />
      </Sheet>

      <Sheet
        isOpen={inovacaoSheetIsOpen}
        onClose={() => setInovacaoSheetOpen(false)}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableDrag={!inovacaoSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  minHeight: 400,
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.main,
                }}
              >
                <h3 className="medium">Inovação</h3>
                <Ratio aspectRatio="16x9" className="mt-4 mb-4">
                  <div style={{
                    backgroundImage: `url(${getCoverImage(lastProfile.displayedProfileLetters, 'inovacao')})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    borderRadius: '12px',
                  }}
                  />
                </Ratio>
                {aboutAnotherUser
                  ? (
                    <p>
                      Tecnologia e inovação são conceitos que tomaram conta do
                      {' '}
                      mercado e se tornaram o motor do crescimento econômico.
                    </p>
                  )
                  : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profileContents[lastProfile.profileLetters]
                          .inovacao.resumo.neutral,
                      }}
                    />
                  )}
                <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                  {aboutAnotherUser ? `Como ${firstName} acelera a inovação 💡` : 'Como você acelera a inovação 💡'}
                </h5>
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .inovacao.comoAcelera.neutral.map(
                      (acelera) => (
                        <li key={acelera}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: acelera, name: firstName })
                            : acelera}
                        </li>
                      ),
                    )}
                </ul>
                <h5 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Suas dificuldades na inovação 👀</h5>
                <ul>
                  {profileContents[lastProfile.profileLetters]
                    .inovacao.dificuldades.neutral.map(
                      (dificuldade) => (
                        <li key={dificuldade}>
                          {aboutAnotherUser
                            ? replaceVoce({ text: dificuldade, name: firstName })
                            : dificuldade}
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setInovacaoSheetOpen(false)} />
      </Sheet>

    </div>
  );
}
UserProfileStrengthsChallengesCards.propTypes = {
  lastProfile: PropTypes.shape({
    profileLetters: PropTypes.string,
    displayedProfileLetters: PropTypes.string,
  }).isRequired,
  userPronouns: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  aboutAnotherUser: PropTypes.bool.isRequired,
};
