import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { Sheet } from 'react-modal-sheet';
import { useContext, useState } from 'react';
import {
  CardButtons, UserIcon, CardHeader, Container,
} from './styles';
import {
  ChevronSmallRight, Crown, Send2, Star2, Trash,
} from '../DuotoneIcon';
import UserTapBarIcon from '../UserTapBarIcon';
import PersoraTextButton from '../TextButton';
import ViewUserProfile from '../../pages/Sheets/ViewUserProfile';
import themeDefault from '../../assets/styles/themes/default';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { UserContext } from '../../Context/UserContext';

export default function UserCard({ userData, handleDeleteUser, handleResendInvite }) {
  const { currentUserContextData } = useContext(UserContext);
  // console.log('aqui', userData); // DEBUG
  let name = userData.email;
  const userProfileLetters = (userData.displayedProfileLetters ? userData.displayedProfileLetters : '');
  if (userData.name) {
    name = userData.name;
  }
  const [viewUserProfileSheetIsOpen, setViewUserProfileSheetOpen] = useState(false);
  const [userIdToViewUserProfile, setUserIdToViewUserProfile] = useState('');
  const [soraUserData, setSoraUserData] = useState(null);
  const handleViewUserProfile = ({ userId, soraUserFakeData }) => {
    if (soraUserFakeData) {
      setSoraUserData(soraUserFakeData);
    } else {
      setSoraUserData(null);
    }
    setUserIdToViewUserProfile(userId);
    setViewUserProfileSheetOpen(true);
  };

  return (
    <Container id={`${userData.name === 'Sora' ? 'thisIsSora' : ''}`} className={`my-2 mb-4 ${!userData.displayedProfileLetters ? 'firstAuthFalse' : ''} ${userData.name === 'Sora' ? 'thisIsSora' : ''}`}>
      {userData.type === 'Líder' ? <UserIcon><Crown cor="#E3A74F" /></UserIcon> : ''}
      {/* {userData.type !== 'Líder' && userData._id === currentUserContextData._id && (
      <UserIcon>
        <small className="semibold" style={{ color: themeDefault.colors.black40 }}>
          (você)
        </small>
        {' '}
        <Star1 cor={themeDefault.colors.secondary.dark} />
      </UserIcon>
      )} */}
      {userData.type === 'Sora' ? <UserIcon><Star2 cor={themeDefault.colors.secondary.dark} /></UserIcon> : ''}
      <CardHeader>
        <div><UserTapBarIcon name={name} perfil={userProfileLetters} showProfileLetters /></div>
        <div className="pt-2 ms-3">
          {!userData.firstAuth
            && (
            <>
              <h4 className="mb-0 medium">Aceite pendente</h4>
              <h6 className="mb-1">{userData.email}</h6>
            </>
            )}
          {userData.firstAuth && !userData.name
            && (
            <>
              <h4 className="mb-0 medium">Mapeamento pendente</h4>
              <h6 className="mb-1">{userData.email}</h6>
            </>
            )}
          {userData.firstAuth && userData.name && !userData.displayedProfileLetters
            && (
            <>
              <h4 className="mb-0 medium">{userData.name}</h4>
              <h6 className="mb-1">Mapeamento pendente</h6>
            </>
            )}
          {userData.displayedProfileLetters
            && (
            <>
              <h4 className="mb-0 medium">{userData.name}</h4>
              <h6 className="mb-1">{userData.settings.role}</h6>
            </>
            )}
        </div>
      </CardHeader>
      <CardButtons className={` ${!userData.displayedProfileLetters ? 'firstAuthFalse' : ''} `}>
        { userData.displayedProfileLetters
          ? (
            <div style={{ width: '100%' }}>
              <PersoraTextButton
                onClick={() => {
                  let userType;
                  if (userData._id === currentUserContextData._id) {
                    userType = 'Próprio';
                  } else if (userData.type === 'Colaborador') {
                    userType = 'Liderado';
                  } else {
                    userType = userData.type;
                  }
                  // console.log('Tipo de usuário selecionado => ', userType); // DEBUG
                  if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                    ReactGA.event({
                      category: 'Engajamento',
                      action: 'estilo_comportamental_aberto',
                      user_page: userType,
                    });
                    mixpanel.track('estilo_comportamental_aberto', {
                      category: 'Engajamento',
                      company_id: currentUserContextData._idWorkspace[0],
                      user_page: userType,
                    });
                  }
                  handleViewUserProfile({
                    userId: userData._id,
                    soraUserFakeData: (userData.type === 'Sora' ? userData : null),
                  });
                }}
                className="mb-0 small semibold"
                style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
              >
                <div>
                  Expandir
                  {' '}
                  {userData._id === currentUserContextData._id && <small style={{ opacity: '0.7' }}>(meu estilo)</small>}
                </div>
                <div style={{ marginRight: '-6px' }}>
                  <ChevronSmallRight tamanho={22} />
                </div>
              </PersoraTextButton>
            </div>
          )
          : (
            <>
              {currentUserContextData.type !== 'Líder'
                && (
                <div>
                  <p className="mb-0 small semibold">
                    <PersoraTextButton
                      onClick={() => console.log('Nada para fazer...')}
                      className="semibold"
                      style={{ cursor: 'default' }}
                    >
                      Usuário sem mapeamento
                    </PersoraTextButton>
                  </p>
                </div>
                )}
              <div className={currentUserContextData.type !== 'Líder' ? 'd-none' : ''}>
                <p className="mb-0 small semibold">
                  <PersoraTextButton
                    onClick={() => handleResendInvite({ userId: userData._id })}
                    className="semibold"
                    id={`resend-invite-${userData._id}`}
                  >
                    <Send2 tamanho={20} />
                    {' '}
                    Reenviar convite
                  </PersoraTextButton>
                </p>
              </div>
              <div className={currentUserContextData.type !== 'Líder' ? 'd-none' : ''}>
                <p className="mb-0 small">
                  <PersoraTextButton onClick={() => handleDeleteUser(userData._id)} className="semibold">
                    <Trash tamanho={20} />
                    {' '}
                    Excluir usuário
                  </PersoraTextButton>
                </p>
              </div>
            </>
          ) }
      </CardButtons>

      <Sheet
        isOpen={viewUserProfileSheetIsOpen}
        onClose={() => setViewUserProfileSheetOpen(false)}
        onOpenEnd={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        disableDrag={!viewUserProfileSheetIsOpen}
      >
        <ViewUserProfile
          userIdToViewUserProfile={userIdToViewUserProfile}
          soraUserData={soraUserData}
        />
        <Sheet.Backdrop onTap={() => setViewUserProfileSheetOpen(false)} />
      </Sheet>

    </Container>
  );
}

UserCard.propTypes = {
  userData: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    displayedProfileLetters: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    firstAuth: PropTypes.bool,
    settings: PropTypes.shape({
      role: PropTypes.string,
    }),
  }).isRequired,
  handleDeleteUser: PropTypes.func,
  handleResendInvite: PropTypes.func,
};
UserCard.defaultProps = {
  handleDeleteUser: () => {},
  handleResendInvite: () => {},
};
