// função para remover trecho específico de uma string
export const removeSubstring = (string, substring) => string.replace(substring, '');

// função para checar se parte de uma string existe
export const checkSubstring = (string, substring) => string.includes(substring);

// função para limitar o tamanho de uma string e adicionar '...' no final
export const limitString = (string, limit) => {
  if (string.length > limit) {
    return `${string.substring(0, limit)}...`;
  }
  return string;
};

export const abreviateName = (name) => {
  const names = name.split(' ');
  const exceptions = ['de', 'da', 'De', 'Da', 'DE', 'DA'];

  if (names.length <= 2) {
    return name;
  }

  return `${names[0]} ${names.slice(1, -1).map((n) => (exceptions.includes(n) ? n : `${n.charAt(0)}.`)).join(' ')} ${names[names.length - 1]}`;
};

export const abreviateEmail = (email) => {
  // fulano@dominio.com.br -> fulano@dom...
  const [name, domain] = email.split('@');
  return `${name}@${limitString(domain, 3)}`;
};
