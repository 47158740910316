import { Sheet } from 'react-modal-sheet';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import { UserContext } from '../../Context/UserContext';
import {
  ChevronLeft, Clock, Crown,
  User1,
} from '../../components/DuotoneIcon';
import PremiumDisclaimer from '../../components/PremiumDisclaimer';
import { validateEmail } from '../../utils/validators';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import AudioPlayer from '../../components/AudioPlayer';
import FotoMilena from '../../assets/img/foto_milena.jpg';
import audioConvite from '../../assets/audios/convidando-o-time.mp3';
import audioConviteLider from '../../assets/audios/convidando-o-time-lider.mp3';
import MapeamentoLoader from '../../components/MapeamentoLoader';

// eslint-disable-next-line
const ListItensWithBlueBg = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  padding: 1.75rem 2rem;
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
  color: ${({ theme }) => theme.colors.primary.light};
`;

const BetaTestesContainer = styled.div`
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2183a6+11,223b59+100 */
  // background: linear-gradient(135deg,  #2183a6 11%,#223b59 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* border-radius: ${({ theme }) => theme.spaces.screenEdge}; */
  /* padding: 2px; */
`;
const BetaTesterContent = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.surfaces.lightBlue};
  padding: 1.5rem;
  border-radius: calc(${({ theme }) => theme.spaces.screenEdge} - 3px);
  color: ${({ theme }) => theme.colors.primary.light};
`;

const UserTypeButton = styled.button`
  background-color: #FFF;
  padding: ${({ theme }) => theme.spaces.screenEdge};
  border: 1px solid ${({ theme }) => theme.colors.black20};
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) => theme.colors.primary.light};
  width: 100%;
  text-align: left;
  transition: all 0.3s;
  p {
    width: calc(100% - 20px);
    margin-bottom: 0;
    text-wrap: pretty;
  }
  p.title {
    width: 100%;
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .4rem;
    /* margin-bottom: -3px; */
    .icon {
      background: ${({ theme }) => theme.colors.black10};
      border-radius: 50px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -5px;
    }
  }
  &.comingsoon {
    border-color: ${({ theme }) => theme.colors.black10};
    background-color: ${({ theme }) => theme.colors.black10};
    color: ${({ theme }) => theme.colors.black60};
    pointer-events: none;
    position: relative;
    p.title {
      color: ${({ theme }) => theme.colors.black60};
    }
    &::before {
      content: 'Em breve';
      font-size: 0.9rem;
      font-weight: 600;
      position: absolute;
      top: 16px;
      right: 20px;
      background-color: ${({ theme }) => theme.colors.black20};
      padding: 2px 7px;
      border-radius: ${({ theme }) => theme.radius.xxs};
    }
  }
  &.selected {
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0px 2px 8px 0px rgba(37, 46, 70, 0.20) !important;
    p.title {
      .icon {
        background: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
  &.notselected {
    opacity: 0.7;
  }
`;

export default function AddUserSheet({
  handleInviteUsers,
  handleInviteUsersIsLoading,
  workspaceTeams,
}) {
  const { currentUserContextData } = useContext(UserContext);
  const totalInvitedUsers = currentUserContextData.workspaceContext.ledUsers; // total ledUsers
  // eslint-disable-next-line
  const [addUserStage, setAddUserStage] = useState(1);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const { totalUsers } = currentUserContextData.workspaceContext;
  const [userType, setUserType] = useState('');
  const [emails, setEmails] = useState([{
    key: uuidv4(),
    value: '',
  }]);
  const [totalValidEmails, setTotalValidEmails] = useState(0);
  const [addUserConfirmSheetIsOpen, setAddUserConfirmSheetOpen] = useState(false);

  const msgConviteLider = 'Olá! Estamos usando a Persora, uma ferramenta que apoia a gestão do nosso time de forma independente. Te adicionei como líder, com as mesmas permissões que eu. Após um rápido teste, você terá acesso ao seu estilo e ao do time, ajudando a melhorar nossas interações do dia a dia.';
  const msgConviteLiderado = 'Olá! Estou utilizando a Persora, uma ferramenta para ajudar na comunicação da equipe. Enviei o convite para você ingressar no time e gostaria de contar com a sua participação.';

  const handleChange = (emailKey, newValue) => {
    // console.log('handleChange');
    // set newValue to lowercase and remove any spaces
    const toLowerCaseAndNoSpaceValue = newValue.toLowerCase().replace(/\s/g, '');
    setEmails(emails.map((item) => (item.key === emailKey
      ? { ...item, value: toLowerCaseAndNoSpaceValue }
      : item)));
    setTotalValidEmails(emails.filter((email) => validateEmail(email.value)).length);
  };

  const clearEmails = () => {
    setEmails([{
      key: uuidv4(),
      value: '',
    }]);
  };

  const handleEmails = () => {
    // console.log('handleEmails');
  //   // if (currentUserContextData.workspaceContext.premium !== true) {
  //   //   if (emails.length >= process.env.REACT_APP_LIMIT_USER - 1 - totalUsers) {
  //   //     return; // Limita o número de emails a 4
  //   //   }
  //   // } // comentar para liberar limite
  //   // Verifica se o último email inserido é válido
  //   const lastEmail = emails[emails.length - 1];
  //   const isValidEmail = /\S+@\S+\.\S+/.test(lastEmail.value); // Verifica se o email válido
  //   console.log('isValidEmail', isValidEmail);
  //   if (isValidEmail) {
  //     // Adiciona um novo campo de email vazio
  //     setEmails((prevEmails) => [...prevEmails, { key: uuidv4(), value: '' }]);
  //   }
  //   // else {
  //   //     // Se o último email não for válido, remove-o do array
  //   //     // setEmails(emails.slice(0, -1));
  //   //   }
  };

  useEffect(() => {
    // console.log(userType, currentUserContextData.workspaceContext.leaderUsers);
    if (userType === 'Líder' && currentUserContextData.workspaceContext.leaderUsers + emails.length >= 3) {
      return; // Limita o número de emails a 4
    }
    const lastEmail = emails[emails.length - 1];
    const isValidEmail = /\S+@\S+\.\S+/.test(lastEmail.value);
    // console.log('isValidEmail', isValidEmail);
    if (isValidEmail) {
      // Adiciona um novo campo de email vazio
      setEmails((prevEmails) => [...prevEmails, { key: uuidv4(), value: '', type: 'user' }]);
    }
  }, [emails]);

  const audioTranscript = [
    'Eu sou a Milena e quero sugerir uma forma assertiva para você convidar o time para ser beta tester na Persora. Bom, a equipe compra a ideia quando sente real benefício para o time. Então, minha sugestão é que você vá direto ao ponto, mas tenha sensibilidade: não imponha, dê espaço para o time aceitar ser esse apoio, porque isso gera compromisso. Você pode dizer assim:',
    '"Pessoal, me cadastrei como beta tester da solução Persora, que tem a proposta de apoiar a gestão do time. Vai chegar um convite para vocês, e não é spam, vai chegar por e-mail, fui eu que mandei. No convite, vai ter um link para baixar o app. Vocês farão um micro teste de estilo comportamental e, na mesma hora, já vão acessar o seu estilo e o de todo o time. É aí que a gente começa a melhorar a nossa interação nas situações do dia a dia: one-on-one, feedback... O algoritmo da Persora personaliza de acordo com o estilo de cada pessoa do time, o que tende a nos ajudar a ter esses pontos de contato com mais qualidade. E aí, topam me ajudar nesse teste?"',
    'A mensagem é bem simples, e agora quero reforçar com algumas dicas. Quando as equipes começam a usar, elas sentem necessidade de espaço para falar sobre o assunto. Muitos líderes relataram que, na reunião seguinte, as pessoas queriam contar como elas eram, queriam ouvir dos colegas como eles eram; eles gostam de falar da percepção deles sobre essa descoberta. Isso reforça que a solução não é só do líder, é do time todo e que você está focado em ser um líder melhor para a equipe.',
    'Nós estamos unindo tecnologia e metodologia para entregar para você uma copiloto que fará parte do seu workflow, que será o seu suporte, que ajudará você a ter uma liderança ainda mais segura. Fazemos isso envolvendo todo mundo, e o time passa a te apoiar no dia a dia. Conta com a gente!',
  ];
  const audioTranscriptLideres = [
    'Oi, aqui é a Milena da Persora e, já que você vai convidar mais líderes para o seu workspace, quero passar algumas orientações importantes.',
    'Primeiro, lembre-se: você só consegue aproveitar todas as funcionalidades da ferramenta com pelo menos 3 liderados no seu workspace, então fique de olho nisso.',
    'Por exemplo, tem uma funcionalidade chamada PIP que ajuda você a entender como o time está e quais ações tomar para melhorar. Seria uma pena perder essa oportunidade.',
    'Por que adicionar mais líderes no seu workspace?',
    'A gente conversa com líderes todos os dias por aqui e ouve muitos comentários como: "Queria adicionar meu chefe no meu workspace para ter o apoio dele, mas fico sem jeito de cadastrá-lo como liderado."; "Coordeno um time com 1 tech lead e faria sentido adicioná-lo como líder."',
    'Então, se fizer sentido para você, pode adicionar mais líderes no seu workspace também.',
    'É importante ser claro e objetivo com quem você convida para líder — isso faz toda a diferença. Vou dar um exemplo prático:',
    '"Oi, João! Cadastrei nosso time como beta tester na Persora, uma ferramenta que apoia nossa gestão de forma independente. Te adicionei como líder, com as mesmas permissões que eu. Fique de olho no convite por e-mail — não é spam! Lá você acessará o link com as instruções para entrar no nosso workspace. Depois de um rápido teste, você terá acesso ao seu estilo e ao de todo o time. Isso nos ajudará nas interações do dia a dia com o time, como em one-on-one, feedback e outras situações. O algoritmo da Persora personaliza tudo com base no estilo das pessoas."',
    'Você ainda pode adaptar o convite à sua realidade [de acordo com quem você adiciona]:',
    '"Te adicionei como líder para você ser meu apoio, mas serei o líder responsável pelo nosso workspace."; "Te adicionei como líder para atuarmos juntos. Caso faça sentido, podemos alternar as percepções do time entre nós dois."; "Te adicionei como líder apenas para acompanhar minha atuação de líder no dia a dia.".',
    'Bom, penso que agora você está pronto para integrar novos líderes. Você pode contar com a gente para o desafio de liderar! 😊',
  ];

  return (
    <>
      <Sheet.Container>
        {addUserStage === 1 && (
          <Sheet.Header />
        )}
        {addUserStage === 2 && (
          <Sheet.Header className="pt-2 pb-2 px-1" style={{ display: 'flex', justifyContent: 'start' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => { setAddUserStage(1); clearEmails(); }}>
              <ChevronLeft />
            </PersoraButton>
          </Sheet.Header>
        )}
        <Sheet.Content>
          <Sheet.Scroller>
            <div
              style={{
                minHeight: 400,
                padding: defaultTheme.spaces.screenEdge,
                paddingTop: 0,
                color: defaultTheme.colors.primary.main,
              }}
            >
              {isLoading && <MapeamentoLoader text={null} shrinkLoader />}
              {addUserStage === 1
              && (
                <>
                  <h3 className="medium mb-4">
                    Quem você gostaria de convidar?
                  </h3>
                  <UserTypeButton
                    className={`mb-3${userType === 'Liderado' ? ' selected' : ''}${userType !== '' && userType !== 'Liderado' ? ' notselected' : ''}`}
                    onClick={() => setUserType('Liderado')}
                  >
                    <p className="title">
                      Liderado
                      <span className="icon">
                        <User1 tamanho={22} cor={userType === 'Liderado' ? '#FFF' : defaultTheme.colors.black60} />
                      </span>
                    </p>
                    <p>Usuário que faz parte do time e está sob a supervisão de um líder. </p>
                    <p>
                      Tem acesso às funções de colaboradores, com foco em receber
                      {' '}
                      orientações para o seu desenvolvimento individual, para colaborar
                      {' '}
                      com o time e com a liderança.
                    </p>
                  </UserTypeButton>
                  <UserTypeButton
                    className={`mb-3${userType === 'Líder' ? ' selected' : ''}${userType !== '' && userType !== 'Líder' ? ' notselected' : ''}`}
                    onClick={() => setUserType('Líder')}
                  >
                    <p className="title">
                      Líder
                      <span className="icon">
                        <Crown tamanho={22} cor={userType === 'Líder' ? '#FFF' : defaultTheme.colors.black60} />
                      </span>
                    </p>
                    <p>Usuário responsável por gerir ou apoiar a gestão do time neste workspace.</p>
                    <p>
                      Tem acesso completo às informações para desenvolver a equipe, pode
                      {' '}
                      habilitar funcionalidades, alterar permissões, adicionar e remover pessoas.
                    </p>
                  </UserTypeButton>
                  <UserTypeButton className="comingsoon mb-3">
                    <p className="title">Externo</p>
                    <p>
                      Usuário freelancer ou parceiro que precisa interagir e
                      {' '}
                      colaborar com o time temporariamente.
                      {' '}
                    </p>
                    <p>Tem acesso a parte das funções de um liderado.</p>
                  </UserTypeButton>
                  <PersoraButton
                    className="mt-2 mb-4"
                    type="button"
                    onClick={() => setAddUserStage(2)}
                    disabled={userType === ''}
                  >
                    Convidar
                    {' '}
                    {userType === 'Líder' && 'líder'}
                    {userType === 'Liderado' && 'liderado'}
                  </PersoraButton>
                  <BetaTestesContainer className="my-4">
                    <BetaTesterContent>
                      <h2 className="medium sourcesans mb-3">
                        Você é um
                        {' '}
                        <b className="premium-text">beta tester!</b>
                        {' '}
                        👾
                      </h2>
                      <div className="emoji-paragraph">
                        <span>👑</span>
                        <p className="mb-2">
                          Até
                          {' '}
                          <s>1 líder</s>
                          {' '}
                          3 líderes
                        </p>
                      </div>
                      <div className="emoji-paragraph">
                        <span>✨</span>
                        <p className="mb-2">
                          Até
                          {' '}
                          <s>4 liderados</s>
                          {' '}
                          20 liderados
                        </p>
                      </div>
                      <div className="emoji-paragraph">
                        <span>😎</span>
                        <p className="mb-2">A partir de 3 liderados, você acessa todas as funcionalidades.</p>
                      </div>
                      <p className="small mb-0">*As condições estão sujeitas a alterações, as quais serão comunicadas com 30 dias de antecedência através de aviso prévio.</p>
                      <PremiumDisclaimer modelo="botaoComoSera" />
                    </BetaTesterContent>
                  </BetaTestesContainer>
                </>
              )}
              {addUserStage === 2
              && (
                <>
                  {userType === 'Líder' && (
                    <h3 className="medium">
                      Convidando líderes
                    </h3>
                  )}
                  {userType === 'Liderado' && (
                    <h3 className="medium">
                      Convidando liderados
                    </h3>
                  )}
                  {userType === 'Líder' && (
                    <h4 className="sourcesans mb-3" style={{ lineHeight: '1.5rem', textWrap: 'pretty' }}>
                      Ouça nossas orientações antes de convidar
                      {' '}
                      <b>novos líderes</b>
                      {' '}
                      para o seu workspace.
                    </h4>
                  )}
                  {userType === 'Liderado' && (
                    <h4 className="sourcesans mb-3" style={{ lineHeight: '1.5rem', textWrap: 'pretty' }}>
                      Ouça a nossa sugestão de
                      {' '}
                      <b>como contar para o time</b>
                      {' '}
                      sobre a Persora.
                    </h4>
                  )}
                  {userType === 'Líder' && (
                    <AudioPlayer
                      src={audioConviteLider}
                      photo={FotoMilena}
                      transcript={audioTranscriptLideres}
                      mixpanelAudioTitleToTrack="Áudio sobre adição de novos líderes."
                      className="mt-2 my-4"
                    />
                  )}
                  {userType === 'Liderado' && (
                    <AudioPlayer
                      src={audioConvite}
                      photo={FotoMilena}
                      transcript={audioTranscript}
                      mixpanelAudioTitleToTrack="Áudio sobre adição de novos liderados."
                      className="mt-2 my-4"
                    />
                  )}
                  <form id="inviteUsersForm" onSubmit={(event) => handleInviteUsers(event, emails, userType)}>
                    {/* <FloatingLabel controlId="floatingTextarea2" label="Endereços de email"> */}
                    <Form.Group>
                      <Form.Label>
                        Endereços de e-mail
                      </Form.Label>
                      {/* map as <Form.Control
                    as="input"
                    placeholder="E-mail "
                    className="mb-3"
                    name="emails"
                    type="email"
                  /> */}
                      {emails.map((email) => (
                        <Form.Control
                          as="input"
                          placeholder="E-mail "
                          className="mb-3"
                          name="emails[]"
                          type="email"
                          key={email.key}
                          value={email.value}
                          onChange={(e) => {
                            handleChange(email.key, e.target.value);
                            handleEmails();
                          }}
                        />
                      ))}

                    </Form.Group>
                    {/* </FloatingLabel> */}
                    {/* <FloatingLabel
                      controlId="floatingTextarea3"
                      label="Mensagem do convite"
                    > */}
                    <Form.Group className="d-none">
                      <Form.Label>Mensagem do convite</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Mensagem do convite que será enviada por email"
                        style={{ height: '140px' }}
                        defaultValue={userType === 'Líder' ? msgConviteLider : msgConviteLiderado}
                        className="mb-3"
                        name="msg"
                      />
                    </Form.Group>
                    {/* </FloatingLabel> */}
                    <Form.Group className="d-none">
                      <Form.Label>
                        Time
                        {' '}
                        <span> • Defina o time inicial destes membros</span>
                      </Form.Label>
                      <Form.Select aria-label="Time padrão" name="team">
                        {/* TODO Ajustar time default */}
                        {workspaceTeams.map((team) => (
                          <option key={team._id} value={team._id}>
                            {team.emoji ? team.emoji : '🤖'}
                            {' '}
                            {team.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {(totalInvitedUsers + totalValidEmails < 3) && userType === 'Liderado' ? (
                      <PersoraButton
                        className="mt-4 mb-3"
                        type="button"
                        onClick={() => setAddUserConfirmSheetOpen(true)}
                      >
                        Convidar meu time
                      </PersoraButton>
                    ) : (
                      <PersoraButton
                        className="mt-4 mb-3"
                        type="submit"
                        form="inviteUsersForm"
                        disabled={handleInviteUsersIsLoading}
                      >
                        {handleInviteUsersIsLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Convidar meu time'}
                      </PersoraButton>
                    )}
                  </form>
                </>
              )}
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet
        isOpen={addUserConfirmSheetIsOpen}
        onClose={() => setAddUserConfirmSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        disableDrag={!addUserConfirmSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-2 px-1" style={{ display: 'flex', justifyContent: 'start' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setAddUserConfirmSheetOpen(false)}>
              <ChevronLeft />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
              >
                <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>
                  Tem certeza que não quer adicionar mais
                  {' '}
                  {3 - (totalInvitedUsers + totalValidEmails)}
                  {' '}
                  liderado
                  {(3 - (totalInvitedUsers + totalValidEmails)) > 1 ? 's' : ''}
                  ?
                </h2>
                <p className="mb-2">
                  Ao adicionar pelo menos 3 liderados, você desbloqueia a
                  {' '}
                  funcionalidade de ver como o time está e o que fazer para melhorar.
                </p>
                <PersoraButton
                  className="mt-4 mb-3"
                  type="button"
                  onClick={() => setAddUserConfirmSheetOpen(false)}
                  disabled={handleInviteUsersIsLoading}
                >
                  Voltar e convidar mais pessoas
                </PersoraButton>
                <PersoraButton
                  className="mb-4"
                  type="submit"
                  form="inviteUsersForm"
                  disabled={handleInviteUsersIsLoading}
                  estilo="outline"
                >
                  {handleInviteUsersIsLoading ? <Clock tamanho={27} cor={defaultTheme.colors.primary.main} /> : 'Enviar convites'}
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setAddUserConfirmSheetOpen(false)} />
      </Sheet>
    </>
  );
}

AddUserSheet.propTypes = {
  handleInviteUsers: PropTypes.func.isRequired,
  handleInviteUsersIsLoading: PropTypes.bool.isRequired,
  workspaceTeams: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    emoji: PropTypes.string,
  })).isRequired,
};
