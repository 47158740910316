/* eslint-disable max-len, no-unused-vars */

import {
  Fragment, useContext, useEffect, useState,
} from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  // Tooltip,
  // Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import styled, { StyleSheetManager } from 'styled-components';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import isPropValid from '@emotion/is-prop-valid';

import {
  Accordion, Modal, Container, Row, Col,
  Form,
  // Tooltip, OverlayTrigger,
} from 'react-bootstrap';

import { Sheet } from 'react-modal-sheet';
import { toast } from 'react-toastify';
import api from '../../services/backend';
import { UserContext } from '../../Context/UserContext';
import Disclaimer from '../Disclaimer';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';
import {
  ArrowSmallDown,
  Basketball,
  Book, ChevronLeft, ChevronSmallLeft, CircleFilled, Clock, Cross, Crown, EmoteSad, EmoteSmile, PinpaperCheck, PinpaperFilled, QuestionCircle,
  User1,
  User2,
  Users,
  UsersMore,
} from '../DuotoneIcon';
import Nervosa from '../../assets/img/Sora/Nervosa1.svg';
import Preocupada from '../../assets/img/Sora/Preocupada2.svg';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import enpsScale from '../../assets/img/enpsScale.svg';
import enpsNeedle from '../../assets/img/enpsNeedle.svg';
import enpsFormula from '../../assets/img/enpsFormula.svg';
import enpsExample from '../../assets/img/enpsExample.svg';
import CoverSora from '../../assets/img/pip-planoacao-sora.svg';
import CoverIcone1 from '../../assets/img/pip-planoacao-icone1.svg';
import CoverIcone2 from '../../assets/img/pip-planoacao-icone2.svg';
import CoverIcone3 from '../../assets/img/pip-planoacao-icone3.svg';
import CoverIcone4 from '../../assets/img/pip-planoacao-icone4.svg';
import OlhosSuplicantes from '../../assets/img/SoraFace/OlhosSuplicantes.svg';
import SoraPreocupacao from '../../assets/img/SoraV2/BustoCortado/Preocupacao.svg';
import RostoSoraPiscando from '../../assets/img/SoraV2/Rosto/Piscando.svg';
import SoraNeutra from '../../assets/img/SoraFace/NeutraLike.svg';
import FotoMilena from '../../assets/img/foto_milena.jpg';
import MapeamentoLoader from '../MapeamentoLoader';
import { formatDateDDMM, formatDateDDMMYY, formatDateDDMMYYYY } from '../../utils/dateUtils';
import audioPip from '../../assets/audios/Aud_cln.mp3';
import Trends from '../Trends';
import {
  SoraSpeech,
  Foto,
  SpeechBubble,
  ColPipData,
  ColPipDataValues,
  ProgressContainer,
  ProgressBar,
  ActionPlanContent,
  GrayBox,
  BlueContainer,
  DimensaoButton,
  ActionPlanTitle,
  ActionPlanTitleEmoji,
  LargeText,
  MediumText,
  PidDateRow,
  PipDateCol,
  PipTabsMenu,
  PipTabsMenuItem,
  PipSubDimensionTitle,
  OldPipDisclaimer,
} from './styles';
import { LiderAvaliaResultado } from '../../pages/Sheets/PipForms';
import AudioPlayer from '../AudioPlayer';
import { CopilotCover } from '../../pages/Sheets/SoraCopilotV2';
import DisclaimerV2 from '../DisclaimerV2';
import PipBarGraph from '../PipBarGraph';

function CustomizedLabel(props) {
  const {
    // eslint-disable-next-line
    x, y, stroke, value,
  } = props;

  return (
    <text
      x={x}
      y={y}
      dy={-16}
      dx={-1}
      fill={stroke}
      fontSize={14}
      textAnchor="middle"
      style={{
        fill: defaultTheme.colors.primary.main, fontFamily: 'Source Sans 3', fontWeight: 600,
      }}
    >
      {value}
    </text>
  );
}

// eslint-disable-next-line
function CustomizedDot(props) {
  const {
    // eslint-disable-next-line
    cx, cy, stroke, payload, value,
  } = props;

  // Ajustar a posição vertical do ponto se o valor for igual a 100
  let adjustedCy = cy;
  if (value >= 90) {
    adjustedCy += 0; // Ajuste a posição para cima
  }

  if (value < 0) {
    return (
      <svg x={cx - 10} y={adjustedCy - 10} width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#F49690" />
        <path d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z" fill="#EF6C64" />
      </svg>
    );
  }
  if (value >= 0 && value < 50) {
    return (
      <svg x={cx - 10} y={adjustedCy - 10} width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#FCD59C" />
        <path d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z" fill="#E3A74F" />
      </svg>
    );
  }
  if (value >= 50 && value < 75) {
    return (
      <svg x={cx - 10} y={adjustedCy - 10} width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#B2E6D0" />
        <path d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z" fill="#3FBE89" />
      </svg>
    );
  }
  return (
    <svg x={cx - 10} y={adjustedCy - 10} width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z" fill="#66CCA1" />
      <path d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8Z" fill="#3B9771" />
    </svg>
  );
}

// eslint-disable-next-line
export default function PipResultsTab({ getQuestByTeamData, setPipRespostasEfetivasSheetOpen, currentTeam, updateTaskPipSeeActionPlan, handleMixpanelConteudoPipAcessado }) {
  if (!getQuestByTeamData) return (<MapeamentoLoader text={null} isLoading />);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [colocarEmPraticaSheetIsOpen, setColocarEmPraticaSheetOpen] = useState(false);
  const [escutaAtivaPipSheetIsOpen, setEscutaAtivaPipSheetOpen] = useState(false);
  const [pipQuestLiderAvaliaResultadoIsOpen, setPipQuestLiderAvaliaResultadoOpen] = useState(false);
  const [pipDefsSheetIsOpen, setPipDefsSheetOpen] = useState(false);
  // enps, individuo, time, lideranca, regras
  const [pipDefsContent, setPipDefsContent] = useState(null);
  const [show, setShow] = useState(false);
  const historyPipData = (getQuestByTeamData || null).filter((item) => !item.enabled);
  // historyPipDataWithResults => filtra hustoryPipData para remover pips
  // que possuam .results[0].results vazio
  const historyPipDataWithResults = historyPipData.filter((item) => item.results[0].results && item.results[0].results.zona);
  const mostRecentValidPip = (historyPipDataWithResults.length > 0
    ? historyPipDataWithResults[historyPipDataWithResults.length - 1]
    : null);
  const leastRecentValidPip = (historyPipDataWithResults.length > 1
    ? historyPipDataWithResults[historyPipDataWithResults.length - 2]
    : null);
  const [totalAnswersMostRecentPip, setTotalAnswersMostRecentPip] = useState(null);
  const [validAnswersMostRecentPip, setValidAnswersMostRecentPip] = useState(null);
  const [totalAnsersLeastRecentPip, setTotalAnsersLeastRecentPip] = useState(null);
  const [validAnswersLeastRecentPip, setValidAnswersLeastRecentPip] = useState(null);
  const [mostRecentPipFinalDate, setMostRecentPipFinalDate] = useState(null);
  // console.log('historyPipData', historyPipData);
  // console.log('historyPipDataWithResults', historyPipDataWithResults);
  // console.log('mostRecentValidPip', mostRecentValidPip);
  // console.log('leastRecentValidPip', leastRecentValidPip);
  // console.log(`Última PIP • Respostas válidas: ${validAnswersMostRecentPip?.length}/${totalAnswersMostRecentPip?.length}`);
  // console.log(`Penúltima PIP • Respostas válidas: ${validAnswersLeastRecentPip?.length}/${totalAnsersLeastRecentPip?.length}`);
  const [needleRotate, setNeedleRotate] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // para cada resultado de da pip (historyPipData[0... 1... 2...]),
  // pegar enps (.results[0].results.enps) e colocar em uma const data
  // para ser usada no gráfico
  // const data = historyPipData.map((item) => ({
  const data = historyPipDataWithResults.map((item) => ({
    name: formatDateDDMM(item.finalDate, '/'),
    uv: (item.results[0].results?.zona ? item.results[0].results?.enps : null),
  }));

  const [dimensionTab, setDimensionTab] = useState('individuo'); // individuo, time, lideranca, regras

  const scrollToDisclaimerOldPipBellow = () => {
    const disclaimerOldPip = document.getElementById('disclaimerOldPipBellow');
    if (disclaimerOldPip) {
      disclaimerOldPip.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [intencaoPraticarAnswer, setIntencaoPraticarAnswer] = useState(null);
  const [hiddenPraticarPlanoAcaoQuestion, setHiddenPraticarPlanoAcaoQuestion] = useState(false);
  const [hiddenPipQuestLiderAvaliaResultado, setHiddenPipQuestLiderAvaliaResultado] = useState(false);
  const [planoDeAcaoObstaculosNote, setPlanoDeAcaoObstaculosNote] = useState(null);
  const [planoDeAcaoObstaculosNoteLenght, setPlanoDeAcaoObstaculosNoteLenght] = useState(0);
  // handleChangeDescription que setará planoDeAcaoObstaculosNote com setPlanoDeAcaoObstaculosNote
  const handleChangeDescription = (e) => {
    // limit length to 230 chars
    if (e.target.value.length > 350) {
      e.target.value = e.target.value.substring(0, 350);
      setPlanoDeAcaoObstaculosNoteLenght(350);
    }
    setPlanoDeAcaoObstaculosNoteLenght(e.target.value.length);
    setPlanoDeAcaoObstaculosNote(e.target.value);
  };
  const handleIntencaoPraticarPlanoAcaoPip = async (answer, updateIntencao = false) => {
    setIsLoading(true);
    setIntencaoPraticarAnswer(answer);
    try {
      const body = {
        idTeam: currentTeam._id,
        idSurvey: mostRecentValidPip.idQuestSurvey,
        resposta: answer,
        observacaoNaoIraPraticar: (planoDeAcaoObstaculosNote || null),
      };
      if (answer === 'sim') {
        await api.post('questSurvey/setIntencaoPraticaPlanoAcao/', body);
        toast.success('Muito bom! Sua intenção foi registrada.', {
          autoClose: 4000,
          position: 'top-center',
          // icon: '✅',
        });
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Engajamento',
            action: 'pip_avaliada',
            question: 'Com base no resultado da PIP, personalizamos um plano de ação para você. Você colocará em prática?',
            answer: 'Colocarei em prática.',
          });
          mixpanel.track('pip_avaliada', {
            category: 'Engajamento',
            company_id: currentUserContextData._idWorkspace[0],
            question: 'Com base no resultado da PIP, personalizamos um plano de ação para você. Você colocará em prática?',
            answer: 'Colocarei em prática.',
          });
        }
      }
      if (answer === 'não' && updateIntencao === false) {
        await api.post('questSurvey/setIntencaoPraticaPlanoAcao/', body);
        setColocarEmPraticaSheetOpen(true);
        toast.success('Que pena. Sua intenção foi registrada.', {
          autoClose: 4000,
          position: 'top-center',
          icon: '😔',
        });
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Engajamento',
            action: 'pip_avaliada',
            question: 'Com base no resultado da PIP, personalizamos um plano de ação para você. Você colocará em prática?',
            answer: 'Não colocarei em prática.',
          });
          mixpanel.track('pip_avaliada', {
            category: 'Engajamento',
            company_id: currentUserContextData._idWorkspace[0],
            question: 'Com base no resultado da PIP, personalizamos um plano de ação para você. Você colocará em prática?',
            answer: 'Não colocarei em prática.',
          });
        }
      }
      if (answer === 'não' && updateIntencao === true) {
        if (planoDeAcaoObstaculosNote !== null) {
          await api.post('questSurvey/setIntencaoPraticaPlanoAcao/', body);
          toast.success('Tudo certo! Você faz parte do desenvolvimento da Persora.', {
            autoClose: 6000,
            position: 'top-center',
            // icon: '👍',
          });
        }
        setColocarEmPraticaSheetOpen(false);
      }
      setHiddenPraticarPlanoAcaoQuestion(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao salvar resposta.', {
        autoClose: 3000,
        position: 'top-center',
      });
      setIsLoading(false);
      // setPipEnableCurrentTeam(!pipEnableCurrentTeam);
    }
    // if (planoDeAcaoObstaculosNote === null && intencaoPraticarAnswer !== null) {
    //   setIsLoading(false);
    //   setHiddenPraticarPlanoAcaoQuestion(true);
    //   setColocarEmPraticaSheetOpen(false);
    //   // toas tudo bem!
    // }
  };

  const renderDisclaimer = () => {
    const planoAcao = mostRecentValidPip?.results[0]?.planoAcaoDefinido;
    const planoAcaoDimensao = planoAcao?.dimensao;
    const planoAcaoDefinicao = planoAcao?.definicao;

    if (planoAcaoDimensao) {
      if (planoAcaoDimensao !== 'tudo10') {
        return (
          <>
            <DisclaimerV2
              estilo="danger"
              soraSize="normal"
              contentAlign="center"
              className="mb-5"
            >
              {currentUserContextData.type === 'Líder' ? (
                <div>
                  <p className="mb-2" style={{ color: defaultTheme.colors.primary.main }}>
                    Referente a PIP do dia
                    {' '}
                    {mostRecentValidPip.finalDate && formatDateDDMMYY(mostRecentValidPip.finalDate, '/')}
                  </p>
                  <h3 className="sourcesans mb-3" style={{ textWrap: 'pretty' }}>
                    {planoAcaoDefinicao}
                    {' '}
                    🚨
                  </h3>
                  <PersoraButton
                    tamanho="condensed"
                    onClick={() => { handleShow(); updateTaskPipSeeActionPlan(); handleMixpanelConteudoPipAcessado('Plano de Ação'); }}
                    className="mb-0"
                    style={{ margin: '0 auto' }}
                  >
                    Ver plano de ação
                  </PersoraButton>
                </div>
              ) : (
                <div>
                  <p className="mb-2" style={{ color: defaultTheme.colors.primary.main }}>
                    Referente a PIP do dia
                    {' '}
                    {mostRecentValidPip.finalDate && formatDateDDMMYY(mostRecentValidPip.finalDate, '/')}
                  </p>
                  <h3 className="sourcesans mb-3" style={{ textWrap: 'pretty' }}>
                    {planoAcaoDefinicao}
                    {' '}
                    🚨
                  </h3>
                  <hr className="mt-4 mb-4" />
                  <h5 className="mb-2 sourcesans semibold" style={{ textWrap: 'pretty' }}>
                    Sua liderança recebeu um
                    {' '}
                    <span className="keeptogether">plano de ação!</span>
                  </h5>
                  <p className="mb-0" style={{ textWrap: 'pretty' }}>
                    Compilamos os resultados das 12 perguntas respondidas pelo time e personalizamos uma ação focada no que está mais crítico.
                  </p>
                </div>
              )}
            </DisclaimerV2>
            <Disclaimer
              estilo="danger"
              tituloAnterior={`Referente a PIP do dia ${mostRecentValidPip.finalDate && formatDateDDMMYY(mostRecentValidPip.finalDate, '/')}.`}
              titulo={planoAcaoDefinicao}
              icone="🚨"
              className="d-none"
              isActionPlanDisclaimer
            >
              {currentUserContextData.type === 'Líder' ? (
                <PersoraButton
                  tamanho="condensed"
                  onClick={() => { handleShow(); updateTaskPipSeeActionPlan(); handleMixpanelConteudoPipAcessado('Plano de Ação'); }}
                  style={{ marginLeft: '38px' }}
                  className="mb-2"
                >
                  Ver plano de ação
                </PersoraButton>
              ) : (
                <div />
              )}
            </Disclaimer>
          </>
        );
      }
      return (
        <Disclaimer
          estilo="danger"
          titulo={planoAcaoDefinicao}
          icone="🚨"
          isActionPlanDisclaimer
        />
      );
    }
    return (
      <Disclaimer
        estilo="danger"
        titulo="Nenhum plano de ação foi gerado ainda."
        icone="🚨"
      />
    );
  };

  useEffect(() => {
    if (historyPipData) {
      setMostRecentPipFinalDate(historyPipData[historyPipData.length - 1]?.finalDate);
      setTotalAnswersMostRecentPip(historyPipData[historyPipData.length - 1]?.answers.length);
      setValidAnswersMostRecentPip(historyPipData[historyPipData.length - 1]?.answers.filter((item) => item.isValid).length);
      setTotalAnsersLeastRecentPip(historyPipData[historyPipData.length - 2]?.answers.length);
      setValidAnswersLeastRecentPip(historyPipData[historyPipData.length - 2]?.answers.filter((item) => item.isValid).length);
      // setHistoryPipDataWithResults(historyPipData.filter((item) => item.results[0].results && item.results[0].results.zona));
    }
    if (mostRecentValidPip) {
      // console.log('mostRecentValidPip.results[0].results.enps', mostRecentValidPip.results[0].results.enps);
      const rotate = mostRecentValidPip.results[0]?.results?.zona ? 90 + (+(mostRecentValidPip.results[0].results.enps) * 0.9) : null;
      setNeedleRotate(rotate);
    }
    // if vetor/array/[] mostRecentValidPip.results[0].intencaoPraticarPlanoAcao tiver
    // alguma posição que tenha idUser === currentUserContextData._id,
    // setHiddenPraticarPlanoAcaoQuestion(true)
    if (mostRecentValidPip?.results[0]?.intencaoPraticarPlanoAcao) {
      const currentUserIntencaoPraticarPlanoAcao = mostRecentValidPip.results[0].intencaoPraticarPlanoAcao.find((item) => item.idUser === currentUserContextData._id);
      if (currentUserIntencaoPraticarPlanoAcao) {
        setHiddenPraticarPlanoAcaoQuestion(true);
      }
    }
    if (mostRecentValidPip?.results[0]?.avaliacaoResultado) {
      const currentUserAvaliacaoResultado = mostRecentValidPip.results[0]?.avaliacaoResultado?.find((item) => item.idUser === currentUserContextData._id);
      if (currentUserAvaliacaoResultado) {
        setHiddenPipQuestLiderAvaliaResultado(true);
      }
    }
  }, [getQuestByTeamData, historyPipData]);
  // console.log('needleRotateneedleRotate', needleRotate);

  // const data = [
  //   {
  //     name: '11/03',
  //     uv: -92,
  //   },
  //   {
  //     name: '11/04',
  //     uv: 49,
  //   },
  // ];

  // setNeedleRotate((180 * 100) / 200);

  if (historyPipData?.length === 0) {
    return (
      <>
        <h2 className="medium sourcesans mb-4" style={{ color: defaultTheme.colors.primary.main }}>Visão geral do time</h2>
        <div className="text-center">
          <img src={SoraPreocupacao} alt="Olhos suplicantes" style={{ width: '100%', maxWidth: '190px', margin: '0 auto' }} className="mb-2" />
          <p>Ainda não há resultados disponíveis.</p>
        </div>
      </>
    );
  }

  const audioTranscript = [
    'Oi, aqui quem fala é Milena da Persora, e eu quero te contar o que não fazer quando você acessar o resultado da PIP do seu time. Depois de saber o que está mais crítico, que aparece com a sirene vermelha, é normal você se perguntar: será que essa é a opinião de uma pessoa só ou do time todo? Talvez sua vontade seja justamente sair interrogando as pessoas para descobrir quem está mal e atuar pontualmente, no intuito de não ocupar a agenda do time todo.',
    'Não funciona assim. Aliás, posso te garantir que quando você expõe as pessoas, você só piora as coisas. A PIP é anônima, lembra? E essa abordagem de investigar quem respondeu o quê quebra a segurança psicológica do time. As pessoas passam a silenciar e você desconstrói esse canal que tem tudo pra ser o feedback mais genuíno do time pra você.',
    'Mas, e se o problema que apareceu for o problema de uma pessoa só?',
    'Calma, os planos de ação são personalizados e priorizados pelas notas, mas são cruciais na manutenção dos fundamentos de uma boa liderança. Por exemplo, se nessa quinzena o líder tem realmente uma pessoa só que deu uma nota baixa por não estar conseguindo identificar suas habilidades mais fortes, quando o líder aplica o plano de ação com todos, ele evita que daqui a pouco outra e outra pessoa sinta a mesma coisa. Então, encare como um processo contínuo de construção de confiança para ser uma liderança forte.',
    'E o que você deve fazer com o resultado da PIP?',
    'A primeira coisa que a gente sugere é que você se comunique com o time assim que acessar o resultado. Use o canal de comunicação do seu time (Slack, Teams, WhatsApp), para enviar uma mensagem (o app oferece um modelo) falando que você está atento à percepção deles sobre a última quinzena e que você irá acessar um plano de ação.',
    'Eu sei que o tempo do time é precioso; a gente aqui na Persora também é um time de tecnologia, mas você pode adaptar a ação para a sua nossa realidade. Por exemplo, o plano de ação de um líder dessa última quinzena foi pedir para que cada pessoa, com base no último mês, compartilhasse com o grupo as tarefas que mais gostou de realizar e o porquê.',
    'Ao invés de marcar uma agenda só para isso, o líder pediu para que todo mundo trouxesse as respostas para uma agenda já existente, e ele definiu um tempo de dois minutos onde todos puderam compartilhar.',
    'Então agora é agir, colocar o plano de ação em prática recorrentemente. Confie no processo que o resultado virá.',
  ];

  return (
    <div>
      <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Visão geral do time</h2>
      <p className="mb-3 small">De acordo com o último resultado compilado da PIP.</p>
      <GrayBox
        style={{
          background: 'none',
          padding: '15px 0',
          paddingTop: '10px',
          paddingBottom: '5px',
          borderRadius: 0,
          // borderTop: `1px solid ${defaultTheme.colors.black20}`,
          // borderBottom: `1px solid ${defaultTheme.colors.black20}`,
        }}
      >
        <h2
          className="mb-3 soucesans"
          style={{
            display: 'flex', gap: '4px', alignItems: 'center', color: defaultTheme.colors.primary.main,
          }}
        >
          Respostas
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            className="py-1 px-1"
            onClick={() => { setPipRespostasEfetivasSheetOpen(true); }}
          >
            <QuestionCircle />
          </PersoraButton>
        </h2>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'start' }}>
          <h1 className="bold mb-0 px-3 pe-1">{totalAnswersMostRecentPip}</h1>
          <p className="mb-0">
            {totalAnswersMostRecentPip === 1 ? 'pessoa realizou' : 'pessoas realizaram'}
            {' '}
            a PIP
            <br />
            encerrada em
            {' '}
            {mostRecentPipFinalDate ? formatDateDDMMYY(mostRecentPipFinalDate, '/') : '--'}
            {totalAnsersLeastRecentPip && (
              <Trends
                className="ms-2"
                style={{
                  margin: '0 auto', fontSize: '.8rem', position: 'absolute', display: 'inline-flex', marginTop: '2px',
                }}
                prevValue={totalAnsersLeastRecentPip}
                currentValue={totalAnswersMostRecentPip}
                iconSize={18}
                returnIntDiference
              />
            )}
          </p>
        </div>
        <ProgressContainer className="ms-0 mt-3 mb-2">
          <h5 className="sourcesans semibold mb-2" style={{ display: 'flex', width: 'fit-content' }}>
            Respostas efetivas
            {validAnswersLeastRecentPip ? (
              <Trends
                className="ms-2"
                style={{ margin: '0 auto', fontSize: '.8rem' }}
                prevValue={validAnswersLeastRecentPip}
                currentValue={validAnswersMostRecentPip}
                iconSize={18}
                returnIntDiference
              />
            ) : ''}
          </h5>
          {/* TODO: CHECAR SE NADA VAI QUEBRAR AO COMENTAR A CONDIÇÃO ABAIXO */}
          {/* {validAnswersLeastRecentPip && ( */}
          <StyleSheetManager shouldForwardProp={isPropValid}>
            <ProgressBar
              percentual={((100 / totalAnswersMostRecentPip) * validAnswersMostRecentPip)}
              progresscontent={`${validAnswersMostRecentPip}/${totalAnswersMostRecentPip}`}
            />
          </StyleSheetManager>
          {/* )} */}
        </ProgressContainer>
      </GrayBox>
      {currentUserContextData.type === 'Líder' && !historyPipData[historyPipData.length - 1]?.results[0]?.results?.zona && (
        <div>
          {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao
            ? (
              <DisclaimerV2
                estilo="warning"
                soraSize="small"
                contentAlign="left"
                className="mb-4"
              >
                <h4 className="mb-2 sourcesans semibold" style={{ textWrap: 'pretty' }}>
                  Não foi possível gerar o novo
                  {' '}
                  <span className="keeptogether">resultado da PIP</span>
                </h4>
                <p className="mb-1">
                  A PIP referente a
                  {' '}
                  {formatDateDDMMYY(historyPipData[historyPipData.length - 1].finalDate, '/')}
                  {' '}
                  não atingiu o mínimo de 3 respostas efetivas.
                </p>
                <p className="mb-1">Reveja o último plano de ação e recupere a participação do time para a próxima quinzena.</p>
                <PersoraButton tamanho="condensed" estilo="outline" className="mt-2 ps-3" onClick={() => scrollToDisclaimerOldPipBellow()} style={{ gap: '8px' }}>
                  <ArrowSmallDown />
                  Rever resultado anterior
                </PersoraButton>
              </DisclaimerV2>
            ) : (
              <DisclaimerV2
                estilo="warning"
                soraSize="small"
                contentAlign="left"
                className="mb-3"
              >
                <h4 className="mb-2 sourcesans semibold" style={{ textWrap: 'pretty' }}>
                  Não foi possível gerar o
                  {' '}
                  <span className="keeptogether">resultado da PIP</span>
                </h4>
                <p className="mb-1">
                  A PIP referente a
                  {' '}
                  {formatDateDDMMYY(historyPipData[historyPipData.length - 1].finalDate, '/')}
                  {' '}
                  não atingiu o mínimo de 3 respostas efetivas.
                </p>
                <p className="mb-2">
                  Impulsione a participação do time para a PIP da próxima quinzena.
                </p>
              </DisclaimerV2>
            )}
        </div>
      )}
      {currentUserContextData.type !== 'Líder' && !historyPipData[historyPipData.length - 1]?.results[0]?.results?.zona && (
        <DisclaimerV2
          estilo="warning"
          soraSize="small"
          contentAlign="left"
          className="mb-4"
        >
          <h4 className="mb-2 sourcesans semibold" style={{ textWrap: 'pretty' }}>
            Não foi possível gerar o
            {' '}
            <span className="keeptogether">resultado da PIP</span>
          </h4>
          <p className="mb-1">
            A PIP referente a
            {' '}
            {formatDateDDMMYY(historyPipData[historyPipData.length - 1].finalDate, '/')}
            {' '}
            não atingiu o mínimo de 3 respostas efetivas.
          </p>
        </DisclaimerV2>
      )}
      {/* se tiver uma pesquisa com resultado, mas o mais recente não tiver tido resposta suficiente, printar mensagem */}
      {!historyPipData[historyPipData.length - 1]?.results[0]?.results?.zona && mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao && (
        <OldPipDisclaimer id="disclaimerOldPipBellow">
          <span>⌛️</span>
          {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao && leastRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao
            ? <span style={{ textWrap: 'pretty' }}>Abaixo você vizualiza os resultados anteriores da PIP.</span>
            : <span style={{ textWrap: 'pretty' }}>Abaixo você vizualiza o resultado anterior da PIP.</span>}
        </OldPipDisclaimer>
      )}
      {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao && (
        <>
          {renderDisclaimer()}
          {currentUserContextData.type === 'Líder' && (
            <>
              {/* <hr className="my-4" /> */}
              <h4 className="sourcesans semibold mt-4 mb-2" style={{ color: defaultTheme.colors.primary.light }}>
                Esse resultado reflete a opinião do time ou de uma pessoa só? 🤔
              </h4>
              <h4 className="sourcesans mb-1">Ouça e entenda.</h4>
              <AudioPlayer
                src={audioPip}
                photo={FotoMilena}
                transcript={audioTranscript}
                mixpanelAudioTitleToTrack="Áudio genérico sobre a PIP"
                className="mt-3 my-4"
              />
            </>
          )}
        </>
      )}
      {/* <hr className="my-4" /> */}
      {currentUserContextData.type === 'Líder' && mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao && (
        <>
          <Container style={{ color: defaultTheme.colors.primary.main, padding: 0 }}>
            <h2 className="medium sourcesans mt-5 mb-3" style={{ color: defaultTheme.colors.primary.main }}>Dimensões</h2>
            <PipTabsMenu className="mb-4">
              <PipTabsMenuItem
                onClick={() => setDimensionTab('individuo')}
                className={`
                  ${dimensionTab === 'individuo' && 'active'}
                  ${['autoconhecimento', 'aproveitamento', 'crescimentoProfissional'].some((word) => mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao.includes(word)) && 'blob'}
                `}
              >
                <User1 />
              </PipTabsMenuItem>
              <PipTabsMenuItem
                onClick={() => setDimensionTab('time')}
                className={`
                  ${dimensionTab === 'time' && 'active'}
                  ${['acolhimento', 'confianca', 'colaboracao'].some((word) => mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao.includes(word)) && 'blob'}
                `}
              >
                <UsersMore />
              </PipTabsMenuItem>
              <PipTabsMenuItem
                onClick={() => setDimensionTab('lideranca')}
                className={`
                  ${dimensionTab === 'lideranca' && 'active'}
                  ${['expectativas', 'feedbacks', 'crescimento'].some((word) => mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao.includes(word)) && 'blob'}
                `}
              >
                <Crown />
              </PipTabsMenuItem>
              <PipTabsMenuItem
                onClick={() => setDimensionTab('regras')}
                className={`
                  ${dimensionTab === 'regras' && 'active'}
                  ${['clareza', 'pratica', 'alinhamento'].some((word) => mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao.includes(word)) && 'blob'}
                `}
              >
                <Basketball />
              </PipTabsMenuItem>
            </PipTabsMenu>
            {dimensionTab === 'individuo' && (
              <>
                <h3 className="sourcesans semibold pb-1" style={{ display: 'flex', alignItems: 'center' }}>
                  Indivíduo
                  {' '}
                  <PersoraButton
                    estilo="ghost"
                    tamanho="mini"
                    className="py-0 px-1"
                    onClick={() => { setPipDefsContent('individuo'); setPipDefsSheetOpen(true); }}
                  >
                    <QuestionCircle />
                  </PersoraButton>
                  {leastRecentValidPip?.results[0]?.results?.individuo ? (
                    <Trends
                      prevValue={leastRecentValidPip.results[0].results.individuo}
                      currentValue={mostRecentValidPip.results[0].results.individuo}
                      style={{ fontSize: '12px' }}
                      manteveSeText="manteve-se"
                    />
                  ) : ''}
                </h3>
                <Row className="mt-3 mb-3">
                  <PidDateRow>
                    {leastRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{formatDateDDMMYY(leastRecentValidPip.finalDate, '/')}</p>
                  </PipDateCol>
                  )}
                    {mostRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={leastRecentValidPip?.finalDate ? defaultTheme.colors.secondary.dark : defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{mostRecentValidPip?.finalDate ? formatDateDDMMYY(mostRecentValidPip.finalDate, '/') : '--'}</p>
                  </PipDateCol>
                  )}
                  </PidDateRow>
                </Row>
                <PipSubDimensionTitle>Autoconhecimento</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.autoconhecimento && !leastRecentValidPip?.results[0]?.results?.autoconhecimento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.autoconhecimento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'autoconhecimento'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.autoconhecimento && leastRecentValidPip?.results[0]?.results?.autoconhecimento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.autoconhecimento.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.autoconhecimento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'autoconhecimento'}
                    />
                  )}
                <PipSubDimensionTitle>Aproveitamento</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.aproveitamento && !leastRecentValidPip?.results[0]?.results?.aproveitamento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.aproveitamento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'aproveitamento'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.aproveitamento && leastRecentValidPip?.results[0]?.results?.aproveitamento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.aproveitamento.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.aproveitamento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'aproveitamento'}
                    />
                  )}
                <PipSubDimensionTitle>Crescimento Profissional</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.crescimentoProfissional && !leastRecentValidPip?.results[0]?.results?.crescimentoProfissional
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.crescimentoProfissional.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'crescimentoProfissional'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.crescimentoProfissional && leastRecentValidPip?.results[0]?.results?.crescimentoProfissional
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.crescimentoProfissional.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.crescimentoProfissional.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'crescimentoProfissional'}
                    />
                  )}
              </>
            )}
            {dimensionTab === 'time' && (
              <>
                <h3 className="sourcesans semibold pb-1" style={{ display: 'flex', alignItems: 'center' }}>
                  Time
                  {' '}
                  <PersoraButton
                    estilo="ghost"
                    tamanho="mini"
                    className="py-0 px-1"
                    onClick={() => { setPipDefsContent('time'); setPipDefsSheetOpen(true); }}
                  >
                    <QuestionCircle />
                  </PersoraButton>
                  {leastRecentValidPip?.results[0]?.results?.time ? (
                    <Trends
                      prevValue={leastRecentValidPip.results[0].results.time}
                      currentValue={mostRecentValidPip.results[0].results.time}
                      style={{ fontSize: '12px' }}
                      manteveSeText="manteve-se"
                    />
                  ) : ''}
                </h3>
                <Row className="mt-3 mb-3">
                  <PidDateRow>
                    {leastRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{formatDateDDMMYY(leastRecentValidPip.finalDate, '/')}</p>
                  </PipDateCol>
                  )}
                    {mostRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={leastRecentValidPip?.finalDate ? defaultTheme.colors.secondary.dark : defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{mostRecentValidPip?.finalDate ? formatDateDDMMYY(mostRecentValidPip.finalDate, '/') : '--'}</p>
                  </PipDateCol>
                  )}
                  </PidDateRow>
                </Row>
                <PipSubDimensionTitle>Acolhimento</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.acolhimento && !leastRecentValidPip?.results[0]?.results?.acolhimento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.acolhimento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'acolhimento'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.acolhimento && leastRecentValidPip?.results[0]?.results?.acolhimento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.acolhimento.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.acolhimento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'acolhimento'}
                    />
                  )}
                <PipSubDimensionTitle>Confiança</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.confianca && !leastRecentValidPip?.results[0]?.results?.confianca
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.confianca.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'confianca'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.confianca && leastRecentValidPip?.results[0]?.results?.confianca
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.confianca.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.confianca.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'confianca'}
                    />
                  )}
                <PipSubDimensionTitle>Colaboração</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.colaboracao && !leastRecentValidPip?.results[0]?.results?.colaboracao
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.colaboracao.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'colaboracao'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.colaboracao && leastRecentValidPip?.results[0]?.results?.colaboracao
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.colaboracao.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.colaboracao.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'colaboracao'}
                    />
                  )}
              </>
            )}
            {dimensionTab === 'lideranca' && (
              <>
                <h3 className="sourcesans semibold pb-1" style={{ display: 'flex', alignItems: 'center' }}>
                  Liderança
                  {' '}
                  <PersoraButton
                    estilo="ghost"
                    tamanho="mini"
                    className="py-0 px-1"
                    onClick={() => { setPipDefsContent('lideranca'); setPipDefsSheetOpen(true); }}
                  >
                    <QuestionCircle />
                  </PersoraButton>
                  {leastRecentValidPip?.results[0]?.results?.lideranca ? (
                    <Trends
                      prevValue={leastRecentValidPip.results[0].results.lideranca}
                      currentValue={mostRecentValidPip.results[0].results.lideranca}
                      style={{ fontSize: '12px' }}
                      manteveSeText="manteve-se"
                    />
                  ) : ''}
                </h3>
                <Row className="mt-3 mb-3">
                  <PidDateRow>
                    {leastRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{formatDateDDMMYY(leastRecentValidPip.finalDate, '/')}</p>
                  </PipDateCol>
                  )}
                    {mostRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={leastRecentValidPip?.finalDate ? defaultTheme.colors.secondary.dark : defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{mostRecentValidPip?.finalDate ? formatDateDDMMYY(mostRecentValidPip.finalDate, '/') : '--'}</p>
                  </PipDateCol>
                  )}
                  </PidDateRow>
                </Row>
                <PipSubDimensionTitle>Expectativas</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.expectativas && !leastRecentValidPip?.results[0]?.results?.expectativas
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.expectativas.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'expectativas'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.expectativas && leastRecentValidPip?.results[0]?.results?.expectativas
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.expectativas.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.expectativas.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'expectativas'}
                    />
                  )}
                <PipSubDimensionTitle>Feedbacks</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.feedbacks && !leastRecentValidPip?.results[0]?.results?.feedbacks
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.feedbacks.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'feedbacks'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.feedbacks && leastRecentValidPip?.results[0]?.results?.feedbacks
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.feedbacks.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.feedbacks.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'feedbacks'}
                    />
                  )}
                <PipSubDimensionTitle>Crescimento</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.crescimento && !leastRecentValidPip?.results[0]?.results?.crescimento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.crescimento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'crescimento'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.crescimento && leastRecentValidPip?.results[0]?.results?.crescimento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.crescimento.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.crescimento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'crescimento'}
                    />
                  )}
              </>
            )}
            {dimensionTab === 'regras' && (
              <>
                <h3 className="sourcesans semibold pb-1" style={{ display: 'flex', alignItems: 'center' }}>
                  Regras do jogo
                  {' '}
                  <PersoraButton
                    estilo="ghost"
                    tamanho="mini"
                    className="py-0 px-1"
                    onClick={() => { setPipDefsContent('regras'); setPipDefsSheetOpen(true); }}
                  >
                    <QuestionCircle />
                  </PersoraButton>
                  {leastRecentValidPip?.results[0]?.results?.cultura ? (
                    <Trends
                      prevValue={leastRecentValidPip.results[0].results.cultura}
                      currentValue={mostRecentValidPip.results[0].results.cultura}
                      style={{ fontSize: '12px' }}
                      manteveSeText="manteve-se"
                    />
                  ) : ''}
                </h3>
                <Row className="mt-3 mb-3">
                  <PidDateRow>
                    {leastRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{formatDateDDMMYY(leastRecentValidPip.finalDate, '/')}</p>
                  </PipDateCol>
                  )}
                    {mostRecentValidPip?.finalDate
                  && (
                  <PipDateCol>
                    <CircleFilled cor={leastRecentValidPip?.finalDate ? defaultTheme.colors.secondary.dark : defaultTheme.colors.secondary.main} tamanho={14} />
                    <p>{mostRecentValidPip?.finalDate ? formatDateDDMMYY(mostRecentValidPip.finalDate, '/') : '--'}</p>
                  </PipDateCol>
                  )}
                  </PidDateRow>
                </Row>
                <PipSubDimensionTitle>Clareza</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.clareza && !leastRecentValidPip?.results[0]?.results?.clareza
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.clareza.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'clareza'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.clareza && leastRecentValidPip?.results[0]?.results?.clareza
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.clareza.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.clareza.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'clareza'}
                    />
                  )}
                <PipSubDimensionTitle>Prática</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.pratica && !leastRecentValidPip?.results[0]?.results?.pratica
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.pratica.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'pratica'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.pratica && leastRecentValidPip?.results[0]?.results?.pratica
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.pratica.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.pratica.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'pratica'}
                    />
                  )}
                <PipSubDimensionTitle>Alinhamento</PipSubDimensionTitle>
                {mostRecentValidPip?.results[0]?.results?.alinhamento && !leastRecentValidPip?.results[0]?.results?.alinhamento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(mostRecentValidPip.results[0]?.results?.alinhamento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'alinhamento'}
                    />
                  )}
                {mostRecentValidPip?.results[0]?.results?.alinhamento && leastRecentValidPip?.results[0]?.results?.alinhamento
                  && (
                    <PipBarGraph
                      scaleValues={[1, 2, 3, 4, 5]}
                      leftValue={Number(leastRecentValidPip.results[0]?.results?.alinhamento.toFixed(1))}
                      rightValue={Number(mostRecentValidPip.results[0]?.results?.alinhamento.toFixed(1))}
                      className="mb-4"
                      worseDimension={mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao === 'alinhamento'}
                    />
                  )}
              </>
            )}
          </Container>
          <GrayBox className="mt-5" style={{ background: 'none', padding: 0 }}>
            <h2
              className="mb-3 soucesans"
              style={{
                display: 'flex', gap: '4px', alignItems: 'center', color: defaultTheme.colors.primary.main,
              }}
            >
              eNPS
              <PersoraButton
                estilo="ghost"
                tamanho="mini"
                className="py-1 px-1"
                onClick={() => { setPipDefsContent('enps'); setPipDefsSheetOpen(true); }}
              >
                <QuestionCircle />
              </PersoraButton>
            </h2>
            <div style={{
              position: 'relative', display: 'block', maxWidth: '330px', margin: '0 auto', height: '230px', marginTop: '20px', pointerEvents: 'none',
            }}
            >
              <img src={enpsScale} alt="escala" style={{ width: '100%' }} />
              {needleRotate !== null ? (
                <>
                  <img
                    src={enpsNeedle}
                    alt="escala"
                    style={{
                      position: 'absolute', left: 0, width: '100%', rotate: `${needleRotate}deg`,
                    }}
                  />
                  <div style={{
                    position: 'absolute', width: '100%', margin: '0 auto', textAlign: 'center', bottom: '50px',
                  }}
                  >
                    {leastRecentValidPip?.results[0]?.results?.zona && (
                    <Trends
                      prevValue={leastRecentValidPip.results[0].results.enps + 100}
                      currentValue={mostRecentValidPip.results[0].results.enps + 100}
                      style={{ margin: '0 auto' }}
                    />
                    )}
                    <h1 style={{ fontSize: '3rem', fontWeight: 600, color: defaultTheme.colors.primary.main }}>{mostRecentValidPip.results[0].results.enps}</h1>
                  </div>
                </>
              ) : (
                <div style={{
                  position: 'absolute', width: '100%', margin: '0 auto', textAlign: 'center', bottom: '50px',
                }}
                >
                  <h1 style={{ fontSize: '3rem', fontWeight: 600, color: defaultTheme.colors.primary.main }}>--</h1>
                </div>
              )}
            </div>
            <Container style={{ color: defaultTheme.colors.primary.light, maxWidth: '340px' }} className="small semibold">
              <Row className="mb-2">
                <Col xs={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <CircleFilled tamanho={18} cor="#EF6C64" />
                  {' '}
                  <span>Zona Crítica</span>
                </Col>
                <Col className="text-center">{'<0'}</Col>
              </Row>
              <Row className="mb-2">
                <Col xs={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <CircleFilled tamanho={18} cor="#FABF69" />
                  {' '}
                  Zona de Aperfeiçoamento
                </Col>
                <Col className="text-center">{'>=0 & < 50'}</Col>
              </Row>
              <Row className="mb-2">
                <Col xs={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <CircleFilled tamanho={18} cor="#3FBE89" />
                  {' '}
                  Zona de Qualidade
                </Col>
                <Col className="text-center">{'>=50 & < 75'}</Col>
              </Row>
              <Row className="mb-2">
                <Col xs={{ span: 8 }} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <CircleFilled tamanho={18} cor="#3B9771" />
                  {' '}
                  Zona de Excelência
                </Col>
                <Col className="text-center">{'>=75'}</Col>
              </Row>
            </Container>
            <div style={{ marginTop: '40px' }}>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={data}
                  margin={{
                    top: 15,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  {/* <CartesianGrid strokeDasharray="2 5" vertical={false} /> */}
                  <ReferenceLine y={0} stroke={defaultTheme.colors.black30} />
                  <XAxis
                    dataKey="name"
                    padding={{ left: 30, right: 30 }}
                    stroke={defaultTheme.colors.black60} // Define a cor do texto do eixo X
                    tick={{ fontSize: 12, fontFamily: 'Source Sans 3' }}
                  />
                  <YAxis
                    domain={[-130, 130]}
                    allowDataOverflow
                    ticks={[]}
                    hide
                  />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Line
                    type="linear"
                    dataKey="uv"
                    stroke={`${defaultTheme.colors.primary.main}`}
                    dot={<CustomizedDot />}
                    label={<CustomizedLabel />}
                    isAnimationActive={false}
                  />
                  {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </GrayBox>
          {!hiddenPipQuestLiderAvaliaResultado && currentUserContextData.type === 'Líder' && mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao && (
            <BlueContainer
              className="mt-0 mb-5 px-3 py-3"
              style={{
                border: 'none !important',
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                color: defaultTheme.colors.primary.light,
                borderRadius: '16px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
                cursor: 'pointer',
              }}
              onClick={() => setPipQuestLiderAvaliaResultadoOpen(true)}
            >
              <img
                src={SoraNeutra}
                alt="Sora Neutra"
                style={{
                  minWidth: '60px', maxWidth: '60px',
                }}
              />
              <div>
                <p className="semibold mb-1 pt-2" style={{ textWrap: 'pretty' }}>O que você achou do resultado da PIP?</p>
                <p className="small mb-2">
                  Sua opinião é importante para o desenvolvimento da Persora.
                </p>
              </div>
            </BlueContainer>
          )}
        </>
      )}
      {currentUserContextData.type === 'Colaborador' && mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao && (
        <Container style={{ color: defaultTheme.colors.primary.main }}>
          <Row className="mb-3">
            <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>Dimensões</h2>
            <PidDateRow>
              {leastRecentValidPip?.finalDate
                && (
                <PipDateCol>
                  <CircleFilled cor={defaultTheme.colors.secondary.main} tamanho={14} />
                  <p>{formatDateDDMMYY(leastRecentValidPip.finalDate, '/')}</p>
                </PipDateCol>
                )}
              {mostRecentValidPip?.finalDate
                && (
                <PipDateCol>
                  <CircleFilled cor={leastRecentValidPip?.finalDate ? defaultTheme.colors.secondary.dark : defaultTheme.colors.secondary.main} tamanho={14} />
                  <p>{mostRecentValidPip?.finalDate ? formatDateDDMMYY(mostRecentValidPip.finalDate, '/') : '--'}</p>
                </PipDateCol>
                )}
            </PidDateRow>
          </Row>
          <Row>
            <h4 className="sourcesans semibold" style={{ display: 'flex', alignItems: 'center' }}>
              Indivíduo
              {' '}
              <PersoraButton
                estilo="ghost"
                tamanho="mini"
                className="py-0 px-1"
                onClick={() => { setPipDefsContent('individuo'); setPipDefsSheetOpen(true); }}
              >
                <QuestionCircle />
              </PersoraButton>
              {leastRecentValidPip?.results[0]?.results?.individuo ? (
                <Trends
                  prevValue={leastRecentValidPip.results[0].results.individuo}
                  currentValue={mostRecentValidPip.results[0].results.individuo}
                  style={{ fontSize: '12px' }}
                  manteveSeText="manteve-se"
                />
              ) : ''}
            </h4>
            {mostRecentValidPip?.results[0]?.results?.individuo && !leastRecentValidPip?.results[0]?.results?.individuo
              && (
                <PipBarGraph
                  scaleValues={[1, 2, 3, 4, 5]}
                  leftValue={Number(mostRecentValidPip.results[0]?.results?.individuo.toFixed(1))}
                  className="mb-4"
                />
              )}
            {mostRecentValidPip?.results[0]?.results?.individuo && leastRecentValidPip?.results[0]?.results?.individuo
            && (
              <PipBarGraph
                scaleValues={[1, 2, 3, 4, 5]}
                leftValue={Number(leastRecentValidPip.results[0]?.results?.individuo.toFixed(1))}
                rightValue={Number(mostRecentValidPip.results[0]?.results?.individuo.toFixed(1))}
                className="mb-4"
              />
            )}
          </Row>
          <Row>
            <h4 className="sourcesans semibold" style={{ display: 'flex', alignItems: 'center' }}>
              Time
              {' '}
              <PersoraButton
                estilo="ghost"
                tamanho="mini"
                className="py-0 px-1"
                onClick={() => { setPipDefsContent('time'); setPipDefsSheetOpen(true); }}
              >
                <QuestionCircle />
              </PersoraButton>
              {leastRecentValidPip?.results[0]?.results?.time ? (
                <Trends
                  prevValue={leastRecentValidPip.results[0].results.time}
                  currentValue={mostRecentValidPip.results[0].results.time}
                  style={{ fontSize: '12px' }}
                  manteveSeText="manteve-se"
                />
              ) : ''}
            </h4>
            {mostRecentValidPip?.results[0]?.results?.time && !leastRecentValidPip?.results[0]?.results?.time
              && (
                <PipBarGraph
                  scaleValues={[1, 2, 3, 4, 5]}
                  leftValue={Number(mostRecentValidPip.results[0]?.results?.time.toFixed(1))}
                  className="mb-4"
                />
              )}
            {mostRecentValidPip?.results[0]?.results?.time && leastRecentValidPip?.results[0]?.results?.time
            && (
              <PipBarGraph
                scaleValues={[1, 2, 3, 4, 5]}
                leftValue={Number(leastRecentValidPip.results[0]?.results?.time.toFixed(1))}
                rightValue={Number(mostRecentValidPip.results[0]?.results?.time.toFixed(1))}
                className="mb-4"
              />
            )}
          </Row>
          <Row>
            <h4 className="sourcesans semibold" style={{ display: 'flex', alignItems: 'center' }}>
              Liderança
              {' '}
              <PersoraButton
                estilo="ghost"
                tamanho="mini"
                className="py-0 px-1"
                onClick={() => { setPipDefsContent('lideranca'); setPipDefsSheetOpen(true); }}
              >
                <QuestionCircle />
              </PersoraButton>
              {leastRecentValidPip?.results[0]?.results?.lideranca ? (
                <Trends
                  prevValue={leastRecentValidPip.results[0].results.lideranca}
                  currentValue={mostRecentValidPip.results[0].results.lideranca}
                  style={{ fontSize: '12px' }}
                  manteveSeText="manteve-se"
                />
              ) : ''}
            </h4>
            {mostRecentValidPip?.results[0]?.results?.lideranca && !leastRecentValidPip?.results[0]?.results?.lideranca
              && (
                <PipBarGraph
                  scaleValues={[1, 2, 3, 4, 5]}
                  leftValue={Number(mostRecentValidPip.results[0]?.results?.lideranca.toFixed(1))}
                  className="mb-4"
                />
              )}
            {mostRecentValidPip?.results[0]?.results?.lideranca && leastRecentValidPip?.results[0]?.results?.lideranca
            && (
              <PipBarGraph
                scaleValues={[1, 2, 3, 4, 5]}
                leftValue={Number(leastRecentValidPip.results[0]?.results?.lideranca.toFixed(1))}
                rightValue={Number(mostRecentValidPip.results[0]?.results?.lideranca.toFixed(1))}
                className="mb-4"
              />
            )}
          </Row>
          <Row>
            <h4 className="sourcesans semibold" style={{ display: 'flex', alignItems: 'center' }}>
              Regras do jogo
              {' '}
              <PersoraButton
                estilo="ghost"
                tamanho="mini"
                className="py-0 px-1"
                onClick={() => { setPipDefsContent('regras'); setPipDefsSheetOpen(true); }}
              >
                <QuestionCircle />
              </PersoraButton>
              {leastRecentValidPip?.results[0]?.results?.cultura ? (
                <Trends
                  prevValue={leastRecentValidPip.results[0].results.cultura}
                  currentValue={mostRecentValidPip.results[0].results.cultura}
                  style={{ fontSize: '12px' }}
                  manteveSeText="manteve-se"
                />
              ) : ''}
            </h4>
            {mostRecentValidPip?.results[0]?.results?.cultura && !leastRecentValidPip?.results[0]?.results?.cultura
              && (
                <PipBarGraph
                  scaleValues={[1, 2, 3, 4, 5]}
                  leftValue={Number(mostRecentValidPip.results[0]?.results?.cultura.toFixed(1))}
                  className="mb-4"
                />
              )}
            {mostRecentValidPip?.results[0]?.results?.cultura && leastRecentValidPip?.results[0]?.results?.cultura
            && (
              <PipBarGraph
                scaleValues={[1, 2, 3, 4, 5]}
                leftValue={Number(leastRecentValidPip.results[0]?.results?.cultura.toFixed(1))}
                rightValue={Number(mostRecentValidPip.results[0]?.results?.cultura.toFixed(1))}
                className="mb-4"
              />
            )}
          </Row>
        </Container>
      )}
      {currentUserContextData.type === 'Líder' && mostRecentValidPip?.results[0]?.planoAcaoDefinido.dimensao ? (
        <>
          <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Escuta ativa</h2>
          <p className="mb-3" style={{ maxWidth: '340px' }}>
            Registros de ideias, preocupações e/ou sugestões das últimas duas PIPs.
          </p>
          <PersoraButton
            tamanho="condensed"
            onClick={() => { setEscutaAtivaPipSheetOpen(true); handleMixpanelConteudoPipAcessado('Escuta Ativa'); }}
            className="mb-4"
          >
            Ver as respostas
          </PersoraButton>
        </>
      ) : (
        <div className="mb-3" />
      )}
      {currentUserContextData.type === 'Líder' && (
        <Sheet
          isOpen={colocarEmPraticaSheetIsOpen}
          onClose={() => setColocarEmPraticaSheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableScrollLocking
          disableDrag
          // mountPoint at id="TESTEAQUI"
          mountPoint={document.getElementById('pipPAModal')}
        >
          <Sheet.Container>
            <Sheet.Header className="pt-2 pb-1 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
              <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setColocarEmPraticaSheetOpen(false)}>
                <Cross />
              </PersoraButton>
            </Sheet.Header>
            <Sheet.Content style={{ zIndex: (91051 * 500) }}>
              <Sheet.Scroller>
                <div
                  style={{
                    padding: defaultTheme.spaces.screenEdge,
                    paddingTop: 0,
                    color: defaultTheme.colors.primary.main,
                  }}
                >
                  <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Compreendi! 😕</h2>
                  <p className="mb-3" style={{ color: defaultTheme.colors.primary.light }}>
                    Você poderia compartilhar comigo quais são os obstáculos que estão dificultando a aplicação do plano de ação com seu time? Estou aqui para te ajudar a superá-los.
                  </p>
                  <Form.Group style={{ position: 'relative' }}>
                    <Form.Control
                      as="textarea"
                      placeholder="Utilize este espaço para escrever (opcional)..."
                      className="mb-3"
                      name="description"
                      style={{
                        height: (planoDeAcaoObstaculosNoteLenght < 120 ? '100px' : '185px'),
                      }}
                      defaultValue={planoDeAcaoObstaculosNote}
                      onChange={handleChangeDescription}
                    />
                    <div style={{
                      position: 'absolute',
                      bottom: '3px',
                      right: '10px',
                      color: (planoDeAcaoObstaculosNoteLenght >= 300 ? 'red' : defaultTheme.colors.primary.light),
                      opacity: 0.8,
                    }}
                    >
                      <small>
                        {planoDeAcaoObstaculosNoteLenght}
                        /350
                      </small>
                    </div>
                  </Form.Group>
                  <PersoraButton
                    estilo="primary"
                    className="mb-2"
                    onClick={() => { handleIntencaoPraticarPlanoAcaoPip(intencaoPraticarAnswer, true); }}
                    disabled={isLoading || !planoDeAcaoObstaculosNoteLenght}
                  >
                    Compartilhar obstáculos
                  </PersoraButton>
                  <PersoraButton
                    estilo="ghost"
                    className="mb-4"
                    onClick={() => { setColocarEmPraticaSheetOpen(false); }}
                    disabled={isLoading}
                  >
                    Pular
                  </PersoraButton>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setColocarEmPraticaSheetOpen(false)} />
        </Sheet>
      )}
      {currentUserContextData.type === 'Líder' && mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao && (
        <Modal show={show} onHide={handleClose} fullscreen animation={false} className="modalFullscreenPage" id="pipPAModal">
          <Modal.Header>
            <PersoraButton
              estilo="ghost"
              tamanho="mini"
              justIcon
              onClick={handleClose}
              style={{ marginLeft: -12 }}
            >
              <ChevronLeft />
            </PersoraButton>
            <Modal.Title style={{
              display: 'inline-flex', alignItems: 'center', gap: '8px', fontSize: '1.15rem',
            }}
            >
              <span className="semibold">
                Plano de ação
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 56px)',
            width: `calc(100% + (${defaultTheme.spaces.screenEdge} * 2)`,
            marginLeft: `-${defaultTheme.spaces.screenEdge}`,
            background: defaultTheme.colors.surfaces.lightOrange,
            paddingBottom: 0,
            paddingTop: 0,
          }}
          >
            <ActionPlanContent>
              <CopilotCover className="mt-2 mb-4">
                <img src={CoverSora} alt="Capa Sora" className="sora" />
                <img src={CoverIcone1} alt="Ícone 1" className="icone1" />
                <img src={CoverIcone2} alt="Ícone 2" className="icone2" />
                <img src={CoverIcone3} alt="Ícone 3" className="icone3" />
                <img src={CoverIcone4} alt="Ícone 4" className="icone4" />
              </CopilotCover>
              <h1 className="sourcesans pt-2 pb-2 mb-4" style={{ color: defaultTheme.colors.primary.main, textWrap: 'pretty' }}>
                {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.definicao}
                {' '}
                🚨
              </h1>
              <Disclaimer
                estilo="reference"
                titulo="Referência"
                icone={<Book cor={defaultTheme.colors.primary.main} />}
                className="mt-2 mb-4 text-start"
              >
                <p className="mb-1">
                  Planos de ação personalizados conforme o resultado da PIP quinzenal.
                  {' '}
                  As ações são baseadas nos fundamentos da Persora e foram selecionadas a partir
                  {' '}
                  das experiências bem-sucedidas dos fundadores em anos de consultoria com times de tecnologia.
                </p>
              </Disclaimer>
              <ActionPlanTitle>
                <ActionPlanTitleEmoji>
                  🎯
                </ActionPlanTitleEmoji>
                <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Onde focar</h2>
              </ActionPlanTitle>
              <p>Para este item, seu time respondeu a seguinte pergunta:</p>
              <div
                style={{
                  border: `1px solid ${defaultTheme.colors.black20}`,
                  borderRadius: '16px',
                  padding: defaultTheme.spaces.screenEdge,
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <div>
                  <PinpaperFilled />
                </div>
                <div>
                  <h4 className="sourcesans semibold mt-1">
                    {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.nomeAmigavel}
                  </h4>
                  <p>
                    {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.perguntaRelacionada}
                  </p>
                </div>
              </div>
              {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.oQueFazerHtml && (
                <>
                  <ActionPlanTitle>
                    <ActionPlanTitleEmoji>
                      💪
                    </ActionPlanTitleEmoji>
                    <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>O que fazer?</h2>
                  </ActionPlanTitle>
                  <LargeText dangerouslySetInnerHTML={{ __html: mostRecentValidPip?.results[0]?.planoAcaoDefinido?.oQueFazerHtml }} />
                </>
              )}
              {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.comoFazerHtml && (
                <>
                  <ActionPlanTitle>
                    <ActionPlanTitleEmoji style={{ paddingTop: '.15rem' }}>
                      🧭
                    </ActionPlanTitleEmoji>
                    <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Como fazer?</h2>
                  </ActionPlanTitle>
                  <MediumText dangerouslySetInnerHTML={{ __html: mostRecentValidPip?.results[0]?.planoAcaoDefinido?.comoFazerHtml }} />
                </>
              )}
              {mostRecentValidPip?.results[0]?.planoAcaoDefinido?.porQueFazerHtml && (
                <>
                  <ActionPlanTitle>
                    <ActionPlanTitleEmoji style={{ paddingTop: '.15rem' }}>
                      💭
                    </ActionPlanTitleEmoji>
                    <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Por que fazer?</h2>
                  </ActionPlanTitle>
                  <MediumText dangerouslySetInnerHTML={{ __html: mostRecentValidPip?.results[0]?.planoAcaoDefinido?.porQueFazerHtml }} />
                </>
              )}
              {!hiddenPraticarPlanoAcaoQuestion && (
                <BlueContainer className="mb-4 text-center" style={{ background: defaultTheme.colors.black10, color: defaultTheme.colors.primary.light }}>
                  <h4 className="sourcesans mb-4">Esse é o plano de ação personalizado para tratar o que está crítico no seu time. </h4>
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <PersoraButton
                      tamanho="condensed"
                      estilo="outline"
                      style={{
                        width: '50%', padding: '.8rem', flexDirection: 'column', color: defaultTheme.colors.primary.light, backgroundColor: defaultTheme.colors.black10,
                      }}
                      className="mb-3"
                      onClick={() => { handleIntencaoPraticarPlanoAcaoPip('sim'); }}
                      disabled={isLoading}
                    >
                      <EmoteSmile tamanho={50} strokeWidth={0.85} cor={defaultTheme.colors.primary.light} />
                      {isLoading && intencaoPraticarAnswer === 'sim' ? (<Clock />) : (
                        <>
                          Colocarei em
                          <br />
                          {' '}
                          prática
                        </>
                      )}
                    </PersoraButton>
                    <PersoraButton
                      tamanho="condensed"
                      estilo="outline"
                      style={{
                        width: '50%', padding: '.8rem', flexDirection: 'column', color: defaultTheme.colors.primary.light, backgroundColor: defaultTheme.colors.black10,
                      }}
                      className="mb-3"
                      onClick={() => { handleIntencaoPraticarPlanoAcaoPip('não'); }}
                      disabled={isLoading}
                    >
                      <EmoteSad tamanho={50} strokeWidth={0.85} cor={defaultTheme.colors.primary.light} />
                      {isLoading && intencaoPraticarAnswer === 'não' ? (<Clock />) : (
                        <>
                          Não colocarei
                          <br />
                          {' '}
                          em prática
                        </>
                      )}
                    </PersoraButton>
                  </div>
                </BlueContainer>
              )}
            </ActionPlanContent>
          </Modal.Body>
        </Modal>
      )}
      {!hiddenPipQuestLiderAvaliaResultado
        && currentUserContextData.type === 'Líder'
        && mostRecentValidPip?.results[0]?.planoAcaoDefinido?.dimensao && (
        <Sheet
          isOpen={pipQuestLiderAvaliaResultadoIsOpen}
          onClose={() => setPipQuestLiderAvaliaResultadoOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
          disableScrollLocking
          disableDrag
          mountPoint={document.getElementById('pipResultModal')}
        >
          <LiderAvaliaResultado
            setPipQuestLiderAvaliaResultadoOpen={setPipQuestLiderAvaliaResultadoOpen}
            setHiddenPipQuestLiderAvaliaResultado={setHiddenPipQuestLiderAvaliaResultado}
            idSurvey={mostRecentValidPip.idQuestSurvey}
            currentTeamId={currentTeam._id}
          />
          <Sheet.Backdrop onTap={() => setColocarEmPraticaSheetOpen(false)} />
        </Sheet>
      )}
      {currentUserContextData.type === 'Líder' && (
        <Sheet
          isOpen={escutaAtivaPipSheetIsOpen}
          onClose={() => setEscutaAtivaPipSheetOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableScrollLocking
          disableDrag={!escutaAtivaPipSheetIsOpen}
        >
          <Sheet.Container>
            <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
              <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setEscutaAtivaPipSheetOpen(false)}>
                <Cross />
              </PersoraButton>
            </Sheet.Header>
            <Sheet.Content>
              <Sheet.Scroller>
                <div
                  style={{
                    padding: defaultTheme.spaces.screenEdge,
                    paddingTop: 0,
                    color: defaultTheme.colors.primary.main,
                  }}
                >
                  <h2 className="medium sourcesans mb-1" style={{ color: defaultTheme.colors.primary.main }}>Escuta ativa</h2>
                  <p className="mb-3" style={{ color: defaultTheme.colors.primary.light, maxWidth: '340px' }}>
                    Registros de ideias, preocupações e/ou sugestões das últimas duas PIPs.
                  </p>
                  {mostRecentValidPip?.results[0]?.results?.notes ? (
                    <GrayBox>
                      <h4 className="sourcesans semibold mt-2 mb-3">
                        PIP do dia
                        {' '}
                        {mostRecentValidPip?.finalDate && formatDateDDMMYY(mostRecentValidPip?.finalDate, '/')}
                      </h4>
                      {mostRecentValidPip.results[0].results.notes
                        .map((value) => ({ value, sort: Math.random() })) // Adiciona um valor aleatório para cada item
                        .sort((a, b) => a.sort - b.sort) // Ordena os itens de acordo com o valor aleatório
                        .map(({ value }, index) => (
                        // eslint-disable-next-line
                        <Fragment key={`${index}-escutaAtivaMostRecent`}>
                          <div>
                            <p>{value}</p>
                          </div>
                          <hr />
                        </Fragment>
                        ))}
                      {mostRecentValidPip?.results[0]?.results?.notes?.length === 0 && (
                        <p>Nenhuma anotação registrada. 😕</p>
                      )}
                    </GrayBox>
                  ) : ('')}
                  {leastRecentValidPip?.results[0]?.results?.notes ? (
                    <GrayBox>
                      <h4 className="sourcesans semibold mt-2 mb-3">
                        PIP do dia
                        {' '}
                        {leastRecentValidPip?.finalDate && formatDateDDMMYY(leastRecentValidPip?.finalDate, '/')}
                      </h4>
                      {leastRecentValidPip.results[0].results.notes
                        .map((value) => ({ value, sort: Math.random() })) // Adiciona um valor aleatório para cada item
                        .sort((a, b) => a.sort - b.sort) // Ordena os itens de acordo com o valor aleatório
                        .map(({ value }, index) => (
                        // eslint-disable-next-line
                        <Fragment key={`${index}-escutaAtivaLeastRecent`}>
                          <div>
                            <p>{value}</p>
                          </div>
                          <hr />
                        </Fragment>
                        ))}
                      {leastRecentValidPip?.results[0]?.results?.notes?.length === 0 && (
                      <p>Nenhuma anotação registrada. 😕</p>
                      )}
                    </GrayBox>
                  ) : ('')}
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setEscutaAtivaPipSheetOpen(false)} />
        </Sheet>
      )}
      <Sheet
        isOpen={pipDefsSheetIsOpen}
        onClose={() => setPipDefsSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        disableDrag={!pipDefsSheetIsOpen}
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setPipDefsSheetOpen(false)}>
              <Cross />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.main,
                }}
              >
                {pipDefsContent === 'enps' && (
                  <div style={{ color: defaultTheme.colors.primary.light }}>
                    <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>e-NPS</h2>
                    <p className="mb-2">
                      O eNPS
                      {' '}
                      <i>(Employee Net Promoter Score)</i>
                      {' '}
                      é um índice independente, com notas de 1 a 10, que resulta em um valor de -100 a 100. Ele mede a satisfação das pessoas com a empresa (remuneração, benefícios, liderança, etc.).
                    </p>
                    <p className="mb-3">
                      Incluímos o e-NPS na PIP quinzenal para que você possa acompanhar o impacto das suas ações como líder.
                    </p>
                    <BlueContainer className="px-3 py-4 text-center mt-2 mb-3">
                      <img src={enpsFormula} alt="Fórmula eNPS" className="my-2" style={{ height: '30px' }} />
                    </BlueContainer>
                    <h3 className="medium sourcesans mb-2">Exemplo:</h3>
                    <ul className="ms-2">
                      <li>20 respostas 2 deram notas de 0 a 6 (detratores)</li>
                      <li>3 deram notas de 7 a 8 (neutros)</li>
                      <li>15 deram notas de 9 a 10 (promotores).</li>
                    </ul>
                    <BlueContainer className="px-3 py-4 text-center mt-2 mb-3">
                      <img src={enpsExample} alt="Exemplo eNPS" className="my-2" />
                    </BlueContainer>
                    <p className="mb-3">
                      O resultado é um número inteiro que indica o nível de satisfação. Promotores são satisfeitos, neutros têm algumas ressalvas e detratores estão insatisfeitos.
                    </p>
                  </div>
                )}
                {pipDefsContent === 'individuo' && (
                  <>
                    <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>Indivíduo</h2>
                    <span style={{ color: defaultTheme.colors.primary.light }}>
                      <p className="mb-3">
                        Avalia como a pessoa se sente com relação a ela mesma no
                        {' '}
                        âmbito profissional com base nas últimas duas semanas.
                      </p>
                      {currentUserContextData.type === 'Líder' && (
                      <>
                        <h4 className="semibold sourcesans mb-1">Autoconhecimento</h4>
                        <p>Você conseguiu identificar suas habilidades mais fortes?</p>
                        <h4 className="semibold sourcesans mb-1">Aproveitamento</h4>
                        <p>Com que frequência você teve a oportunidade de fazer no trabalho o que você faz de melhor?</p>
                        <h4 className="semibold sourcesans mb-1">Crescimento Profissional</h4>
                        <p>
                          Com que frequência você teve a oportunidade de aprender e
                          {' '}
                          crescer no trabalho?
                        </p>
                      </>
                      )}
                    </span>
                  </>
                )}
                {pipDefsContent === 'time' && (
                  <>
                    <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>Time</h2>
                    <span style={{ color: defaultTheme.colors.primary.light }}>
                      <p className="mb-3">
                        Avalia como a pessoa se sente com relação ao time no qual
                        {' '}
                        está inserida com base nas últimas duas semanas.
                      </p>
                      {currentUserContextData.type === 'Líder' && (
                      <>
                        <h4 className="semibold sourcesans mb-1">Acolhimento</h4>
                        <p>Sua opinião foi levada em conta nas tomadas de decisão?</p>
                        <h4 className="semibold sourcesans mb-1">Confiança</h4>
                        <p>Seus colegas estiveram comprometidos em fazer o seu melhor no trabalho?</p>
                        <h4 className="semibold sourcesans mb-1">Colaboração</h4>
                        <p>Você recebeu ajuda e apoio de colegas de trabalho quando precisou?</p>
                      </>
                      )}
                    </span>
                  </>
                )}
                {pipDefsContent === 'lideranca' && (
                  <>
                    <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>Liderança</h2>
                    <span style={{ color: defaultTheme.colors.primary.light }}>
                      <p className="mb-3">
                        Avalia como a pessoa se sente com relação à sua liderança
                        {' '}
                        com base nas últimas duas semanas.
                      </p>
                      {currentUserContextData.type === 'Líder' && (
                      <>
                        <h4 className="semibold sourcesans mb-1">Expectativas</h4>
                        <p>Você sabia exatamente o que sua liderança esperava de você no trabalho?</p>
                        <h4 className="semibold sourcesans mb-1">Feedbacks</h4>
                        <p>
                          Você se sentiu à vontade com sua liderança
                          {' '}
                          para dar e receber feedbacks?
                        </p>
                        <h4 className="semibold sourcesans mb-1">Crescimento</h4>
                        <p>Sua liderança estimulou seu desenvolvimento? </p>
                      </>
                      )}
                    </span>
                  </>
                )}
                {pipDefsContent === 'regras' && (
                  <>
                    <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>Regras do jogo</h2>
                    <span style={{ color: defaultTheme.colors.primary.light }}>
                      <p className="mb-2">
                        Avalia como a pessoa se sente em relação aos valores do time
                        {' '}
                        com base nas últimas duas semanas.
                      </p>
                      <p className="mb-2">
                        Os valores são aqueles comportamentos fundamentais que são enfatizados nas dinâmicas de trabalho e nos acordos feitos entre a equipe. Cada time/empresa tem os seus.
                      </p>
                      <p className="mb-3">
                        Por exemplo: colaboração, aprendizado contínuo e transparência. A ideia é medir a percepção da aplicação desses valores no dia a dia e o quanto as pessoas se sentem alinhadas com eles.
                      </p>

                      {currentUserContextData.type === 'Líder' && (
                      <>
                        <h4 className="semibold sourcesans mb-1">Clareza</h4>
                        <p>Os valores do time estiveram claros para você?</p>
                        <h4 className="semibold sourcesans mb-1">Prática</h4>
                        <p>
                          Com que frequência os valores do time foram colocados em prática pelas pessoas no dia a dia?
                        </p>
                        <h4 className="semibold sourcesans mb-1">Alinhamento</h4>
                        <p>Seus valores pessoais estiveram alinhados com os valores do time?</p>
                      </>
                      )}
                    </span>
                  </>
                )}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setPipDefsSheetOpen(false)} />
      </Sheet>
    </div>
  );
}
PipResultsTab.propTypes = {
  updateTaskPipSeeActionPlan: PropTypes.func.isRequired,
  currentTeam: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
  }).isRequired,
  handleMixpanelConteudoPipAcessado: PropTypes.func.isRequired,
};
// Header.propTypes = {
//   userData: PropTypes.shape({
//     name: PropTypes.string,
//     type: PropTypes.string,
//     owner: PropTypes.bool,
//     _id: PropTypes.string,
//   }),
// };
// Header.defaultProps = {
//   userData: {},
//   workspaceTeams: [],
// };
