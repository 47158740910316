import { createGlobalStyle } from 'styled-components';
import Edit2 from '../img/Edit2.svg';

export default createGlobalStyle`
  #root.androidSheetHack {
    padding-top: 1px;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Source Sans 3', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  body {
    color: ${({ theme }) => theme.bodyColor};
    background: ${({ theme }) => theme.backgroundColor};
    font-size: 16px;
  }
  button {
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  h1 { font-size: 1.70rem; }
  h2 { font-size: 1.55rem; }
  h3 { font-size: 1.35rem; }
  h4 { font-size: 1.15rem; }
  h5 { font-size: 1rem; }
  h6 { font-size: 0.82rem; }
  .responsive-svg {
    width: 100%;
    height: auto;
  }
  p {
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
  }
  .content-label {
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: 600;
    font-size: .9rem;
    line-height: 1.1rem;
    text-transform: uppercase;
  }
  .content-label span {
    font-size: 0.9rem;
    font-weight: 400;
  }
  p.big {
    font-size: 1.2rem;
  }
  p.small {
    font-size: 0.9rem;
  }
  .sourcesans {
    font-family: 'Source Sans 3', sans-serif;
  }
  .sourceserif {
    font-family: 'Source Serif 4', serif;
  }
  .dmsans {
    font-family: 'DM Sans', sans-serif;
  }
  .dmsans span {
    font-family: 'DM Sans', sans-serif !important;
  }
  /* nav {
    margin-bottom: 10px;
    a {
      margin-right: 10px;
    }
  } */

  /* ul li withtout margin */
  ul {
    padding-left: 16px;
  }
  ul li {
    margin-bottom: 4px;
  }

  /* Custom tooltip bootstrap */
  .custom-tooltip .tooltip-inner {
    background-color: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.primary.main}; /* Define a cor do texto */
    padding: .8rem 1rem;
    font-size: 1rem;
    border-radius: 8px;
    box-shadow: 0px 2px 16px 0px rgba(37, 46, 70, 0.10) !important;
  }
  .custom-tooltip .tooltip-arrow::before  {
    border-right-color: ${({ theme }) => theme.colors.alerts.info.background}; /* Define a cor da seta do tooltip */
  }

  .premium-text {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#223b59+38,2183a6+100 */
    background: linear-gradient(135deg,  rgba(34,59,89,1) 38%,rgba(33,131,166,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .regular {
    font-weight: 400;
  }
  .medium {
    font-weight: 500;
  }
  .semibold {
    font-weight: 600;
  }
  .bold {
    font-weight: 700;
  }
  .emoji-paragraph {
    display: flex;
    align-items: flex-start;
  }
  .emoji-paragraph span {
    margin-right: 12px; /* ou o espaçamento desejado */
  }
  /* Bootstrap modfiers */
  .btn-primary {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
  .form-floating>label {
    color: ${({ theme }) => theme.colors.black60};
  }
  .form-control, .form-select {
    background-color: ${({ theme }) => theme.colors.surfaces.lightBlue};
    border-color: ${({ theme }) => theme.colors.black40};
    color: ${({ theme }) => theme.colors.primary.light} !important;
    box-shadow: 0px -4px 10px 0px rgba(37, 46, 70, 0.10) !important;
    padding: 10px;
  }
  .form-label {
    color: ${({ theme }) => theme.colors.black60};
    font-weight: 600;
    line-height: 1.25rem;
  }
  .form-label span {
    font-size: 0.9rem;
    font-weight: 400;
  }
  .form-control::placeholder {
    color: ${({ theme }) => theme.colors.black40};
  }
  .handbookFormControlFilled:not(:focus) {
    background: none;
    border: none;
    box-shadow: none !important;
    background-image: url(${Edit2});
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 20px;
    padding-top: 0;
    padding-left: 28px;
    margin-bottom: -10px !important;
  }
  textarea.handbookFormControlFilled:not(:focus) {
    background-position: 3px 3px;
    padding-top: 3px;
    padding-left: 31px;
  }
  /* alinha texto do input tipo "date" à esquerda no iOS */
  input.handbookFormControlFilled::-webkit-date-and-time-value {
    text-align: left;
  }
  .handbookFormControlFilledV2:not(:focus) {
    background: none;
    border: none;
    box-shadow: none !important;
    background-image: url(${Edit2});
    background-repeat: no-repeat;
    /* background-position 20px from right; */
    background-position: calc(100% - 10px) 5px;
    background-size: 20px;
    padding: 5px 0 15px 0;
    margin-bottom: -10px !important;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
  }
  textarea.handbookFormControlFilledV2:not(:focus) {
    background-position: 3px 3px;
    padding-top: 3px;
    padding-left: 31px;
  }
  /* alinha texto do input tipo "date" à esquerda no iOS */
  input.handbookFormControlFilledV2::-webkit-date-and-time-value {
    text-align: left;
  }
  ul.nav-tabs {
    padding-left: ${({ theme }) => theme.spaces.screenEdge};
    border: none;
    margin-bottom: -12px;
  }
  .nav-tabs .nav-link {
    border: none !important;
    border-color: #fff !important;
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 16px;
    background: ${({ theme }) => theme.colors.surfaces.white};
    color: ${({ theme }) => theme.colors.primary.light};
    margin-right: .5rem;
    border-radius: 8px;
  }
  .nav-tabs .nav-link span {
    display: none;
    margin-left: 8px;
  }
  .nav-tabs .nav-link.active {
    background: ${({ theme }) => theme.colors.secondary.light};
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .nav-tabs .nav-link.active span {
    display: inline-block;
  }
  .tab-content {
    box-shadow: 0px -6px 12px 0px rgba(37, 46, 70, 0.15) ;
    background: ${({ theme }) => theme.colors.surfaces.white};
    padding: 20px ${({ theme }) => theme.spaces.screenEdge};
    padding-top: 32px;
  }
  #home-tabs-tab-handbook.disabled {
    /* display: none; */
  }

  .baseStyleTabs {
    color: ${({ theme }) => theme.colors.primary.light};
  }

  .accordion-item button {
    z-index: 0 !important;
  }

  .accordion-item, .accordion-iten:first-of-type, .accordion-item:last-of-type {
    box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
    margin-bottom: 18px;
    background: ${({ theme }) => theme.colors.surfaces.lightBlue};
    border-color: ${({ theme }) => theme.colors.surfaces.lightBlue};
    border-radius: 12px !important;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .accordion-item .accordion-button {
    outline: none;
    box-shadow: none;
    background: none;
    font-weight: 500;
    font-size: 1.05rem;
    color: ${({ theme }) => theme.colors.primary.main};
    padding: 18px;
  }
  .accordion-item .accordion-button:not(.collapsed) {
    /* background: ${({ theme }) => theme.colors.secondary.light}; */
  }
  .accordion-item .accordion-collapse {
    /* background: ${({ theme }) => theme.colors.secondary.light};
    border-color: ${({ theme }) => theme.colors.secondary.light}; */
  }
  .accordion-item .accordion-button::after {
    align-self: middle;
    transform: scale(.9)
  }
  .accordion-item .accordion-button:not(.collapsed)::after {
    transform:  rotate(-180deg);
  }
  .accordion-item .accordion-body {
    padding-top: 4px;
  }
  .accordion-item .accordion-header span.icon {
    background: ${({ theme }) => theme.colors.secondary.light};
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 1.3rem;
    border-radius: 32px;
    margin-right: calc(${({ theme }) => theme.spaces.screenEdge} / 1.5);
  }

  /* react-modal-sheet modifiers */
  .react-modal-sheet-backdrop {
  background-color: rgba(255,255,255,.5) !important;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  pointer-events: visible !important;
  }
  .react-modal-sheet-container {
    /* custom styles */
    border-top-right-radius: 24px!important;
    border-top-left-radius: 24px !important;
    box-shadow: 0px -4px 10px 0px rgba(37, 46, 70, 0.10) !important;
  }
  /* webapp */
  @media (min-width: 768px) {
    .react-modal-sheet-container {
      max-width: 570px;
      margin-left: calc(50% - 285px) !important;
    }
  }
  .react-modal-sheet-header {
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    /* custom styles */
  }
  .react-modal-sheet-content {
    /* custom styles */
  }

  /* bootstrap modal modifiers */
  .modal {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+14,223b59+76&0.95+8,0.35+44,0.35+100 */
    background: rgba(255,255,255, .7);
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
    transition-duration: 0.35s;
  }
  .modal-content {
    border: none;
    padding: calc(${({ theme }) => theme.spaces.screenEdge} / 2);
    color: ${({ theme }) => theme.colors.primary.light};
    border-radius: 16px;
  }
  .modal-header {
    border: none;
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .modal-footer {
    border: none;
  }
  .modalFullscreenPage {
    /* opacity: .7; */
  }
  .modalFullscreenPage .modal-header {
    height: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 12px 18px;
    background: #FFFFFF;
    border-bottom: 1px solid #E5E8EC;
    color: #223B59;
    text-align: center;
    z-index: 10;
  }
  /* webapp */
  @media (min-width: 768px) {
    .modalFullscreenPage .modal-fullscreen {
      max-width: 600px;
      margin: 20px auto !important;
      border-radius: 20px;
      overflow: hidden;
      height: calc(100dvh - 40px) !important;
      /* box-shadow: 0 0 40px rgba(0, 0, 0, .2); */
    }
  }
  .modalFullscreenPage .modal-content {
    padding: 0;
  }
  .modalFullscreenPage .modal-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    font-size: 1rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
  }

  /* sweetalert2 modifiers */
  .swal2-container {
    z-index: 99999999;
  }
  .swal2-backdrop-show {
    /* background-color: #223B59 !important; */
    /* background in rgba */
    background-color: rgba(34, 59, 89, 0.2) !important;
    backdrop-filter: blur(8px) !important;
    -webkit-backdrop-filter: blur(8px) !important;
    padding: ${({ theme }) => theme.spaces.screenEdge};
  }
  .swal2-modal {
    background-color: rgba(255,255,255,.95) !important;
    border-radius: 24px;
    padding: 24px 22px;
    padding-bottom: 28px;
    box-shadow: 0px 4px 10px 2px rgba(37, 46, 70, 0.10) !important;
    color: ${({ theme }) => theme.colors.primary.main};
    text-wrap: balance;
  }
  .swal2-close {
    margin-top: -10px;
    margin-right: -10px;
    font-size: 2rem;
    box-shadow: none !important;
  }
  .swal2-cancel.swal2-styled {
    background: ${({ theme }) => theme.colors.surfaces.white};
    color: ${({ theme }) => theme.colors.black60};
    border: 1px solid ${({ theme }) => theme.colors.black40};
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 16px;
  }
  .swal2-confirm.swal2-styled {
    background: ${({ theme }) => theme.colors.secondary.dark};
    color: ${({ theme }) => theme.colors.surfaces.white};
    border-radius: 8px;
    padding: 13px 16px;
    font-weight: 600;
    font-size: 16px;
  }
  .swal2-button-full-width {
    width: 100% !important;
    font-size: 18px !important;
  }
  .swal2-actions {
    width: 100%;
    max-width: 400px;
    margin-top: 16px;
    font-size: 16px;
  }
  .swal2-button-full-width:focus {
    outline: none !important; /* Borda vermelha */
    box-shadow: none !important; /* Sombra vermelha */
  }
  .swal2-title {
    font-weight: 600;
    font-size: 1.15rem;
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .swal2-html-container {
    max-width: 480px;
    margin: .8rem auto !important;
    margin-bottom: .4rem !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .swal2-html-container p {
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: .4rem;
  }
  .swal2-button-color-outline-red, .swal2-button-color-outline-red:focus, .swal2-button-color-outline-red:hover, .swal2-button-color-outline-red:active {
    background-color: ${({ theme }) => theme.colors.fullWhite} !important;
    border: 1px solid #A11212 !important;
    color: #A11212 !important;
  }
  .swal2-button-color-filled-primary-main {
    background-color: ${({ theme }) => theme.colors.primary.main} !important;
    border: 1px solid ${({ theme }) => theme.colors.primary.main} !important;
    color: ${({ theme }) => theme.colors.fullWhite} !important;
  }
  .swal2-custom-z-index {
    z-index: 99999999999999999 !important;
  }

  /* swiperjs modifiers */
  .swiper-goals-tracker, .swiper-user-profile-cards, .swiper-nudges {
    padding-bottom: 16px;
  }
  .swiper-goals-tracker-pagination, .swiper-user-profile-cards-pagination, .swiper-nudges-pagination {
    display: flex;
    justify-content: center;
    padding: 6px;
  }
  .swiper-goals-tracker-pagination .swiper-pagination-bullet,
  .swiper-user-profile-cards-pagination .swiper-pagination-bullet,
  .swiper-nudges-pagination .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.black40};
    opacity: 1;
    width: 6px;
    height: 6px;
    margin: 0 3px;
    transition: ease-in-out .3s;
  }
  .swiper-goals-tracker-pagination .swiper-pagination-bullet-active,
  .swiper-user-profile-cards-pagination .swiper-pagination-bullet-active,
  .swiper-nudges-pagination .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.primary.main};
    width: 20px;
    border-radius: 16px;
  }
  @media (max-height: 760px) {
    .swiper-pagination.swiper-pagination-oboarding-pip {
      bottom: 58px !important;
    }
  }


  .Toastify__toast-container {
    margin-top: 12px !important;
    margin-left: 8px !important;
    width: calc(100% - 16px);
  }
  .Toastify__toast-body {
    align-items: start;
  }
  .Toastify__toast {
    margin-bottom: 8px !important;
  }
  .Toastify__close-button {
    margin-top: -3px;
    margin-right: 3px;
  }
  .Toastify__close-body {
    align-items: start;
  }
  .Toastify__toast-icon {
    font-size: 1.3rem;
    margin-top: -.15rem;
  }
  .Toastify__close-icon {
    margin-top: 3px;
  }
  .Toastify__toast {
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.alerts.generic.background};
    color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  }
  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.alerts.success.background};
    color: ${({ theme }) => theme.colors.alerts.success.textColor};
  }
  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.alerts.danger.background};
    color: ${({ theme }) => theme.colors.alerts.danger.textColor};
  }
  .Toastify__toast--warning {
    background: ${({ theme }) => theme.colors.alerts.warning.background};
    color: ${({ theme }) => theme.colors.alerts.warning.textColor};
  }
  .Toastify__toast--info {
    background: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.alerts.info.textColor};
  }

  /* novu modifiers */
  .mantine-Popover-dropdown > div {
    max-width: 340px;
    /* margin-right: -45px; */
  }
  .mantine-Popover-arrow {
    /* right: 65px !important; */
    right: 20px !important;
  }
  .nc-layout-wrapper button.mantine-UnstyledButton-root {
    display: none !important;
  }
  .nc-notifications-list-item button.mantine-UnstyledButton-root {
    display: block !important;
  }
  .nc-notifications-list-item {
    box-shadow: 0px 3px 10px 0px rgba(37, 46, 70, 0.10) !important;
  }
  .nc-notifications-list-item-read {
    box-shadow: none !important;
  }

  /* driver.js modifiers */
  .driver-popover  {
    border-radius: 12px;
    min-width: calc(365px - (2 * ${({ theme }) => theme.spaces.screenEdge}));
  }
  .driver-popover footer {
    margin-top: 20px;
  }
  .driver-overlay path {
    fill: ${({ theme }) => theme.colors.primary.main} !important;
    opacity: .6 !important;
  }
  .driver-popover-title {
    color: ${({ theme }) => theme.colors.primary.main};
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1.1rem;
    text-align: center;
    margin-top: .6rem;
  }
  .driver-popover-description {
    color: ${({ theme }) => theme.colors.primary.light};
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1rem;
    text-align: center;
  }
  .driver-popover .driver-popover-next-btn, .driver-popover .driver-popover-next-btn:focus, .driver-popover .driver-popover-next-btn:hover,
  .driver-popover .driver-popover-prev-btn, .driver-popover .driver-popover-prev-btn:focus, .driver-popover .driver-popover-prev-btn:hover {
    text-shadow: none;
    padding: 10px 22px;
    border-radius: 10px;
    border: none !important;
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.surfaces.white};
  }
  .tapbartime-popover .driver-popover-arrow {
    left: 50% !important;
    width: 10px !important;
    margin-left: -5px !important;
  }


  .toggle-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
  }

  .toggle-switch {
    background-color: ${({ theme }) => theme.colors.surfaces.lightBlue};
    border: 2px solid ${({ theme }) => theme.colors.primary.light};
    width: 52px;
    height: 32px;
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
  }

  .toggle-switch::before {
    content: "";
    font-weight: 900;
    background-color: ${({ theme }) => theme.colors.primary.light};
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 50%;
    top: 5px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ddd;
    transition: 0.3s;
  }

  .toggle-checkbox:checked+.toggle-switch {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }

  .toggle-checkbox:checked+.toggle-switch  {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
  .toggle-checkbox:checked+.toggle-switch::before {
    content: "";
    top: 3px;
    left: 23px;
    color: #0075ff;
    background-color: #fff;
    width: 21px;
    height: 21px;
  }

  .form-check-input {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.primary.light};
  }
  .form-check-input:checked {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: none;
  }

  /* INPUT TYPE RANGE MODIFIERS */
  /* Estilo para navegadores baseados em WebKit (Chrome, Safari) */
  input[type='range'].pipRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    margin-top: -5px;
    background: ${({ theme }) => theme.colors.primary.main} !important; /* Altere esta cor para a cor desejada */
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary.main} !important;
    box-shadow: none !important;
  }

  /* Estilo para navegadores baseados em Firefox */
  input[type='range'].pipRange::-moz-range-thumb {
    width: 20px;
    height: 20px;
    margin-top: -5px;
    background: ${({ theme }) => theme.colors.primary.main} !important; /* Altere esta cor para a cor desejada */
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary.main} !important;
    box-shadow: none !important;
  }

  /* Estilo para navegadores baseados em IE */
  input[type='range'].pipRange::-ms-thumb {
    width: 20px;
    height: 20px;
    margin-top: -5px;
    background: ${({ theme }) => theme.colors.primary.main} !important; /* Altere esta cor para a cor desejada */
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary.main} !important;
    box-shadow: none !important;
  }

  /* .hiddenLastHr inside div - div */
  .hiddenLastHr > div:last-of-type hr {
    display: none;
  }
  .modal-open.driver-active .modal,
  .modal-open.driver-active .modal * {
    pointer-events: auto !important;
  }

  .remirror-sendBumper .remirror-editor-wrapper {
    padding-top: 10px !important;
  }
  .remirror-sendBumper .ProseMirror {
    border-radius: 0;
    box-shadow: none !important;
    border-top: 1px solid #ddd;
  }

  .remirror-sendBumper h1 {
    font-size: 22px;
    font-family: Helvetica, Arial, sans-serif;
    color: #223B59;
    margin-bottom: 16px !important;
    font-weight: 500;
  }

  .remirror-sendBumper p {
    font-size: 17px;
    font-family: Helvetica, Arial, sans-serif;
    color: #4F6586;
    margin-bottom: 8px !important;
  }

  .remirror-sendBumper ul {
    background: #E7F6FF; padding: 20px 25px !important; padding-left: 40px !important; border-radius: 16px; margin-top: 20px !important; margin-bottom: 25px !important;
  }

  span.keeptogether {
    white-space: nowrap;
  }

`;
